import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import "ag-grid-enterprise";
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { InventoryService } from 'src/app/_services/inventory.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custella-bin',
  templateUrl: './custella-bin.component.html',
  styleUrls: ['./custella-bin.component.scss']
})
export class CustellaBinComponent extends SubscriptionUtil
implements OnInit, OnDestroy
{
@Output() itemSelector = new EventEmitter();
@Output() closeView = new EventEmitter();


gridApi: any;
gridColumnApi: any;
columnDefs: any;
defaultColDef: any;
frameworkComponents: any;
gridParams : any;
startFrom: any;
product: any;
pageSize: any = 25;
parameters: any;
gridOptions: GridOptions;
overlayNoRowsTemplate: string;
productsFamilies: any;
productsType: any;
currentRecordNumber: any;
totalRecord: any;

 searchValue: any=null
  checklistData: any;
  translate: any;

constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private inventoryService: InventoryService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private languageTranslateService: LanguageTranslateService
  ) {
  super();
    this.getLanguageData()
  this.gridOptions = {
    cacheBlockSize: 25,
    rowModelType: "infinite",
    sortingOrder: ["desc", "asc"],
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    tooltipShowDelay: 100,
  };
 
  this.defaultColDef = {
    enableRowGroup: true,//ag-Grid-Enterprise
    enablePivot: true,//ag-Grid-Enterprise
    enableValue: true,//ag-Grid-Enterprise
    sortable: true,
    resizable: true,
    filter: false,
    tooltipComponent: TableHeaderToolTipComponent
  };
  //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";

  
}

ngOnInit(): void {}

ngOnDestroy(): void {
  super.ngOnDestroy();
}
public viewUser($event: any) {
  const field = $event.colDef.field;

  if (field === "binNumber") {
    this.selectItem($event.data);
  }
}
selectItem(val: any) {
  this.itemSelector.emit(val);
}

public closeTable() {
  this.closeView.emit(false);
}
 
push(obs:any) {
  super.push(obs);
}



/************************************************ Search Data ****************************************/
quickSearch() {
  this.onGridReady(this.gridParams);
  
}
/***************************************** Initilize Grid Table ****************************************/
onGridReady(params: any) {
  //console.log(params)
  this.gridParams = params;

  this.gridApi = params.api;
  this.gridApi.sizeColumnsToFit();
  var dataSource = {
    getRows: (params: IServerSideGetRowsParams) => {
      this.getServerSideData(params);
    },
  };

  this.gridApi.sizeColumnsToFit();
  this.gridApi.setDatasource(dataSource);
}

/********************************** Call Customer API to Display data *********************************/
getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
  // this.spinner.show()
  this.gridApi.hideOverlay();
  const serverRequest = this.getRequestParams(agGridGetRowsParams);
 
  this.inventoryService.getAllBin(this.searchValue, serverRequest, null).subscribe((data: any) => {
          console.log(data.body);
          if (data.status === 200 || data.status === 201) {
          this.checklistData = data.body.data;
          this.totalRecord = data.body.total;
          this.setGridData(
            agGridGetRowsParams,
            this.checklistData,
            data.body.total,
            data.body.status
          );
          //this.spinner.hide()
        } else {
          const title = this.errorUtil.processErrorTitle(data.error,this.translate)
          this.errorUtil.setErrorMessage(400, null ,  title, 'error',3000);
        }
        },
        (err) => {
          //console.log(err,"===>>>")
          const title = this.errorUtil.processErrorTitle(err.error,this.translate)
       this.errorUtil.setErrorMessage(400, null ,  title, 'error',3000);
        }
      )
  
}
/**************************************** Set Data for Grid Table ****************************************/
private setGridData( agGridGetRowsParams: IServerSideGetRowsParams,resultItems: any[],totalCount: number, status: string ) {
  console.log(resultItems, "===");
  
 
    if (this.checklistData.length === 0) {
      this.gridApi.showNoRowsOverlay();
       } else {
       agGridGetRowsParams.successCallback(this.checklistData, totalCount);
    }
  
 
}
/********************************** Request Parameter for api ****************************************/

getRequestParams(agGridRequest: any): ServerRequest {
  if (agGridRequest.sortModel.length <= 0) {
    this.parameters = { colId: "locationNumber", sort: "desc" };
    agGridRequest.sortModel.push(this.parameters);
  }

  //console.log(agGridRequest)
  this.currentRecordNumber = agGridRequest.startRow;
  return {
    startRow: agGridRequest.startRow,
    pageSize: 25,
    filterModel: null,
    sortModel: agGridRequest.sortModel,
  };
}

getLanguageData() {
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.initializeColumns();
      }
    }))
  console.log("translate",this.translate)
  //this.mainLabel = this.translate?.customer.label.plural
}
initializeColumns() {
  this.columnDefs = []
  this.overlayNoRowsTemplate = "<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
//   console.log("initiateCol", translateData, "=======", this.columnDefs)
  var newData = [
    {
      headerName:  this.translate?.setup.modules.inventory.bins.fields.binNumber.singular,
      field: 'binNumber',
      width: 150,
      cellClass: function (params: any) {
        return ['table_default_color-top'];
      },
      headerTooltip: "#",
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadSetInvBinNo">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      },
      cellRenderer: function (params: any) {

        if (params.data) {
          return '<p id="' + params.data.rowId + '">' + params.data.name + '</p>';
        }
      },
    },

    { headerName: this.translate?.setup.modules.inventory.bins.fields.name.singular,  field: 'name', width: 150, headerTooltip: "#", },
    { headerName: this.translate?.setup.modules.inventory.bins.fields.shortCode.singular,  field: 'shortCode', width: 150, headerTooltip: "#", },
    { headerName: this.translate?.setup.modules.inventory.bins.fields.displayName.singular,  field: 'displayName', width: 150, headerTooltip: "#", },
    { headerName: this.translate?.setup.modules.inventory.bins.fields.user.singular,  field: 'user.fullName', width: 100, headerTooltip: "#", },
    
  ];

  this.columnDefs = newData;
  if (this.gridApi) {
    this.gridApi.setColumnDefs(this.columnDefs);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
  }
}
}