<div class="custella-modal d-flex flex-column">
  <div class="custella-modal-header flex-align-center">
    <div class="custella-modal-header-text" id="title_JobCompletionForm">
      {{ translate?.jobOrder.sections.sec40.singular }}
    </div>

    <i class="fa-regular fa-xmark" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" id="btn_JobCompletionFormClose" (click)="modalRef.hide()"></i>
  </div>

  <div class="custella-modal-body">
    <!-- NAME AND IC PASSPORT -->
    <ng-container *ngIf="data?.jobOrderCompletionRecordVm?.personName">
      <div class="custella-form-container-title flex-between rounded-top">
        <div class="font-medium text-14" id="title_JobComplNameIc">
          {{ translate?.jobOrder.sections.sec9.singular }}
        </div>
      </div>

      <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
          <div class="custella-content-row-input col-6">
            <span class="custella-content-row-input-title-two" id="label_JobComplName">
              {{ translate?.jobOrder.fields.name.singular }}
            </span>

            <p class="custella-content-row-input-title" id="value_JobComplName">
              {{ data?.jobOrderCompletionRecordVm?.personName }}
            </p>
          </div>

          <div class="custella-content-row-input col-6">
            <span class="custella-content-row-input-title-two" id="label_JobComplIcPassport">
              {{ translate?.jobOrder.fields.icPassport.singular }}
            </span>

            <p class="custella-content-row-input-title" id="value_JobComplIcPassport">
              {{ data?.jobOrderCompletionRecordVm?.icPassportNumber }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- PHOTO -->
    <ng-container *ngIf="data?.jobOrderCompletionRecordVm?.attachmentList && data?.jobOrderCompletionRecordVm?.attachmentList.length">
      <div class="custella-form-container-title flex-between rounded-top">
        <div class="font-medium text-14" id="title_JobComplPhoto">
          {{ translate?.jobOrder.fields.photo.singular }}
        </div>
      </div>

      <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
          <div class="custella-content-row-input col" *ngFor="let attachment of data?.jobOrderCompletionRecordVm?.attachmentList">
            <a [href]="attachment.attachmentUrl" target="_blank" class="pr-3 pt-1" id="link_JobComplAttach">
              <img class="img-form" [src]="attachment.attachmentUrl" alt="" id="img_JobComplAttach" />
            </a>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- PDF -->
    <ng-container>
      <div class="custella-form-container-title flex-between rounded-top">
        <div class="font-medium text-14" id="title_JobComplForm">
          {{ translate?.jobOrder.fields.jobOrderCompletionForm.singular }}
        </div>
      </div>

      <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
          <div class="custella-content-row-input col-12">
            <p class="custella-content-row-input-title">
              <a class="text-secondary" [href]="data?.jobOrderCompletionRecordVm?.jobOrderPdf?.attachmentUrl" target="_blank" id="link_JobComplFormPdf">
                {{ data?.jobOrderCompletionRecordVm?.jobOrderPdf?.fileName }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Location -->
    <ng-container *ngIf="data?.locationLat">
      <div class="custella-form-container-title flex-between rounded-top">
        <div class="font-medium text-14" id="title_JobComplLoc">
          {{ translate?.jobOrder.fields.location.singular }}
        </div>
      </div>

      <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
          <div class="custella-content-row-input col-12">
            <p-gmap [options]="options" [overlays]="overlays" [style]="{ width: '100%', height: '400px' }"></p-gmap>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- USER RECORD -->
    <ng-container>
      <div class="custella-form-container-title flex-between rounded-top">
        <div class="font-medium text-14" id="title_JobComplSysInfo">
          {{ translate?.jobOrder.fields.system.singular }}
        </div>
      </div>

      <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
          <div class="custella-content-row-input col-6">
            <span class="custella-content-row-input-title-two" id="label_JobComplCreatedBy">
              {{ translate?.jobOrder.fields.createdBy.singular }}
            </span>

            <div class="custella-vp-picture d-flex align-items-center">
              <p-avatar [image]="data.jobOrderCompletionRecordVm?.createdByUserImageUrl" styleClass="mr-2 mt-2" shape="circle" *ngIf="data.jobOrderCompletionRecordVm?.createdByUserImageUrl && data.jobOrderCompletionRecordVm?.createdByUserImageUrl !== ''" id="img_JobComplCreatedByAvatar"></p-avatar>
              <p-avatar image="assets/images/profile-pic.png" styleClass="mr-2 mt-2" shape="circle" *ngIf="!data.jobOrderCompletionRecordVm?.createdByUserImageUrl || data.jobOrderCompletionRecordVm?.createdByUserImageUrl == ''" id="img_JobComplCreatedByAvatar"></p-avatar>

              <p class="custella-content-row-input-title-three mx-2" id="value_JobComplCreatedByFullName">
                {{ data?.jobOrderCompletionRecordVm?.createdByUserName }}
                <span class="" id="value_JobComplCreatedByDateTime">, {{ data?.jobOrderCompletionRecordVm?.createdDateTime | momentDate: 'hh:mm a' }}, {{ data?.jobOrderCompletionRecordVm?.createdDateTime | momentDate: dateFormat:timezone }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
