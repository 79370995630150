<div class="custella-form-container-content">
    <div class="custella-content-row justify-content-unset">
        <div class="custella-content-row-input col-3">
            <p-dropdown optionLabel="label" optionValue="name" [(ngModel)]="teamCharts.selectedTimeframe" [options]="teamCharts.timeFrames" (onChange)="updateData($event)" id="picklist_ChubbStatusTimeFrameField"></p-dropdown>
        </div>
        <div class="custella-content-row-input col-3">
            <p-dropdown optionLabel="label" optionValue="name" [(ngModel)]="teamCharts.selectedRegion" [options]="teamCharts.regions" (onChange)="updateData($event)" id="picklist_ChubbStatusRegionField"></p-dropdown>
        </div>
    </div>
    <div class="custella-content-row pe-0">
        <div class="all-team-box-chart w-100 gap-3 pb-4" *ngIf="teamCharts.teams.length > 0">
            <div class="custella-dash-chart-two m-0 cursor-pointer" *ngFor="let team of teamCharts.teams" id="btn_ChubbStatusNavigate" (click)="navigateTo(team)">
                <div class="custella-dash-chart-two-title">
                    <p class="text-12 color-var-500 me-3 text-center text-nowrap" id="label_ChubbStatusTeamName">{{team.name | uppercase}}</p>
                </div>

                <div class="chart-area d-flex justify-content-center">
                    <ngx-charts-pie-chart
                        [scheme]="team.colorScheme"
                        [view]="teamCharts.chartConfig.view"
                        [results]="team.data"
                        [legend]="teamCharts.chartConfig.legend"
                        [legendTitle]="teamCharts.chartConfig.legendTitle"
                        [doughnut]="teamCharts.chartConfig.isDonut"
                    >
                    </ngx-charts-pie-chart>
                </div>

                <div class="custella-content-row-two-non align-items-center justify-content-center ms-3 me-3">
                    <div class="team-chart-data-label">
                        <div *ngFor="let data of team.data" class="text-10 d-flex">
                            <div class="status-ball mt-1 mb-1 me-2" [ngStyle]="{ 'background-color': data.colorCode }" id="color_ChubbStatusTeamColors"></div>
                            <div class="text-nowrap" id="value_ChubbStatusTeamName">
                                {{data.name}} ({{data.value}}%)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="teamCharts.teams.length === 0" class="col-12 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2">
        <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" alt="" />
        <p class="text-6 my-1 font-weight-bold">
           {{translate?.general.messages.noRecordsFound}}
        </p>
    </div>
</div>
