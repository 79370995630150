import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DateHelper, Scheduler, StringHelper , Popup, EventModel } from '@bryntum/scheduler';
import { BryntumSchedulerComponent } from '@bryntum/scheduler-angular';
import * as _ from 'lodash';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { TeamService } from 'src/app/_services/team.service';
import { Subscription } from 'rxjs';
import { WebSocketsService } from 'src/app/_services/web-sockets.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { MenuItem } from 'primeng/api';

var dateFormateChange: any;
var viewPresetSC : any
@Component({
  selector: 'app-view-staff-task-schedules',
  templateUrl: './view-staff-task-schedules.component.html',
  styleUrls: ['./view-staff-task-schedules.component.scss']
})
export class ViewStaffTaskSchedulesComponent implements OnInit ,OnDestroy {
  mode: any
  onlineUsersList: any
  filterDay: any;
  typeOfUsersList: any
  taskStatus: any;
  fromDateTime: any;
  toDateTime: any;

  createTask: any;
  filterByView = "hourAndDay"
  taskDateRange: any;
  ///////////////////////////////////////////////////////////////
  @ViewChild('BryntumSchedulerComponent') schedulerComponent: BryntumSchedulerComponent | undefined;tasks: any;
  show: any;
  users: any[]=[];
  teams: any;
  selectedTeam: any[]=[];
  child: any;

  scheduler: Scheduler | undefined;
  filter: any={onlineUsers:'ALL',typeOfUsers : 'ALL',status : '', fromDateTime : '', toDateTime : ''};
  showNoUsers!: boolean;
  resources: any[] = [];
  events: any = [];
  byTeamOnlyChk: boolean = true;
  employees: any;
  notTeamMember: Boolean = false;
  teamsChkShow: boolean = false;
  leaves: any;
  team: any;
  schedulerConfig: any
  features:any
  viewPreset:any;
  refreshTasks : Subscription;
  refreshUser: Subscription;
  colors = ['#AD1457','#AB1A58','#D62161','#D30915','#E47D75','#F1522C','#ED6C1F','#EE9224','#F5BE3B','#E3C34D','#C0C842','#7DB249','#3BB57B','#167F45','#159588','#1B9CE2','#4688F1','#4054B2','#7A88C9','#B39ED9','#9D6BAD','#8D2CA8','#785549','#616161','#A79B8F']
 
  filterActive: boolean = false;
  filterClick: boolean = false;

  toggle : boolean = true;
  translate: any;
  items: MenuItem[];
  displayJobOrderTemplates = false;
  constructor(
    private router: Router,
    private spinner : NgxSpinnerService,
    private taskService: TasksService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private route: ActivatedRoute,
    private modalService2 : ModalServiceService,
    private auth : AuthenticationService,
    private perm: PermissionService,
    private util: UtilServiceService,
    private teamService : TeamService,
    private webSocket : WebSocketsService,
    private languageTranslateService: LanguageTranslateService
    
  ) { 

    this.refreshTasks = taskService.refreshList$.subscribe(item => {
      console.log(item)
      this.loadDownline()
    });
    this.refreshTasks = teamService.refreshList$.subscribe(item => {
      console.log(item)
      this.loadDownline()
    });
    
    this.refreshUser = webSocket.refreshOnlineStatus$.subscribe(user => {
      this.updateUserInfoUsingWebSocket(JSON.parse(user.body));
    });

    this.features = {
      timeRanges : {
        showCurrentTimeLine : true,
      },
      cellEdit  : {disabled:true},
      eventDrag : {
          constrainDragToResource : true,
          showExactDropPosition   : true
      }
     
    }

  }
getBrynthumDataInit(){
  var currentDate: any = new Date()
  this.events = [];
  this.schedulerConfig = {
  columns :[
    { type: 'tree', 
    text: this.translate?.task.fields.staffMember.plural, id:"label_TaskStaffMembers",
    field: 'title',align: 'left',
    width: '16em', 
     // Hide default tree icons
     expandedFolderIconCls  : null,
     collapsedFolderIconCls : null,
     leafIconCls            : null,
     // Set to `false` to render our custom markup
     htmlEncode             : false,
     cellEdit : {disabled : true },
    renderer({ cellElement,record, value, size }: {cellElement:any,record: any,value: any , size: any}) {
        // Parent rows are employees
        if (record.isParent) {
            // Make employee row higher
            size.height = 60;
            // Employee template
            var style=`style="border: solid 2px ${record.color}"`
                 return '<div style="height: 32px; padding: 2px 0; display: flex; align-items: center;"><div '+style+'  class="mr-2 p-avatar p-component p-avatar-image p-avatar-circle task-avatar" >'+
                  '<img src="'+record.imageUrl+'" id="img_TaskAvatarUser">'+
                  '</div>'+ value +'</div>';
          }
            // Other rows are clients
            else {
            // Client template(child template)
            var style=`style="border: solid 2px ${record.color}"`
            var img="../../../../../assets/svg/Avatar.svg"
            var status=''
            if(record.imageUrl !== null){
              img=record.imageUrl
            }
            var displayMap=''
            if(viewPresetSC == 'hourAndDay'){
              displayMap ='<img alt="logo" class="viewRoute ms-2 cursor-pointer" src="../../../../../assets/svg/sidebar/map.svg" id="img_TaskMap"/>'
           }

            if(record.online){
              status='<div class="custella-online-mini" style="top: 12px;left: -15px;position: relative;" ></div>';
            }else{
              status=' <div class="custella-offline-mini" style="top: 12px;left: -15px;position: relative;" ></div>';
            }
            return '<div style="height: 32px; padding: 2px 0; display: flex; align-items: center;"><div '+style+' class="mr-2 p-avatar p-component p-avatar-image p-avatar-circle task-avatar" >'+
            '<img src="'+img+'"  id="img_TaskAvatarStaff">'+
            '</div>'+status+ '<span class="text-12" id="value_TaskStaffName">'+ value +'</span>'+displayMap+'</div>';

        }
    },
  }
  ],
  startDate  : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 7),
  endDate    : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23),//should not give>23),
  rowHeight : 40,
  barMargin : 5,
  features : {
    eventEdit : true,
    cellEdit : {disabled : true    },
    resourceTimeRanges : true,
    tree           : true,
    eventTooltip : {
      showOnHover: true,
      bodyCls : {
        'b-resize-monitors'     : 1,
        },
        hideDelay  : 200,
      // template returns a custom element created in app.module.ts
      template : ({ eventRecord }:{ eventRecord: any }): string => {
        console.log(eventRecord)
        setTimeout(() => {  
          $('body').addClass('b-resize-monitored');
        }, 500);
        var startTime = moment.tz(eventRecord.data.startDate,this.auth.getUserTimezone()).format('DD-MM-YYYY');
        var startDateTime =  moment(eventRecord.data.startDate).format("hh:mm a")
        var endDateTime =  moment(eventRecord.data.endDate).format("hh:mm a")
        var taskStatus = ''
          if (eventRecord.data.taskStatus == "OPEN") {
            taskStatus='<span class="p-mr-2 p-tag p-component p-tag-open" id="tag_TaskStatusOpen"><span class="p-tag-value text-dark">Open</span></span>' ;
          } else if (eventRecord.data.taskStatus == "PENDING") {
            taskStatus= '<span class="p-mr-2  p-tag p-component p-tag-pending" id="tag_TaskStatusPending"><span class="p-tag-value text-dark">Pending</span></span> ';
          } else if ( eventRecord.data.taskStatus == "IN PROGRESS" || eventRecord.data.taskStatus == "IN_PROGRESS") {
            taskStatus= '<span class="p-mr-2  p-tag p-component p-tag-in-progress" id="tag_TaskStatusInProgress"><span class="p-tag-value text-dark">In Progress</span></span>';
         
          } else if (eventRecord.data.taskStatus == "COMPLETED") {
            taskStatus='<span class="p-mr-2  p-tag p-component p-tag-completed" id="tag_TaskStatusComplete"><span class="p-tag-value text-dark">Completed</span></span>';
          
          } else if ( eventRecord.data.taskStatus == "INCOMPLETE" ||  eventRecord.data.taskStatus == "IN_COMPLETED") {
            taskStatus='<span class="p-mr-2  p-tag p-component p-tag-incomplete" id="tag_TaskStatusIncomplete"><span class="p-tag-value text-dark">Incomplete</span></span>';
          } else if (eventRecord.data.taskStatus == "CANCELLED") {
            taskStatus='<span class="p-mr-2  p-tag p-component p-tag-cancel" id="tag_TaskStatusCancel"><span class="p-tag-value text-dark">Incomplete</span></span>';
          }
        

        var location = eventRecord.data.location ? eventRecord.data.location:'-'
          return `
          <div class="custella-form-container m-0 h-100 rounded-2">
            <div class="custella-form-container-title d-flex px-3">
                <div class="font-medium text-14 elips col justify-content-start" id="label_TaskName">${eventRecord.name}</div>
                <span class="text-12 font-medium ms-2 text-wrap col" style="text-align: end;" id="label_TaskStatus">${taskStatus}</span>
            </div>
            <!-- Name and Email -->
            <div class="custella-form-container-content font-black">
                <div class="custella-content-row">
                       
                    <div class="custella-content-row-input">
                      <div class="d-flex align-items-center">
                          <i class="pi pi-clock" id="img_TaskDuration"></i>
                          <span class="text-12 font-medium ms-2" id="value_TaskDuration">${startDateTime} - ${endDateTime}</span>
                      </div>
                    </div>
                    <div class="custella-content-row-input">
                      <div class="d-flex align-items-center">
                          <i class="pi pi-calendar" id="img_TaskDurStart"></i>
                          <span class="text-12 font-medium ms-2" id="value_TaskDurStart">${startTime}</span>
                      </div>
                    </div>
                </div>
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                      <div class="d-flex align-items-baseline">
                          <i class="pi pi-map" id="img_TaskLocation"></i>
                          <span class="text-12 font-medium ms-2 text-wrap" id="value_TaskLocation">${location}</span>
                      </div>
                    </div>
                </div>
            </div>
        </div>`
      }
     }

    },
    createEventOnDblClick: false,
    readOnly: true,
   
  };
  this.getTodaysData()
}
  ngOnInit() {
    this.createTask = this.perm.capable("tasks", "create");
    viewPresetSC = this.filterByView
    dateFormateChange = this.auth.getUserTimezone();
    if (this.route.snapshot.data['mode']) {
      this.mode = this.route.snapshot.data['mode']
     
      }
      this.languageTranslateService.menuSrc$.subscribe(data => {
        if(Object.keys(data).length != 0){ 
          this.translate = data
          this.filterDay  =[
            {label: this.translate?.task.fields.day.singular,value:'hourAndDay'},
            {label: this.translate?.task.fields.week.singular,value:'weekAndDay'},
            {label: this.translate?.task.fields.month.singular,value:'monthAndYear'},
          ]
          this.taskStatus  =[
            {label:this.translate?.general.status.open.singular,value:'OPEN'},
            {label:this.translate?.general.status.pending.singular,value:'PENDING'},
            {label:this.translate?.general.status.inProgress.singular,value:'IN_PROGRESS'},
            {label:this.translate?.general.status.completed.singular,value:'COMPLETED'},
            {label:this.translate?.general.status.incompleted.singular,value:'IN_COMPLETED'}
          ]

          this.onlineUsersList=[
            {label: this.translate?.task.fields.allUsers.singular,value:'ALL'},
            {label:this.translate?.task.fields.onlineUsers.singular,value:'ONLINE'},
            {label:this.translate?.task.fields.offlineUsers.singular,value:'OFFLINE'}
          ]
          this.typeOfUsersList =[
            {label:this.translate?.task.fields.directReports.singular,value:'ALL'},
            {label:this.translate?.task.fields.allStaff.singular,value:'SUBORDINATES'}
          ]

          this.items = [
            {label: this.translate.general.buttons.createViaTemplate.singular, command: () => { this.showTable1() },
          }
        ]

          this.getBrynthumDataInit()

      this.changeCalendarView()
        }
      })



     
   //this.spinner.show()
  }

  ngOnDestroy(){
    this.refreshUser.unsubscribe();
    this.refreshTasks.unsubscribe();
    localStorage.removeItem('calendarDate');
  }
  getFilter(){
    if(this.toggle){
      this.changeCalendarView()
    }else{
     var ss = this.filter
      console.log(this.filter);
    }
  }

  changeCalendarView(){
   console.log(this.filterByView);
   viewPresetSC = this.filterByView
   this.viewPreset = this.filterByView
    if(this.filterByView =='hourAndDay'){
      this.viewPreset = {
        base              : 'hourAndDay',
        displayDateFormat : 'll'
      }
      this.loadDownline();
      ////debugger
    }
    else if(this.filterByView==='weekAndDay'){
      this.viewPreset = {
        base              : 'weekAndDay',
        displayDateFormat : 'll',
        headers : [// Override headers
          {
            unit : "WEEK",
            dateFormat : 'MMM YYYY',
          },
          {
            unit       : 'day',
            dateFormat : 'ddd DD',
         
          }
        ]       
     }
      this.loadDownline();
      ////debugger
    }else if(this.filterByView==='monthAndYear'){
      this.viewPreset= {
        base              : 'monthAndYear',
        headers : [
            {
                unit       : 'month',
                dateFormat : 'MMM YYYY',
            },
            {
                unit     : 'week',
                renderer : (startDate:any, endDate: any) => `Week ${DateHelper.format(startDate, 'WW')}`
            },
          
            {
                unit       : 'day',
                dateFormat : 'ddd DD'
            },
            // {
            //     unit     : 'day',
            //     renderer : (startDate: any, endDate: any, headerConfig: any, index: any) => index % 4 === 0 ? Math.round(Math.random() * 5) : ''
            // }
        ]
      }
      this.loadDownline();
      ////debugger
    }
 
  }



 
  openMapLocation(){
    // if(this.mode == 'upcoming'){
    //     this.router.navigate(['dashboard/tasks/upcomingStaffTask'])
    // }else if(this.mode == 'today'){
    //   this.router.navigate(['dashboard/tasks/todayStaffTask'])
    // }else{
    //   this.router.navigate(['dashboard/tasks/allStaffTask'])
    // } 

    this.toggle = !this.toggle
    this.getFilter()
  }
  optimize(){
    this.router.navigate(['dashboard/tasks/optimize'])
  }
  
  clone(){
       var currentDate: any = moment(this.taskDateRange).format('YYYY-MM-DD')
        var fromDateTime = moment(currentDate+ ' ' + " 12:00 AM");
        this.fromDateTime = moment.tz(fromDateTime, this.auth.getUserTimezone()).utc().format();
       
        var toDateTime: any = moment(currentDate+ ' ' + " 11:59 PM");
        this.toDateTime = moment.tz(toDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
        console.log( this.fromDateTime,"======",this.toDateTime);
        
    const data={
      fromDateTime: this.fromDateTime,
      toDateTime: this.toDateTime
    }
    this.modalService2.openModal('taskClone', data);
  }

  openModal(modalView: any, data : any) {
    data={mode : 'add' }
    this.modalService2.openModal(modalView, data);
  }
  getTodaysData(){
   if(this.mode == 'upcoming'){
    this.taskDateRange = new Date()
    this.taskDateRange.setDate(this.taskDateRange.getDate() + 1);
   }else{
    this.taskDateRange = new Date();
   }
    var startD = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD');
    console.log(this.team)
   // this.loadDownline();
    
  }

  onCompletionDateChange(event: any){
    console.log(event,"====",this.taskDateRange)
      this.fromDateTime = moment(this.taskDateRange).format('YYYY-MM-DD');
      
        console.log(event,"====",this.taskDateRange)
        if(this.taskDateRange){
          this.schedulerConfig.startDate = new Date(event.getFullYear(), event.getMonth(), event.getDate(), 7);
          this.schedulerConfig.endDate = new Date(event.getFullYear(), event.getMonth(), event.getDate(), 23);//should not give>23

          var startD = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD');
          console.log(this.team)
        //  this.loadDownline();
        this.getFilter()
        
    } 
   
  }
  updateUserInfoUsingWebSocket(user: any){
    var tasks = Object.assign([], this.tasks);
    this.tasks = [];
    let matchingIndex = _.findIndex(this.resources, function(o) { return o.id == user.id; });
    if(matchingIndex !== -1){
      this.resources[matchingIndex].online = user.online;
      this.resources = _.cloneDeep(this.resources);
      let matchingTasks = _.filter(tasks, function(o) { return o.assignedTo.id == user.id; });
      if(matchingTasks.length){
        matchingTasks.forEach((task, index) => {
          var matchingIndex2 = _.findIndex(tasks, function(o:any) { return o.id == matchingTasks[index].id; });
          tasks[matchingIndex2].assignedTo.online = user.online;
        });
        this.tasks = tasks;
      }
    }
  }

  filterUsers(){
    this.spinner.show();
    var allStaff = null;
    var online = null;
    if(this.filter.typeOfUsers == 'SUBORDINATES'){
      allStaff = true;
    }
    
    if(this.filter.onlineUsers == 'ONLINE'){
      online = 'ONLINE';
    } else if(this.filter.onlineUsers == 'OFFLINE'){
      online = 'OFFLINE';
    }

    this.teamService.getManagedUsersWithTasks(this.auth.getUserId(), 'true', online, allStaff, 'true', this.taskDateRange, this.taskDateRange).subscribe((res:any) => {
      var resources: any[] = [];
      if(!res){
        this.showNoUsers = true;
        this.spinner.hide();
        return;
      }
      res.forEach((user:  any ) => {
        if(user.colorCode){
          var color: any = user.colorCode;
        } else {
          var color: any = this.util.getRandomColor();
        }
        var photo;
        if(user.imageUrl && user.imageUrl !== ''){
          photo = user.imageUrl
        } else {
          photo = "../../../../../assets/svg/Avatar.svg";
        }
        var resource = { 
          id: user.id, 
          userId: user.id,
          title: user.firstName + ' ' + user.lastName,
          fullName: user.firstName + ' ' + user.lastName,
          photoUrl : photo,
          imageUrl : photo,
          role : user.roleName,
          online : user.online,
          color : color,
          manager : user.manager
        };
        resources.push(resource);
      });
      this.resources = resources;
      this.loadDownline();
      ////debugger
    });
  }


  loadDownline(){
    this.spinner.show();
    var allStaff = null;
    var online = null;
    if(this.filter.typeOfUsers == 'SUBORDINATES'){
      allStaff = true;
    }
    if(this.filter.onlineUsers == 'ONLINE'){
      online = 'ONLINE';
    } else if(this.filter.onlineUsers == 'OFFLINE'){
      online = 'OFFLINE';
    }
    if(this.filterByView === 'weekAndDay'){
      var startOfWeek = moment(this.taskDateRange).startOf('week').toDate();
      var endOfWeek   = moment(this.taskDateRange).endOf('week').toDate();
      var startDate : any= moment.tz(startOfWeek, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      var endDate : any= moment.tz(endOfWeek, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      this.schedulerConfig.startDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate(), 7);
      this.schedulerConfig.endDate = new Date(endOfWeek.getFullYear(), endOfWeek.getMonth(), endOfWeek.getDate(), 23);//should not give>23
     
    }
    else if(this.filterByView === 'monthAndYear'){
      var startOfMonth: any = moment(this.taskDateRange).startOf('month').toDate();
      var endOfMonth : any  = moment(this.taskDateRange).endOf('month').toDate();
      var startDate : any= moment.tz(startOfMonth, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      var endDate: any = moment.tz(endOfMonth, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      this.schedulerConfig.startDate = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), startOfMonth.getDate(), 7);
      this.schedulerConfig.endDate = new Date(endOfMonth.getFullYear(), endOfMonth.getMonth(), endOfMonth.getDate(), 23);//should not give>23
     
    }
    else{
      var startDate: any = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      var endDate: any = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      this.schedulerConfig.startDate = new Date(this.taskDateRange.getFullYear(), this.taskDateRange.getMonth(), this.taskDateRange.getDate(), 7);
      this.schedulerConfig.endDate = new Date(this.taskDateRange.getFullYear(), this.taskDateRange.getMonth(), this.taskDateRange.getDate(), 23);//should not give>23
     
    }
    this.teamService.getManagedUsersWithTasksV3(this.auth.getUserId(), 'true', online, allStaff, 'true', startDate, endDate,true,this.filter.status).subscribe((res:any) => {
    var users:  any [] = [];
      var events:  any [] = [];
      if(!res){
          this.showNoUsers = true;
          this.spinner.hide();
          return;
      }
      res.forEach((user:  any ) => {
        if(user.colorCode){
          var color: any = user.colorCode;
        } else {
          var color: any = this.util.getRandomColor();
        }
        var photo;
        if(user.imageUrl && user.imageUrl !== ''){
          photo = user.imageUrl
        } else {
          photo = "../../../../../assets/svg/Avatar.svg";
        }
        var resource = { 
          id: user.id, 
          userId: user.id,
          title: user.firstName + ' ' + user.lastName,
          fullName: user.firstName + ' ' + user.lastName,
          photoUrl : photo,
          imageUrl : photo,
          role : user.roleName,
          color : color,
          online : user.online,
          manager : user.manager
        };
        users.push(resource);
        if(user.tasks){
          user.tasks.forEach((task:  any ) => {
            var findColorMatch = _.filter(users, function(o) {
              return o.id === task.assignedTo.id
            });
            if(!findColorMatch.length){
              var tempColor = {color : '#60e0d6'}
              findColorMatch.push(tempColor);
            }
              if(task.assignStatus == 'PENDING'){
                task.title = task.taskName,
                task.start = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                task.end = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                task.color = "#ffffff",
                task.borderColor  = findColorMatch[0].color,
                task.textColor = "#000000",
                task.resourceId = task.assignedTo.id
                task.taskId = task.id          
                task.id = task.id+Math.random()
                task.eventStyle = "border"
               // task.eventStyle = "hollow"
                task.eventColor = findColorMatch[0].color
                task.startDate = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                task.endDate = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
                task.startTime = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                task.endTime = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                task.name = task.taskName
                task.customerName = task.customer ? task.customer.name : '',
                task.taskStatus = task.taskStatus
                events.push(task);
              }
              else if (task.assignStatus == 'ACCEPTED'){
                task.title = task.taskName,
                task.start = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                task.end = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                task.color = findColorMatch[0].color,
                task.textColor = "#ffffff",
                task.resourceId = task.assignedTo.id
                task.taskId = task.id          
                task.id = task.id+Math.random()
                task.eventStyle = "border"
                task.eventColor = findColorMatch[0].color
                task.startDate = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                task.endDate = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
                task.startTime = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                task.endTime = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                task.name = task.taskName
                task.customerName = task.customer ? task.customer.name : ''
                task.taskStatus = task.taskStatus
                events.push(task);
              }
          });
        }
      });
      this.resources = users;
      this.events = events;
      console.log('events of staff tasks',this.events)
      console.log('resources of staff tasks', this.resources)
      this.tasks = events;
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    },(err: any) =>{
      this.spinner.hide();
      if(err.error.detail != "noDownLineFound")
      {
        const title = this.errorUtil.processErrorTitle(err.error,this.translate)
        this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
      }

    });
  }
 
   /***************************************** Bryntum Cell Click Event**********************************************/
     
   onSchedulerEvents(event : any) {
    //console.log(event.type)
    switch(event.type) {
     
      case 'eventclick':
              if(event.eventRecord.data){
                if(event.eventRecord.data.name != 'Busy' && !(event.eventRecord.data.name.includes('Leave'))){
                  this.viewTask(event.eventRecord.data.taskId);
                }
              }
                break;
      case "cellclick":
            if(event.event.target.classList.contains('viewRoute')){
                 this.viewRoute(event);
                }
                  break
             case 'celldblclick':
                   this.onCellDblClick(event);
                   break;
  } // eo function dispatchEvent
}

onCellDblClick({ record, cellElement, column }:{ record:any, cellElement: any, column :any}): void {
  // Show a custom editor when dbl clicking a client cell
    if (column.field === 'title' && record.isLeaf) {
      
      new Popup({
          autoShow     : true,
          autoClose    : true,
          closeAction  : 'destroy',
          scrollAction : 'realign',
          forElement   : cellElement,
          anchor       : true,
          width        : '20em',
          cls          : 'client-editor',
          items        : [{
              type       : 'text',
              name       : 'name',
              label      : 'User',
              labelWidth : '4em',
              disabled   : true,
              value      : record.title,
              onChange   : ({ value }:{ value :any}) => {
                  record.title = value;
              }
          }, {
              type        : 'combo',
              cls         : 'b-last-row',
              name        : 'color',
              label       : 'Color',
              labelWidth  : '0em',
              listCls     :'demo',
              editable    :false,
              items       : this.colors.map(color => [color.toLowerCase(), color]),
              listItemTpl : (data :any)=> `<div style="background-color: ${data.text};border-radius: 20px;height: 20px;width: 20px;float: left;"></div>`,
              value       : record.color,
              onChange    : ({ value }:{value: any}) => {
                  record.color = value;
                  this.changeCalendarColor(record.color,record)
              }
          }]
      });
       
       // eo new Popup
  } // eo if(name && leaf)
} // eo function onCellDblClick
changeCalendarColor(colorCode : any, resource: any){
  var req = {
    colorCode: colorCode,
    type:"CALENDER",
    user:{id: resource.userId},
    calenderOwner:{id: this.auth.getUserId()}
  }
  this.teamService.setUserCalendarColor(req).subscribe((res:any) => {
  })
}

 /***************************** View Task *********************************************************************/ 
viewTask(id: any){
  //  var url = this.router.serializeUrl(
  //   this.router.createUrlTree(['/dashboard/tasks/allMyTask'], { queryParams: { taskId:id,requestAssistance:true}})
  //  );
  //  url = url.replace('%23', '#');
  //  window.open(url, '_blank');
  //  window.focus();
 
  this.util.openTaskVIewPage(id)
 }
 /***************************** View Route *********************************************************************/
 viewRoute({ record, cellElement, column }:any): void {
  var startDate = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD')
  var url = this.router.serializeUrl(
    this.router.createUrlTree(['/dashboard/tasks/dailyRouteMaps'], { queryParams : {'userId': record.userId, 'date' : startDate}})
  );
  url = url.replace('%23', '#');
  window.open(url, '_blank');
}
showDirections(){
  var startDate = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD')

  this.router.navigate(['/dashboard/tasks/map'], { queryParams: {'teamView' : true, 'date' : startDate,'directions':true}});
   
}
 

onFilteruttonClick(event: any) {
  this.filterActive = !this.filterActive;
  this.filterClick = !this.filterClick;
  event.preventDefault();
}
resetSearch(){
  this.filter=
  {
  onlineUsers:'ALL',
  typeOfUsers : 'ALL',
  status : ''};
    this.getFilter();
  
}

  /******************************************** close asset table ******************************************/
  public closeTable() {
    this.displayJobOrderTemplates = false;
  }
  /******************************************** show / hide asset table ******************************************/
  public showTable1() {
    this.displayJobOrderTemplates = !this.displayJobOrderTemplates;
  }

}

