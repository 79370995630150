<ag-grid-angular
#agGrid
class="ag-theme-balham h-100 w-100"

[columnDefs]="columnDefs"
[gridOptions]="gridOptions"
[defaultColDef]="defaultColDef"
[maxConcurrentDatasourceRequests]="-1"
[suppressDragLeaveHidesColumns]="true"
[allowContextMenuWithControlKey]="true"
[getContextMenuItems]="getContextMenuItems"
[frameworkComponents]="frameworkComponents"
(gridReady)="onGridReady($event)"
[overlayNoRowsTemplate]="overlayNoRowsTemplate"
(cellClicked)="viewAsset($event)"
[getContextMenuItems]="getContextMenuItems"
></ag-grid-angular>
<div class="col-12"><p class="ag-record-row-summary-panel padds text-end">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p></div>
