

<div *ngIf="assetsSummary" class="custella-form-container box-none bg-white m-0">
    <!-- Additional Details -->
    <div class="custella-form-container-title-two flex-between">
        <div class="flex-align-center">
            <span class="text-14 font-medium" id="title_JobOrdAssetSummary">{{translate?.general.summary.sections.sec0.singular}}</span>
            <span class="ms-2">
                <i class="fa-regular fa-arrow-up-right-from-square" id="btn_JobOrdAssetNewTab" [pTooltip]="translate?.general.buttons.openInNewTab.singular" tooltipPosition="bottom" (click)="onAssetViewOnNewTab()"></i>
            </span>
        </div>
        <span  (click)="closeRightNav()" id="btn_JobOrdAssetSummaryClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="bottom"><i class="pi pi-times"></i></span>
    </div>
    <div class="scroll">
        <div class="custella-form-container-image" *ngIf="assetsSummary.attachments.length > 0">
            <p-image [src]="assetsSummary.attachments[0].attachmentUrl" alt="Asset Image" width="155" id="img_TeamAvatar">
             </p-image>
        </div>
        <div class="ov-content-solo pb-3">
            <div class="ov-row">
                <div class="label-c" id="label_JobOrdAssetName">{{assetsSummary.name}}</div>
            </div>
            <div class="ov-row justify-content-between">
                <div class="flex-align-baseline">
                  <div class="label" id="label_JobOrdAssetLatestReading">{{translate?.general.summary.fields.latestReading.singular}}&colon;&nbsp;</div>
                  <span *ngIf="assetsSummary.equipmentMeterReading" class="value" id="value_JobOrdAssetLatestReading">{{assetsSummary.equipmentMeterReading | number:'1.0-0'}}</span>
                  <span *ngIf="!assetsSummary.equipmentMeterReading" class="value" id="label_JobOrdAssetLatestReading">{{translate?.general.messages.notAvailable}}</span>
                </div>
                <div class="dropdown options mr-2" >
                  <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                      <div class="d-flex align-items-center rounded-2 border bg-white h-27px">
                         
                          <span  class="p-mr-2 slider-tag p-tag p-component" [attr.style]="
                          'background-color:' + assetsSummary.assetsStatus.colorCode + '50 !important;' + 'color:' + assetsSummary.assetsStatus.colorCode " ><span class="p-tag-value text-dark">{{assetsSummary.assetsStatus.name}}</span></span>
                         
                          <div *ngIf="updateAssets" role="button" aria-haspopup="listbox" class="p-dropdown-trigger" >
                              <span class="p-dropdown-trigger-icon fa-regular fa-chevron-down mx-2 font-black"></span>
                          </div>
                      </div>
                  </div>
  
                  <ul class="dropdown-menu dropdown-collaps assets-tag p-0" *ngIf="updateAssets" >
                    <div class="py-07">
                      <li class="dropdown-item cursor-pointer py-1" *ngFor="let assetStatuse of assetStatuses" (click)="onAcceptAssetStatus(assetsSummary, assetStatuse)">
                          <span  class="p-mr-2 p-tag p-component" [attr.style]="
                                'background-color:' + assetStatuse.colorCode + '50 !important;' + 'color:' + assetStatuse.colorCode "
                              ><span class="p-tag-value text-dark">{{ assetStatuse.name }}</span></span>
                      </li>
                    </div>
                      
                  </ul>
              </div>
                
            </div>
        </div>
        <div class="ov-title-summ">
            <label class="font-medium" id="title_JobOrdAssetGenDets">{{translate?.general.summary.sections.sec1.singular}}</label>
        </div>
        <div class="ov-content-solo">
            <div class="container p-0">
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetSerialNo">{{ translate?.asset.fields.serialNo.singular }}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div  *ngIf="assetsSummary.serialNo" class="p-0 value" id="value_JobOrdAssetSerialNo">{{assetsSummary.serialNo}}</div>
                    <span *ngIf="!assetsSummary.serialNo" class="value" id="value_JobOrdAssetSerialNo">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetType">{{ translate?.asset.fields.type.singular }}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div class="p-0 value" id="value_JobOrdAssetType">{{assetsSummary.assetsType?.name}}</div>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetCode">{{ translate?.asset.label.singular }}&nbsp;{{ translate?.asset.fields.code.singular }}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="assetsSummary.assetsCode" class="p-0 value" id="value_JobOrdAssetCode">{{assetsSummary.assetsCode}}</div>
                    <span *ngIf="!assetsSummary.assetsCode" class="value" id="value_JobOrdAssetCode">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetEquipNumber">{{ translate?.asset.fields.equipmentNumber.singular }}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div  *ngIf="assetsSummary.equipmentNumber" id="value_JobOrdAssetEquipNumber" class="p-0 value">{{assetsSummary.equipmentNumber}}</div>
                    <span *ngIf="!assetsSummary.equipmentNumber" id="value_JobOrdAssetEquipNumber" class="value">{{"-"}}</span>
                  </div>
                </div>
            </div>
        </div>
        
        <div class="ov-title-summ">
            <label class="font-medium" id="title_JobOrdAssetOwnershipLoc">{{translate?.general.summary.sections.sec2.singular}}</label>
        </div>
        <div class="ov-content-solo">
            <div class="container p-0">
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetCustomer">{{translate?.customer.label.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="assetsSummary.customer" class="p-0 value" id="value_JobOrdAssetCustomer">{{assetsSummary.customer?.name}}</div>
                    <span *ngIf="!assetsSummary.customer" class="value" id="value_JobOrdAssetCustomer">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetSite">{{translate?.site.label.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div  *ngIf="assetsSummary.site" class="p-0 value" id="value_JobOrdAssetSite">{{assetsSummary.site?.siteName}}</div>
                    <span *ngIf="!assetsSummary.site" class="value" id="value_JobOrdAssetSite">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetServZone">{{translate?.setup.serviceZone.label.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="assetsSummary.serviceZone" class="p-0 value" id="value_JobOrdAssetServZone">{{assetsSummary.serviceZone?.name}}</div>
                    <span *ngIf="!assetsSummary.serviceZone" class="value" id="value_JobOrdAssetServZone">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetCustodian">{{translate?.asset.fields.custodian.singular }}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="assetsSummary.custodian" class="p-0 value" id="value_JobOrdAssetCustodian">{{assetsSummary.custodian?.fullName}}</div>
                    <span *ngIf="!assetsSummary.custodian" class="value" id="value_JobOrdAssetCustodian">{{"-"}}</span>
                  </div>
                </div>
            </div>
        </div>
        <div class="ov-title-summ">
            <label class="font-medium" id="title_JobOrdAssetMaintenanceDets">{{translate?.general.summary.sections.sec3.singular}}</label>
        </div>
        <div class="ov-content-solo border-0">
            <div class="container p-0">
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetNextMaintenance">{{translate?.general.summary.fields.nextMaintenance.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div  *ngIf="assetsSummary.nextMaintenanceDate" class="p-0 value" id="value_JobOrdAssetNextMaintenance">{{assetsSummary.nextMaintenanceDate | momentDate : dateFormat : timezone }}</div>
                    <span *ngIf="!assetsSummary.nextMaintenanceDate" class="value" id="value_JobOrdAssetNextMaintenance">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0 align-items-center">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetStatus">{{translate?.asset.fields.status.singular }}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <!-- <div class="p-0 value"><span class="p-tag cancel" ><span class="p-tag-value text-dark">{{'overdue_'}}</span></span></div> -->
                    
                    <span class="p-tag overdue" *ngIf="assetsSummary.overDueStatus" id="label_AssetOverviewMainStatusOverdue"><span class="p-tag-value text-dark">{{translate?.general.status.overdue.singular}}</span></span>
                    <span class="p-tag approve"  *ngIf="!assetsSummary.overDueStatus " id="label_AssetOverviewMainStatusUpDate"><span class="p-tag-value text-dark">{{translate?.general.status.upToDate.singular}}</span></span>
               
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_JobOrdAssetWarrantyEnds">{{translate?.general.summary.fields.warrantyEnds.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="assetsSummary.warrantyEndDate" class="p-0 value" id="value_JobOrdAssetWarrantyEnds">{{assetsSummary.warrantyEndDate | momentDate : dateFormat : timezone}}</div>
                    <span *ngIf="!assetsSummary.warrantyEndDate" class="value" id="value_JobOrdAssetWarrantyEnds">{{"-"}}</span>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
