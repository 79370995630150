<div class="h-95">
    <!-- <div class="custella-taskOpt-header">
        <button class="transparent-btn" (click)="goBack()">
            <img src="assets\images\chubb\ic_arrow_forward.png" alt="Exit Map" class="w-19px cursor-pointer">
        </button>
        <p class="text-14 font-white font-semiBold ms-3">Exit Map</p>
    </div> -->

    <div class="d-flex">
        <div class="w-50 br-right-5656 h-951px">
            <!-- Insert Map here -->

            <!-- Map -->
            <google-map #plannedMap height="100%" width="100%" [center]="optionsMap1.center" [zoom]="optionsMap1.zoom"
                [options]="mapOptions">
                <map-polyline *ngFor="let line of polyLines" [path]="line?.polyLine"
                    [options]="line?.newPolyOption"></map-polyline>

                <map-marker #marker2="mapMarker" *ngFor="let marker of planMarkers;let i = index"
                    (mapClick)="openInfoWindow(marker2, marker,i)" [position]="marker" [options]="marker.options"
                    zIndex="20" [icon]="{url: marker.icon,labelOrigin:pointer, scaledSize: scaledSize}"
                    [label]="{color:'white',fontWeight: 'normal', fontSize: '14px',text:((marker?.label)?(marker?.label):' ')}">
                </map-marker>
            </google-map>

            <!-- Div -->
            <div class="position-absolute w-40 top-51px transform-x-10px">
                <div class="custella-form-container-marker rounded-2 mt-0">
                    <!-- General Details -->
                    <div class="custella-form-container-marker-title rounded-top">
                        <div class="font-medium text-14" id="title_TaskOptPlanned" >{{ translate?.task.sections.sec6.singular }}</div>
                    </div>
                    <!-- Name and Email -->
                    <div class="custella-form-container-marker-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title-two" id="label_TaskOptActTravelTime">{{ translate?.task.fields.actualTravelTime.singular }}</span>
                                <span class="custella-content-row-input-title-three" id="value_TaskOptActTravelTime">{{ totalTravelTime1 | minutesSeconds }}</span>
                            </div>
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title-two" id="label_TaskOptActTravelDistance">{{ translate?.task.fields.actualTravelDistance.singular }}</span>
                                <span class="custella-content-row-input-title-three" id="value_TaskOptActTravelDistance">{{ totalTravelDistance1 | distanceToKm }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-50">
            <!-- Insert Map here -->
            <!-- Map -->
            <google-map #plannedMap height="100%" width="100%" [center]="optionsMap.center" [zoom]="optionsMap.zoom"
                [options]="mapOptions">
                <map-polyline *ngFor="let line of polyLines" [path]="line?.polyLine"
                    [options]="line?.newPolyOption"></map-polyline>

                <map-marker #marker1="mapMarker" *ngFor="let marker of markers;let i = index" class="plannedClass"
                    (mapClick)="openInfoWindow(marker1, marker,i)" [position]="marker" [options]="marker" zIndex="20"
                    [icon]="{url: marker.icon,labelOrigin:pointer, scaledSize: scaledSize}"
                    [label]="{color:'white',fontWeight: 'normal', fontSize: '14px',text:((marker?.label)?(marker?.label):' ')}">
                </map-marker>
                <map-info-window>
                    <div class="custella-form-container-marker m-0 pb-2 h-100 rounded-2">
                        <div class="custella-form-container-marker-title px-p75rem">
                            <div class="flex-between">
                                <span class="text-12 font-semiBold me-2" id="label_TaskSelTaskName">{{ selectedMarker?.taskName }}</span>
                            </div>
                        </div>
                        <!-- Name and Email -->
                        <div class="custella-form-container-marker-content font-black px-2 m-0 pb-2">
                            <div class="custella-content-row">
                                <div class="custella-content-row-input">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/svg/table/clock.svg" id="img_TaskSelTaskOrderTime" alt="Order Time" class="w-24px">
                                        <span class="text-12 font-medium ms-2" id="label_TaskSelTaskStartEndTime">{{ selectedMarker?.startTime | momentDate :'hh:mm a' : timezone }} - {{ selectedMarker?.endTime | momentDate :'hh:mm a' : timezone }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="custella-content-row">
                                <div class="custella-content-row-input col-12">
                                    <div class="d-flex">
                                        <img src="assets/svg/table/map-marker-black.svg" id="img_TaskSelLoc" alt="Location" class="w-24px">
                                        <span class="text-12 font-medium ms-2 text-wrap" id="label_TaskSelLoc">{{ selectedMarker?.location }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </map-info-window>
            </google-map>
            <!-- Div -->
            <div class="position-absolute w-40 top-51px transform-x-10px">
                <div class="custella-form-container-marker rounded-2 mt-0">
                    <!-- General Details -->
                    <div class="custella-form-container-marker-title flex-between rounded-top">
                        <div class="font-medium text-14" id="title_TaskOptActual" >{{ translate?.task.sections.sec7.singular }}</div>
                    </div>
                    <!-- Name and Email -->
                    <div class="custella-form-container-marker-content-border-bottom text-12 font-medium">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title-two" id="label_TaskOptActTravelTime2">{{ translate?.task.fields.actualTravelTime.singular }}</span>
                                <span class="custella-content-row-input-title-three" id="value_TaskOptActTravelTime2">{{ totalTravelTime | minutesSeconds }}</span>
                            </div>
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title-two" id="label_TaskOptActTravelDistance2">{{ translate?.task.fields.actualTravelDistance.singular }}</span>
                                <span class="custella-content-row-input-title-three" id="value_TaskOptActTravelDistance2">{{ totalTravelDistance | distanceToKm2 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
