import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import * as moment from "moment-timezone";
import { PermissionService } from "src/app/auth/permission.service";
import { Router } from "@angular/router";
import { AssetService } from "src/app/_services/asset.service";
import {
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorUtil } from "src/app/_utilities/error";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { Global } from "src/app/_utilities/global";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
var dateFormateChange: any;
@Component({
  selector: "app-contracts-table",
  templateUrl: "./contracts-table.component.html",
})
export class ContractsTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy {
  @Output() noData = new EventEmitter();
  @Output() isFilterClosed = new EventEmitter();
  @Input() searchValueContract: any;
  @Input() sourceComponent: any;
  //@Output() contractClicked = new EventEmitter<{ message: any, source: string }>();
  private id: any;
  @Input() data: any;
  @Input() assetId: any;
  //aggrid(contracts)
  gridParamsContract: any;
  gridApiContracts: any;
  columnDefsContracts: any[] = [];
  startFrom: any;
  assets: any;
  parameters: any;
  gridOptionsContracts!: GridOptions;
  contracts: any;
  noContracts!: boolean;
  statuses: any = null;
  frameworkComponents: any;
  defaultColDef: any;
  overlayNoRowsTemplate: any;
  create: any;
  public totalRecord: any;
  public filterView = false;
  public allStatus: any[];
  private deleteSubHasPushed = false;
  loadTable = 0;
  sortBy: any;
  sortOrder: any;
  dateFormat: any;
  actionData: any;
  @Input() translate: any;
  tableContent: any

  constructor(
    public util: UtilServiceService,
    private assetService: AssetService,
    private perm: PermissionService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private auth: AuthenticationService,
    private dynamicFormService: DynamicFormService,
    private translator: DynamicLabelsService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();


    this.preInit();
  }

  ngOnInit() {
    this.init();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.searchValueContract &&
      changes.searchValueContract.previousValue !== changes.searchValueContract.currentValue
    ) {

      this.refresh();
    }

    if (
      changes &&
      changes.searchValueContract &&
      changes.searchValueContract.previousValue !== changes.searchValueContract.currentValue &&
      changes.searchValueContract.currentValue === ""
    ) {
      //  debugger
      this.refresh();
    }
    /************************** Check translate object changed or not:  Language Translated Dynamically (Preeti) ************************************/
    if (
      changes &&
      changes.translate && changes.translate.previousValue != undefined && Object.keys(changes.translate.previousValue).length !== 0 &&
      changes.translate.previousValue !== changes.translate.currentValue
    ) {

      this.setOnlyColumn();
    }

    console.log("sourceCompoe", this.sourceComponent, this.data)
  }
  /************************** Set Table Header:  Language Translated Dynamically (Preeti) ************************************/
  private setOnlyColumn() {
    // Reset columnDefs to avoid duplication
    this.gridOptionsContracts.columnDefs = [];
    console.log(this.tableContent);
    var columnDefs: any = this.gridOptionsContracts.columnDefs;
    this.tableContent.tableColumnSetups.forEach((element: any) => {
      this.cellRenderer(element);
      columnDefs.push(element);
      this.gridOptionsContracts.api!.setColumnDefs(columnDefs);
    });
    this.actionData.headerName = this.translate?.general.buttons.action.singular;
    columnDefs.push(this.actionData);
    this.gridOptionsContracts.api!.setColumnDefs(columnDefs);
    setTimeout(() => { this.gridApiContracts.sizeColumnsToFit(); }, 500);
  }
  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** pre init some variables ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptionsContracts = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.actionData = {
      headerName: this.translate?.general.buttons.action.singular,
      sortable: false,
      filter: false,
      width: 150,
      headerTooltip: "#",
      headerClass: "marging-auto, hide-action-border",
      cellStyle: { "text-align": "center" },
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.editContract.bind(this),
        permissions: {
          update: this.perm.capable("assets_contracts", "update"),
          remove: this.perm.capable("assets_contracts", "remove"),
        },
        hidden: {
          update: !this.perm.capable("assets_contracts", "update"),
          remove: !this.perm.capable("assets_contracts", "remove"),
        },
      },
    };
  }

  /******************************************** init ******************************************/
  private init() {
    this.create = this.perm.capable("assets_contracts", "create");
    this.preInitTableColumn()
    this.allStatus = [
      {
        value: "ON_RENT",
        label: this.translate?.general.status.onRent.singular,
      },
      {
        value: "EXPIRED",
        label: this.translate?.general.status.expired.singular,
      },
      {
        value: "NEW",
        label: this.translate?.general.status.new.singular,
      },
      {
        value: "TERMINATED",
        label: this.translate?.general.status.terminated.singular,
      },
    ];
    // if (this.data?.customerId) {
    //   const index = this.columnDefsContracts.findIndex(
    //     (cd) => cd.field === "customerName"
    //   );

    //   if (index !== -1) {
    //     this.columnDefsContracts.splice(index, 1);
    //   }
    // }


  }
  /********************************** PreInit *****************************/
  private preInitTableColumn() {
    this.dateFormat = localStorage.getItem("date_format");
    dateFormateChange = this.auth.getUserTimezone();

    this.dynamicFormService.getTableColumn("contract").subscribe((res: any) => {
      this.tableContent = res;
      this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder

      var columnDefs: any = []
      res.tableColumnSetups.forEach((element: any) => {
        this.cellRenderer(element);

        columnDefs.push(element);
        this.gridOptionsContracts.api!.setColumnDefs(columnDefs);
      });
      this.overlayNoRowsTemplate = "<div class='not-found'><span>" + this.translate?.general.messages.noRecordsFound + "</span> </div>";

      this.actionData.headerName = this.translate?.general.buttons.action.singular;
      this.columnDefsContracts.push(this.actionData);

      columnDefs.push(this.actionData);

      this.loadTable = 1;
      this.gridOptionsContracts.api!.setColumnDefs(columnDefs);
      this.onGridReadyContract(this.gridParamsContract);
    });
  }
  /********************************** Render Cell *****************************/
  private cellRenderer(element: any) {
    console.log(element, "===", element.dataType)

    var dateFormat: any = localStorage.getItem("date_format");
    if (element.translatePath) {
      element.headerName = this.languageTranslateService.getNestedValue(this.translate, element.translatePath);
    }

    element.headerTooltip = "#";

    if (element.field === "contractNumber") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_Head_AssetContract'),
        element.cellRenderer = function (params: any) {
          if (params.data) {
            return Global.setCellRenderer(params, params.data.rowId, params.data.contractNumber)
          }
        };
    }

    else if (element.field === "customer.name") {
      element.headerComponentParams = Global.setHeaderComponentParams(element, 'Tbl_Head_AssetContraCusName'),
        element.cellRenderer = (params: any) => {
          if (params.data && params.data.customer != null) {
            return Global.setCellRenderer(params, params.data.rowId2, params.data.customer.name)
          }
        };
    }

    else if (element.field === "status") {

      element.cellRenderer = (params: any) => {
        if (params.data) {
          if (params.data.status == "ON_RENT") {
            ;
            return (
              '<span class="p-tag p-component on-rent"><span class="p-tag-value text-dark">' + this.translate?.general.status.onRent.singular +
              "</span></span>"
            );
          } else if (params.data.status == "EXPIRED") {
            return (
              '<span class="p-tag p-component expire"><span class="p-tag-value text-dark">'
              + this.translate?.general.status.expired.singular +
              "</span></span>"
            );
          } else if (params.data.status == "NEW") {
            return (
              '<span class="p-tag p-component new"><span class="p-tag-value text-dark">' + this.translate?.general.status.new.singular + '</span></span>'
            );
          } else if (params.data.status == "TERMINATED") {
            return (
              '<span class="p-tag p-component terminate"><span class="p-tag-value text-dark">' + this.translate?.general.status.terminated.singular + "</span></span>"
            );
          }
        }
      };
    }


    else if (element.dataType == 'date' || element.dataType == "text" || element.dataType == "datetime" || element.dataType == "boolean") {
      element = Global.setColumnByDataType(element, this.auth.getUserTimezone(), dateFormat)
    }
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** on table ready ******************************************/
  public onGridReadyContract(params: any) {
    this.gridParamsContract = params;
    this.searchValueContract = this.searchValueContract
      ? this.searchValueContract
      : "";
    this.gridApiContracts = params.api;

    var dataSource = {
      getRows: (params: any) => {
        if (this.loadTable == 1 && this.translate) {
          if (!this.data) {
            this.getServerSideDataAll(params);
          } else if (this.data && this.data.assetId) {
            this.id = this.data.assetId;
            this.getServerSideDataAssetId(params);
          } else if (this.data && this.data.customerId) {
            this.id = this.data.customerId;
            this.getServerSideDataCustomerId(params);
          }else if (this.data && this.data.priceBookId) {
            this.id = this.data.priceBookId;
            this.getServerSideDataPriceBookId(params);
          }
        }
      },
    };



    this.gridApiContracts.sizeColumnsToFit();
    this.gridApiContracts.setDatasource(dataSource);
  }

  /******************************************** get contract data all - no id ******************************************/
  getServerSideDataAll(params: any) {
    // Show spinner when starting data loading
    this.spinner.show();
    this.gridApiContracts.hideOverlay();
    this.startFrom = params.startRow;
    const serverRequest = this.getRequestParams(params);
    let count = serverRequest.startRow ? serverRequest.startRow : 0;

    this.push(
      this.assetService
        .getContractGrid(
          this.startFrom,
          25,
          this.searchValueContract,
          this.statuses,
          serverRequest
        )
        .subscribe((res: any) => {
          this.gridApiContracts.hideOverlay();
          console.log("check asset contract", res)
          this.contracts = res.body.data;
          this.contracts.forEach((element: any) => {
            count++
            element.rowId = 'Tbl_RecAssetContractNo' + count
            element.rowId2 = 'Tbl_RecAssetContraCusName' + count
            element.buttonEdit = 'Btn_AssetContractEdit' + count
            element.buttonDelete = 'Btn_AssetContractDel' + count
          });
          if (res.body.status === "SEARCH_NO_RESULTS" || res.body.status === "NO_DATA") {
            this.noContracts = true;
          } else {
            this.noContracts = false;
          }
          if (this.contracts.length === 0) {
            this.gridApiContracts.showNoRowsOverlay();
            params.successCallback([], 0);
          } else {
            params.successCallback(this.contracts, res.body.total);
          }
          this.totalRecord = res.body.total;
          this.noData.emit(this.noContracts);

          this.spinner.hide();

        },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }
        )
    );

  }

  /******************************************** get contract data by asset id ******************************************/
  getServerSideDataAssetId(params: any) {
    // Show spinner when starting data loading
    this.spinner.show();
    this.gridApiContracts.hideOverlay();

    this.startFrom = params.startRow + 1;

    const serverRequest = this.getRequestParams(params);
    let count = serverRequest.startRow ? serverRequest.startRow : 0;

    this.push(
      this.assetService
        .getAssetContractGrid(
          this.startFrom,
          25,
          this.searchValueContract,
          this.id,
          this.statuses,
          serverRequest
        )
        .subscribe((res: any) => {
          this.gridApiContracts.hideOverlay();
          this.contracts = res.body.data;
          this.contracts.forEach((element: any) => {
            count++
            element.rowId = 'Tbl_RecAssetContractNo' + count
            element.rowId2 = 'Tbl_RecAssetContraCusName' + count
            element.buttonEdit = 'Btn_AssetContractEdit' + count
            element.buttonDelete = 'Btn_AssetContractDel' + count
          });
          if (res.body.status === "SEARCH_NO_RESULTS" || res.body.status === "NO_DATA") {
            this.noContracts = true;
          } else {
            this.noContracts = false;
          }
          if (this.contracts.length === 0) {
            this.gridApiContracts.showNoRowsOverlay();
            params.successCallback([], 0);
          } else {
            params.successCallback(this.contracts, res.body.total);
          }

          this.totalRecord = res.body.total;

          this.noData.emit(this.noContracts);

          this.spinner.hide();
        },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          })
    );



  }

  /******************************************** get contract data all - no id ******************************************/
  getServerSideDataCustomerId(params: any) {
    // Show spinner when starting data loading
    this.spinner.show();
    this.gridApiContracts.hideOverlay();

    this.startFrom = params.startRow + 1;

    const serverRequest = this.getRequestParams(params);
    let count = serverRequest.startRow ? serverRequest.startRow : 0;

    this.push(
      this.assetService
        .getContractByCustomer(
          this.startFrom,
          25,
          this.searchValueContract,
          this.statuses,
          serverRequest,
          this.id
        )
        .subscribe((res: any) => {
          this.gridApiContracts.hideOverlay();

          this.contracts = res.body.data;
          this.contracts.forEach((element: any) => {
            count++
            element.rowId = 'Tbl_RecAssetContractNo' + count
            element.rowId2 = 'Tbl_RecAssetContraCusName' + count
            element.buttonEdit = 'Btn_AssetContractEdit' + count
            element.buttonDelete = 'Btn_AssetContractDel' + count
          });
          if (res.body.status === "SEARCH_NO_RESULTS" || res.body.status === "NO_DATA") {
            this.noContracts = true;
          } else {
            this.noContracts = false;
          }

          if (this.contracts.length === 0) {
            this.gridApiContracts.showNoRowsOverlay();
            params.successCallback([], 0);
          } else {
            params.successCallback(this.contracts, res.body.total);
          }

          this.totalRecord = res.body.total;

          this.noData.emit(this.noContracts);

          this.spinner.hide();
        },
          (err) => {
            console.log(err)
            const title = this.errorUtil.processErrorTitle(err.error, this.translate)
            this.errorUtil.setErrorMessage(400, null, title, 'error', 3000);
            this.spinner.hide();
          }


        )
    );

  }

   /******************************************** get contract data by asset id ******************************************/
   private getServerSideDataPriceBookId(params: any) {
    this.gridApiContracts.hideOverlay();

    this.startFrom = params.startRow + 1;

    const serverRequest = this.getRequestParams(params);
    let count  = serverRequest.startRow ? serverRequest.startRow : 0;
    this.push(
      this.assetService
        .getPriceBookContractGrid(
          this.startFrom,
          25,
          this.searchValueContract,
          this.id,
          this.statuses,
          serverRequest
        )
        .subscribe((res: any) => {
          this.gridApiContracts.hideOverlay();
          this.contracts = res.body.data;
          this.contracts.forEach((element: any) => {
            count++
            element.rowId='Tbl_RecAssetContractNo'+count
            element.rowId2 = 'Tbl_RecAssetContraCusName'+count
            element.buttonEdit='Btn_AssetContractEdit'+count
            element.buttonDelete='Btn_AssetContractDel'+count
          });
          if (res.body.status === "SEARCH_NO_RESULTS" || res.body.status === "NO_DATA") {
            this.noContracts = true;
          } else {
            this.noContracts = false;
          }
          if (this.contracts.length === 0) {
            this.gridApiContracts.showNoRowsOverlay();
            params.successCallback([], 0);
          } else {
            params.successCallback(this.contracts, res.body.total);
          }

          this.totalRecord = res.body.total;

          this.noData.emit(this.noContracts);
        })
    );
  }

  /******************************************** get request params ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.sortBy, sort: this.sortOrder }
      agGridRequest.sortModel.push(this.parameters);
    }

    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** refresh ******************************************/
  public refresh() {
    console.log(this.statuses)
    if (this.gridParamsContract) {
      this.onGridReadyContract(this.gridParamsContract);
    }
  }

  /******************************************** edit/delete ******************************************/
  private editContract(e: any) {
    if (e.action === "edit") {
      // const data = {
      //   assetId: null,
      //   assetsCode: null,
      //   serialNo: null,
      // };

      this.util.setPersistantItem(PERSISTANT_ITEM.CONTRACTS, e.rowData);

      this.router.navigate(["/dashboard/contracts/updateContract"], {
        queryParams: { assetId: this.assetId ? this.assetId : null, source: this.sourceComponent },
      });
    } else if (e.action === "delete") {
      let data: any;
      if(this.sourceComponent == "priceBook"){
        data = {
          deleteHeader : this.translate.contracts.label.singular,
          deleteType: "Contract",
          id: e.rowData.id,
          message:  this.translate?.general.messages.confirmDelete+" "+  `${e.rowData.contractNumber} ${this.translate.priceBook.messages.fromThisPriceBook}? ${this.translate?.general.messages.irreversibleAction}`,
        };
      }else{
       data = {
        deleteHeader : this.translate.contracts.label.singular,
        deleteType: "Contract",
        id: e.rowData.id,
        message: this.translate?.general.messages.confirmDelete + " " + `${e.rowData.contractNumber}?`,
      };
    }

      if (!this.deleteSubHasPushed) {
        this.push(
          this.deleteRecordService.getItems(data).subscribe(() => {
            this.onGridReadyContract(this.gridParamsContract);
          })
        );

        this.deleteSubHasPushed = true;
        console.log(this.deleteSubHasPushed);
      } else {
        this.deleteRecordService.getItems(data);
      }
    }
  }

  /******************************************** on cell clicked ******************************************/
  public viewContract(e: any) {
    this.util.resetTabView();
    if (e.colDef.field === "contractNumber") {
      this.router.navigate(["/dashboard/contracts/view/" + e.data.id], {
        queryParams: { id: e.data.id, source: this.sourceComponent, assetId: this.data?.assetId },
      });
    } else if (e.colDef.field === "salesOrder.orderName" && e.data.salesOrder) {
      this.router.navigate(["dashboard/salesOrders/view/" + e.data.salesOrder.id], {
        queryParams: {
          customerId: e.data.customerId,
          salesOrderId: e.data.salesOrderId,
        },
      });
    } else if (e.colDef.field === "customer.name" && e.data.customer) {
      this.router.navigate(["dashboard/customers/view/" + e.data.customer.id], {
        queryParams: { id: e.data.customer.id },
      });
    }
  }

  /******************************************** toggle filter ******************************************/
  public toggleFilterView(): void {
    this.filterView = !this.filterView;
    this.isFilterClosed.emit(this.filterView)
  }

  /******************************************** clear all filters ******************************************/
  public clearAll() {
    this.statuses = null;
    this.searchValueContract = "";
    this.onGridReadyContract(this.gridParamsContract);
  }

  /******************************************** return status ******************************************/
  public returnStatus(status: any) {
    return this.allStatus.find((theStatus) => theStatus.value === status).label;
  }

  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];
    console.log(params.column.colId)
    if (params.column.colId == "contractNumber") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (params.column.colId == "salesOrder.orderName" && params.node.data.salesOrder) {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (params.column.colId == "customer.name" && params.node.data.customer) {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    console.log(data)
    if (data.field === "contractNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/dashboard/contracts/view/" + data.id],
          {
            queryParams: { id: data.id, source: this.sourceComponent },
          }
        )
      );
    }

    if (data.field === "salesOrder.orderName") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/salesOrders/view/" + data.salesOrder.id],
          {
            queryParams: {
              customerId: data.customerId,
              salesOrderId: data.salesOrder.id,
            },
          }
        )
      );
    }

    if (data.field === "customer.name") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/customers/view/" + data.customer.id],
          {
            queryParams: { id: data.customer.id },
          }
        )
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }

}
