<div class="modal-header flex-between border-0 pb-1">
    <div class="">
        <span class="text-16 font-medium" id="title_JobCalendlySendLink">{{translate?.jobOrder.fields.sendCalendlyLink.singular}}</span>
        <span class="text-12 font-medium color-9e d-block" id="subtitle_JobCalendlySendLink">{{translate?.jobOrder.fields.selectToSendCalendlyLink.singular}}</span>
    </div>
    <i class="fa-regular fa-xmark" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" id="btn_JobCalendlyClose" (click)="modalRef.hide()"></i>
</div>

<div class="modal-body tix pt-1">
    <form [formGroup]="calendlyLinkForm">
    <div class="custella-form-container rounded-2 ms-0 me-2 box-none border-dee">
        <!-- Container Content -->
        <div class="custella-form-container-title rounded-top" id="title_JobCalendlyDets">
            <span class="text-14 font-semiBold">{{translate?.general.messages.step1}}&colon;&nbsp;</span>
            <span class="text-14">{{translate?.jobOrder.fields.calendlyStepOne.singular}}</span>
        </div>
        <div class="custella-form-container-content my-3">
            <div class="mb-3 col-12"> 
                <span class="custella-content-row-input-title mandatory" id="label_JobCalendlyFrom">{{translate?.jobOrder.fields.whoseCalendarSent.singular}}</span>
                <div class="flex-align-center">
                    <input *ngIf="calendlyLinkData.fromType =='JOB_ORDER_OWNER'" type="text" readonly  [(ngModel)]="calendlyLinkData.orderOwner.fullName" class="mx-500px" 
                    formControlName="fromUser" pInputText autocomplete="off" maxlength="255" id="input_JobCalendlyFromField" [attr.disabled]="true"/>
                 
                    <p-dropdown *ngIf="calendlyLinkData.fromType =='CURRENT_USER_DOWNLINE'" class="w-54"
                        [placeholder]="translate?.general.messages.none"
                       id="picklist_JobCalendlyFromField" 
                       [(ngModel)]="userId"
                       [options]="users" 
                       [showClear]="true" 
                       formControlName="fromUser" 
                       optionLabel="label" 
                       dataKey="id"
                       [autoDisplayFirst]="false"
                       filterBy="label" (onChange)="onchangeUser($event)" optionValue="id"></p-dropdown>

                    
                    <div class="flex-align-center">
                        <button pButton type="button" id="btn_JobCalendlyFromOrderOwner" class="p-button text-nowrap ms-2 w-jo-own" [label]="translate?.jobOrder.fields.joOwner.singular" [ngClass]="{'p-button-secondary':calendlyLinkData.fromType !='JOB_ORDER_OWNER'}"  (click)="fromTypeTo('JOB_ORDER_OWNER')"></button>
                        <button pButton type="button" id="btn_JobCalendlyFromMyDownline" class="p-button text-nowrap p-button-secondary ms-2" [label]="translate?.jobOrder.fields.fromMyDownline.singular"  [ngClass]="{'p-button-secondary':calendlyLinkData.fromType !='CURRENT_USER_DOWNLINE'}"  (click)="fromTypeTo('CURRENT_USER_DOWNLINE')"></button>
                    </div>
                </div>
                <div *ngIf="calendlyLinkForm.controls['fromUser'].touched && calendlyLinkForm.controls['fromUser'].invalid">
                    <small class="text-10 p-error" *ngIf="calendlyLinkForm.controls['fromUser'].errors?.required" id="label_JobCalendlyFromReqField" >{{translate?.jobOrder.validationFE.userCalendlyNoIntegrate}}</small>
                </div>
            </div>
            <div class="mb-3 col-12">
                <span class="custella-content-row-input-title" id="label_JobCalendlySendTo">{{translate?.jobOrder.fields.whoCalendlySentTo.singular}}</span>
                <div class="flex-align-center">
                    <input type="text" #toEmails pInputText autocomplete="off"  [(ngModel)]="calendlyLinkData.toEmails"
                    class="mx-500px" formControlName="toEmails" maxlength="255" id="input_JobCalendlySendToField" *ngIf="sendTo !='CUSTOMER_CONTACT' && sendTo =='CUSTOM'"/>
                    <input type="text" #toEmails pInputText autocomplete="off"  [(ngModel)]="calendlyLinkData.toEmails" [readonly]="sendTo !='CUSTOM'" 
                    class="mx-500px" formControlName="toEmails" maxlength="255" id="input_JobCalendlySendToField" *ngIf="sendTo !='CUSTOMER_CONTACT' && sendTo !='CUSTOM'" [attr.disabled]="true" />
                    <p-dropdown *ngIf="sendTo =='CUSTOMER_CONTACT' && !isContactsDisabled" class="w-54" [placeholder]="translate?.general.messages.none" id="picklist_TaskSmartNoUsersField" #toEmails2
                       [(ngModel)]="calendlyLinkData.toEmails"
                       [options]="contacts" 
                       [showClear]="true" 
                       formControlName="toEmails" 
                       optionLabel="email"
                       dataKey="id"
                       id="picklist_JobCalendlySendToField"
                       [autoDisplayFirst]="false"
                       filterBy="email" (onChange)="onchangeContacts($event)" optionValue="email" [disabled]="isContactsDisabled"></p-dropdown>
                       <input type="text" #toEmails pInputText autocomplete="off"  [(ngModel)]="calendlyLinkData.toEmails" 
                    class="mx-500px" formControlName="toEmails" maxlength="255" id="input_JobCalendlySendToField" *ngIf="sendTo =='CUSTOMER_CONTACT' && isContactsDisabled" [attr.disabled]="true" />
                    
                    <div class="flex-align-center">
                        <button pButton type="button" id="btn_JobCalendlySendToOrderCont" class="p-button text-nowrap ms-2" [label]="translate?.jobOrder.fields.joContact.singular" [ngClass]="{'p-button-secondary':sendTo !='ORDER_CONTACT'}" (click)="sendEmailTo('ORDER_CONTACT')"></button>
                        <button pButton type="button" id="btn_JobCalendlySendToCustCont" class="p-button text-nowrap p-button-secondary ms-2" [label]="translate?.jobOrder.fields.custContact.singular" [ngClass]="{'p-button-secondary':sendTo !='CUSTOMER_CONTACT'}"  (click)="sendEmailTo('CUSTOMER_CONTACT')"></button>
                        <button pButton type="button" id="btn_JobCalendlySendToCustom" class="p-button text-nowrap p-button-secondary ms-2" [label]="translate?.jobOrder.fields.custom.singular" [ngClass]="{'p-button-secondary':sendTo !='CUSTOM'}"  (click)="sendEmailTo('CUSTOM')"></button>
                    </div>
                </div>
                <div *ngIf="calendlyLinkForm.controls['toEmails'].touched && calendlyLinkForm.controls['toEmails'].invalid && isSubmited">
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.required && sendTo =='ORDER_CONTACT'" id="label_JobCalendlySendToReqField">{{translate?.jobOrder.validationFE.noContactFoundForThisJobOrder}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.pattern && sendTo =='ORDER_CONTACT'" id="label_JobCalendlySendToInvalidEmail">{{translate?.general.validationFE.invalidEmailFormat}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.required && sendTo =='CUSTOMER_CONTACT'" id="label_JobCalendlySendToReqField">{{translate?.jobOrder.validationFE.noContactFoundForTheJobOrdersCustomer}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.pattern && sendTo =='CUSTOMER_CONTACT'" id="label_JobCalendlySendToInvalidEmail">{{translate?.general.validationFE.invalidEmailFormat}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.required && sendTo =='CUSTOM'" id="label_JobCalendlySendToReqField">{{translate?.general.validationFE.invalidEmail}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.pattern && sendTo =='CUSTOM'" id="label_JobCalendlySendToInvalidEmail">{{translate?.general.validationFE.invalidEmailFormat}}</span>
                </div>
            </div>
        </div>

        <div class="custella-form-container-title-two" id="title_JobCalendlyBookTrigg" >
            <span class="text-14 font-semiBold">{{translate?.general.messages.step2}}&colon;&nbsp;</span>
            <span class="text-14">{{translate?.jobOrder.fields.calendlyStepTwo.singular}}</span>
        </div>
        <div class="custella-form-container-title-two-grey text-12 fst-italic ps-4" id="subtitle_JobCalendlyBookTrigg">
            {{translate?.jobOrder.fields.whatHappenCustBooking.singular}}
        </div>
        <div class="custella-form-container-content my-3">
            <div class="mb-3 flex-align-center col-12">
                <div>
                    <span class="text-12 font-bold color-56" id="label_JobCalendlyUpdTimeDate">{{translate?.jobOrder.fields.updateJoTimeDate.singular}}</span>
                    <span class="text-10 font-medium d-block color-86" id="label_JobCalendlyUpdTimeDate">{{translate?.jobOrder.fields.willUpdateSlotCustomer.singular}}</span>
                </div>
                <div class="p-field-checkbox ms-auto">
                    <p-checkbox [binary]="true" id="check_JobCalendlyUpdTimeDate" (onChange)="detectChanges()" [(ngModel)]="calendlyLinkData.updateJoDateTime" [ngModelOptions]="{ standalone: true }"></p-checkbox>
                </div>
            </div>
            <div class="mb-3 flex-align-center mt-2 col-12">
                <div>
                    <span class="text-12 font-bold color-56" id="label_JobCalendlyUpdTimeDate">{{translate?.jobOrder.fields.createTaskThisJo.singular}}</span>
                    <span class="text-10 font-medium d-block color-86" id="label_JobCalendlyUpdTimeDate">{{translate?.jobOrder.messages.newTaskForSelectedDateTime}}</span>
                </div>
                <div class="p-field-checkbox ms-auto">
                    <p-checkbox [binary]="true" id="check_JobCalendlyCreateTaskJob" (onChange)="detectChanges()"  [(ngModel)]="calendlyLinkData.generateTask" [ngModelOptions]="{ standalone: true }"></p-checkbox>
                </div>
            </div>
        </div>

        <!-- Condition: JO Created, Generate Link to be copied -->
        <div class="custella-form-container-footer mb-0 row">
            <div class="flex-align-center justify-content-start col">
                <!-- <button type="button" (click)="copyLink()" class="custella-btn-white text-12 ms-2"  id="btn_JobCalendlyCancel">{{"general.buttons.cancel.singular"}}</button> -->
                <!-- <button type="button" class="d-flex custella-btn-white text-12 ms-2" id="btn_DeliverCopyLink" >
                    <i class="pi pi-link d-flex"> <p translate class="ml-1"> copy_link </p></i>
                </button> -->
                <button  type="button" *ngIf="isSend" class="custella-btn-white flex-align-center" id="btn_JobSurveyCopyLink" (click)="copyLink()">
                    <img src="../../../../assets/svg/table/copy-link.svg" class="w-12px">
                    <span class="text-12 ms-2">{{ btnTextCopy  }}</span>
                </button>
            </div>
            <div class="flex-align-center justify-content-end col">
                <button type="button" (click)="modalRef.hide()" class="custella-btn-white text-12 ms-2" id="btn_JobCalendlyCancel">{{ translate?.general.buttons.cancel.singular }}</button>
                <p-button type="button" *ngIf="!isSend" (onClick)="generateLink()" styleClass="ms-2 h-32" id="btn_JobCalendlyGenLink" [label]="translate?.general.buttons.generateLink.singular"></p-button>
                <p-button type="button" *ngIf="isSend" (onClick)="onSubmitForm()" styleClass="ms-2 h-32" id="btn_JobCalendlyGenLink" [label]="translate?.general.buttons.send.singular"></p-button>
            </div>
        </div>

        
    </div>
    </form>
</div>
