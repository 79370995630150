<div class="custella-modal ">
    <div class="custella-modal-header align-items-center">
        <span class="text-14 font-medium ms-2" id="title_SetImportData">{{translate?.setup.importExport.sections.sec2.singular }}</span>
        <i class="pi pi-times" id="btn_SetImportDataClose" [pTooltip]="translate?.general.buttons.close.singular" (click)="modalRef.hide()"></i>
    </div>
    <form  [formGroup]="uploadCSVForm">
        <!-- Container -->
        <div class="custella-form-container position-relative d-block w-100 rounded-2 pb-3 m-0 height-fit-content">
            <!--  -->
            <div class="py-2">
                <div class="d-flex align-items-center justify-content-center m-4">
                    <div class="custella-import-checkpoint ms-4" id="label_SetImportDataCheck1"></div>
                    <div class="custella-import-path-dash" id="label_SetImportDataGap1"></div>
                    <div class="custella-import-uncheckpoint" id="label_SetImportDataUncheck1"></div>
                    <div class="custella-import-path-dash" id="label_SetImportDataGap2"></div>
                    <div class="custella-import-uncheckpoint" id="label_SetImportDataUncheck2"></div>
                    <div class="custella-import-path-dash" id="label_SetImportDataGap3"></div>
                    <div class="custella-import-uncheckpoint me-4" id="label_SetImportDataUncheck3"></div>
                </div>
                <div class="d-flex align-items-center justify-content-center m-4">
                    <span class="text-12 font-medium ms-4" id="label_SetImportDataSelect" >{{translate?.setup.importExport.fields.select2.singular }}</span>
                    <div class="custella-import-path-none" id="label_SetImportDataNon1"></div>
                    <span class="text-12 font-medium" id="label_SetImportDataUpload" >{{translate?.setup.importExport.fields.upload.singular }}</span>
                    <div class="custella-import-path-none" id="label_SetImportDataNon2"></div>
                    <span class="text-12 font-medium" id="label_SetImportDataMapping" >{{translate?.setup.importExport.fields.mapping.singular }}</span>
                    <div class="custella-import-path-none" id="label_SetImportDataNon3"></div>
                    <span class="text-12 font-medium me-4" id="label_SetImportDataImport" >{{translate?.setup.importExport.sections.sec0.singular }}</span>
                </div>
            </div>
            <!-- Match Any -->
            <div class="custella-form-container-title-two-grey d-flex justify-content-between align-items-center">
                <span class="text-14 font-medium" id="title_SetImportModuleFields" >{{translate?.setup.importExport.sections.sec3.singular }}</span>
            </div>
            <div class="custella-form-container-content">
                <div class="custella-content-row pe-0">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title" id="label_SetImportSelectModule"  >{{translate?.setup.importExport.fields.selectModule.singular }}</span>
                        <!-- <p-dropdown optionLabel="name" placeholder="{{'select_a_module'}}"></p-dropdown> -->
                        <p-dropdown [options]="moduleList" [showClear]="true" optionLabel="value" optionValue="key"
                            formControlName="selectedModule" name="selectedModule" [(ngModel)]="selectedModule.key"
                            (onChange)="onModuleChange($event)" placeholder="{{'select_a_module'}}" id="picklist_SetImportSelectModuleField">
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list" id="value_SetImportSelectModuleField">
                                    <div>{{ user.value }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="custella-content-row pe-0">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title" id="label_SetImportSelectAction" >{{translate?.setup.importExport.fields.selectAction.singular }}</span>
                        <!-- <p-dropdown optionLabel="name" placeholder="{{'select_a_field_list'}}"></p-dropdown> -->
                        <p-dropdown [options]="actions" [showClear]="true" optionLabel="label" optionValue="name"
                            formControlName="action" name="action" [(ngModel)]="type" (onChange)="actionChange()" id="picklist_SetImportSelectActionField"
                            [placeholder]="translate?.general.messages.none">
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list" id="value_SetImportSelectActionField">
                                    <div>{{ user.label }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-footer-two">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" id="btn_SetImportCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular }}</button>
                    <p-button [label]="buttonLabel" (onClick)="next()" id="btn_SetImportSubmit" [disabled]="uploadCSVForm.invalid" styleClass="ms-2 h-32"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>