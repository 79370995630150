<div class="custella-modal">
  <form (ngSubmit)="onSubmit()">
    <div class="custella-modal-header align-items-center">
      <span class="custella-modal-header-text" id="title_GoogleCalDisconnect">{{title}}</span>
      <i class="fa-regular fa-xmark"  [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" id="btn_GoogleCalDisconnectClose" (click)="modalRef.hide()"></i>
    </div>
    <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3 height-fit-content">
      <div class="modal-body">
        <div class="container text-center">
          <p >{{translate?.general.messages.confirmAction}}</p>
        </div>
      </div>
      <div class="custella-form-container-footer mb-0">
        <div class="d-flex align-items-center justify-content-end">
          <button class="custella-btn-white text-12 ms-2 me-0" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
          <button pButton type="button" [label]="translate?.general.buttons.submit.singular" iconPos="left" class="p-button-danger ms-2 me-0 h-32"  id="btn_GoogleCalDisconnectSubmit"
            (click)="onSubmit()"></button>
        </div>
      </div>
    </div>
  </form>
</div>
