<div class="modal-header flex-between border-0 pb-1">
    <div class="">
        <span class="text-16 font-medium" id="title_SetIntegrationsSetUpEvents">{{translate?.setup.integrations.sections.sec6.singular }}</span>
        <span class="text-12 pt-1 color-var-500 d-block" id="subtitle_SetIntegrationsSetUpEvents">{{translate?.setup.integrations.sections.sec7.singular }}</span>
    </div>
    <i class="fa-regular fa-xmark" (click)="modalRef.hide()" id="btn_SetIntegrationsSetUpEventsClose"></i>
</div>
<div class="modal-body tix pt-2">
    <div class="custella-form-container rounded-2 ms-0 me-2 box-none border-dee">
        <!-- Container Content -->
        <div class="custella-form-container-title text-14 font-medium rounded-top"id="title_SetIntegrationsSetUpEventsConnectJobOrder">{{translate?.setup.integrations.sections.sec8.singular }}</div>
        <div class="custella-form-container-title-two-grey fst-italic" id="desc_SetIntegrationsSetUpEventsConnectJobOrder">
            <span class="text-12">1&period;&nbsp;<span
                    class="font-bold">&nbsp;{{translate?.setup.integrations.fields.calendlyEvents.singular}}</span>&nbsp;{{translate?.setup.integrations.fields.calendlyEventsSpecificDurations.singular}}</span>
            <span class="text-12 d-block">2&period;&nbsp;{{translate?.setup.integrations.fields.mapEachEventToJobType.singular}}&nbsp;<span
                    class="font-bold">{{translate?.setup.integrations.fields.jobOrderType.singular}}</span>&nbsp;{{translate?.setup.integrations.fields.mapIn.singular}}&nbsp;<span
                    class="font-bold">{{translate?.setup.integrations.fields.calendlyEventId.singular}}</span>&period;</span>
            <span class="text-12 d-block">3&period;&nbsp;{{translate?.setup.integrations.fields.onceAssignAutoUpdateJobOrderTask.singular}}<span
                    class="left-14px">{{translate?.setup.integrations.fields.assignTaskToJob.singular}}</span></span>
        </div>
        <div class="custella-form-container-content mb-2">
            <!-- Title -->
            <div class="custella-content-row">
                <div class="custella-content-row-input col-5 me-0">
                    <span class="text-14 font-medium color-56" id="label_SetIntegrationsSetUpEventsJobType">{{translate?.setup.integrations.fields.jobOrderType.singular }}</span>
                </div>
                <div class="custella-content-row-input col-1 mx-2">
                    <!-- <i class="pi pi-arrow-right"></i> -->
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="text-14 font-medium color-56"id="label_SetIntegrationsSetUpEventsId">{{translate?.setup.integrations.fields.calendlyEventId.singular }}</span>
                </div>
                <!-- <div class="custella-content-row-input me-0 col-1">
                    <i class="pi pi-arrow-right"></i>
                </div> -->
            </div>
            <!-- Input fields -->
            <div class="scroll-content">
                <div class="custella-content-row" *ngFor="let calendlyEventJOT of calendlyEventJobOrderTypes;let i = index">
                    <div class="custella-content-row-input col-5 me-0">
                        <span class="custella-content-row-input-title-three" id="value_SetIntegrationsSetUpEventsJobType">{{calendlyEventJOT.jobOrderType.name}}</span>
                        <!-- <p-dropdown 
                            [(ngModel)]="calendlyEventJOT.jobOrderType.id"
                            optionLabel="name"
                            formControlName="jobOrderType" 
                            [placeholder]="'select_order_type' "
                            [options]="calendlyEventJOT.jobOrderTypes"
                            [showClear]="true"
                            optionLabel="name"
                            optionValue="id" (onChange)="selectJoType(calendlyEventJOT.jobOrderType)"></p-dropdown>
                            <div class="text-10 p-error" *ngIf="calendlyEventJOTForm[i].controls['jobOrderType'].touched && calendlyEventJOTForm[i].controls['jobOrderType'].invalid">
                                <span *ngIf="calendlyEventJOTForm[i].controls['jobOrderType'].errors?.required">{{'general.validationFE.requiredField' }}</span>
                            </div> -->
                    </div>
                    <div class="custella-content-row-input text-center col-1 mx-2">
                        <i class="pi pi-arrow-right arrow-calendly"></i>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <input type="text" pInputText autocomplete="off" maxlength="255" class="max-w-500px" id="input_SetIntegrationsSetUpEventsIdField"
                            [(ngModel)]="calendlyEventJOT.calendlyEventId" [ngModelOptions]="{standalone: true}" />
                    </div>
                </div>
            </div>

            <!-- <div class="custella-content-row-input col-1 mt-1 text-center" (click)="removeRecord(i)">
                    <button *ngIf="i !=0">
                        <img alt="logo" src="../../../../../assets/svg/table/exit_button.svg" />
                    </button>
                </div> -->
        </div>
        <!-- Input Fields Disabled -->
        <!-- <div class="custella-content-row">
                <div class="custella-content-row-input col-5">
                    <p-dropdown optionLabel="name" [disabled]="true"></p-dropdown>
                </div>
                <div class="custella-content-row-input text-center col-1">
                    <i class="pi pi-arrow-right"></i>
                </div>
                <div class="custella-content-row-input col-5 me-0">
                    <input type="text" [disabled]="true" pInputText autocomplete="off" maxlength="255"/>
                </div>
                <div class="custella-content-row-input col-1 mt-1 text-center">
                    <button (click)="createNew()">
                        <img alt="logo" src="../../../../../assets/svg/table/add_button.svg" />
                    </button>
                </div>
            </div> -->


        <div class="custella-form-container-footer mb-0">
            <div class="flex-align-center justify-content-end">
                <button type="button" class="custella-btn-white text-12 ms-2" id="btn_SetIntegrationsSetUpEventsCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular  }}</button>
                <p-button type="button" styleClass="ms-2 h-32" id="btn_SetIntegrationsSetUpEventsSubmit"[disabled]="submitTouched" [label]="translate?.general.buttons.save.singular " (onClick)="onSubmit()"></p-button>
            </div>
        </div>
    </div>
</div>