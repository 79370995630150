import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { ServiceZoneService } from 'src/app/_services/service-zone.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { PERSISTANT_ITEM, ServerRequest } from 'src/app/_models/global.data.model';
import { Router } from '@angular/router';
var translateData: any;
@Component({
  selector: 'app-list-jo-templates',
  templateUrl: './list-jo-templates.component.html',
  styleUrls: ['./list-jo-templates.component.scss']
})
export class ListJoTemplatesComponent extends SubscriptionUtil implements OnInit {
  @Output() closeTable = new EventEmitter();
  @Output() isFilterClosed = new EventEmitter();

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: GridOptions;
  overlayNoRowsTemplate: string;
  productsFamilies: any;
  productsType: any;
  searchValue: any
  currentRecordNumber: any;
  totalRecord: any;

  DataDetails: any[] = [];
  noJOData: boolean = false;
  noJODataIcon: boolean = false;
  joData: any;
  jobOrderTypes: any;
  serviceZones: any;
  systems: any;

  isFilterToggleOn: boolean = false;
  translate: any;
  searchValueData: boolean;
  filter: any = { typeId: null, systemId: null, faultId: null, isCreation: 1 };
  faults: any;

  constructor(
    private languageTranslateService: LanguageTranslateService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private jobOrdersService: JobOrdersService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private breadcrumbService: BreadcrumbService,
    private serviceZonesService: ServiceZoneService,
    private util: UtilServiceService,
  ) {

    super();
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };

  }

  ngOnInit(): void {
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if (Object.keys(data).length != 0) {
        this.translate = data
        translateData = data;
      }
      this.initializeColumns()
      this.loadData();
    })
  }

  /******************************************** let job order table toggle filter ******************************************/
  public callFilterToggle($event: any) {
    // this.jobOrderTableComponent.filterToggle();
  }
  closeAsset() {
    this.closeTable.emit(false)
  }

  /******************************************** toggle the filter sidebar ******************************************/
  public filterToggle() {
    this.isFilterToggleOn = !this.isFilterToggleOn;
    this.isFilterClosed.emit(this.isFilterToggleOn)
  }

  loadData() {
    // get system cetegories
    this.push(
      this.jobOrdersService.getJobOrderSyatemCategory(true).subscribe((res: any) => {
        this.systems = res.filter((sys: any) => sys.active).sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });

        // for magnum

      })
    );

    //***************************************  get fault list *****************************/

    this.getFaultData()

    /**************************************** get all job order types ************************/
    this.push(
      this.jobOrdersService.getJobOrderTypes(true).subscribe((res: any) => {
        this.jobOrderTypes = res?.filter((jot: any) => jot.active).sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });

      })
    );
  }

  viewDets(e: any) {
    if (e.colDef.field === "templateName") {

      const data = {
        joTemplateId: e.data.id,
        joTemplateName: e.data.templateName,
      };

      // this.util.setPersistantItem(PERSISTANT_ITEM.JOB_ORDER_TEMPLATE, data);
       this.router.navigate(['dashboard/jobOrders/create'], { queryParams: { template: true, templateId: e.data.id } });
    }
  }

  onRefreshList() {
    console.log(this.filter,"filter")
    this.onGridReady(this.gridParams);
  }
  /********************************** Search Data **************************/
  quickSearch() {
    if (this.searchValue) {
      this.searchValueData = true
      this.onRefreshList()
    }
  }
  /********************************** Initilize Grid Table **************************/
  onGridReady(params: any) {
    //console.log(params)
    this.gridParams = params;

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }

  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    console.log('Making a server request: ', serverRequest);

    this.push(this.jobOrdersService.getAllJobOrderTemplates(this.searchValue, serverRequest, this.filter.typeId, this.filter.systemId, this.filter.serviceZoneId, this.filter.isCreation, this.filter.faultId).subscribe(
      (data: any) => {
        console.log(data.body);
        this.joData = data.body.data;
        this.totalRecord = data.body.total;
        this.setGridData(
          agGridGetRowsParams,
          this.joData,
          data.body.total,
          data.body.status
        );
        //this.spinner.hide()
      },
      (err) => {
        //console.log(err,"===>>>")
        this.errorUtil.setErrorMessage(err);
      }
    )
    );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number, status: string) {
    console.log(resultItems, "===");

    if (this.joData.length === 0) {
      this.noJOData = false;
      this.noJODataIcon = true;
      this.gridApi.showNoRowsOverlay();
    } else {
      this.noJOData = true;
      this.noJODataIcon = false;

      agGridGetRowsParams.successCallback(this.joData, totalCount);
    }


  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "fault.name", sort: "asc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  resetSearch() {
    this.filter = { typeId: null, systemId: null, faultId: null };
    this.onGridReady(this.gridParams);

  }
  push(obs: any) {
    super.push(obs);
  }
  /********************************** On Destroy *****************************/
  private onDestroy() {
    super.ngOnDestroy();
  }
  public onSearchCheck() {
    if (this.searchValue.length == 0) {
      this.searchValueData = false
      this.searchValue = ''
      this.onRefreshList()
    }
  }

  public onClear() {
    this.searchValueData = false
    this.searchValue = ''
    this.onRefreshList()

  }

  initializeColumns() {
    this.columnDefs = []
    this.overlayNoRowsTemplate = "<div class='not-found'><span>" + this.translate?.general.messages.noRecordsFound + "</span> </div>";
    console.log("initiateCol", translateData, "=======", this.columnDefs)
    var newData = [
      { headerName: this.translate?.setup.modules.templates.fields.templateName.singular, field: 'templateName', width: 150, headerTooltip: "#", cellClass: function (params: any) { return ['table_default_color']; } },
      { headerName: this.translate?.jobOrder.label.singular, field: 'jobOrderName', width: 170, headerTooltip: "#" },
      { headerName: this.translate?.setup.modules.templates.fields.customer.singular, field: 'customer.name', width: 80, headerTooltip: "#", },
      { headerName: this.translate?.setup.modules.templates.fields.orderType.singular, field: 'jobOrderType.name', width: 100, headerTooltip: "#" },
      { headerName: this.translate?.setup.modules.templates.fields.system.singular, field: 'systemValue.name', width: 100, headerTooltip: "#" },

      { headerName: this.translate?.setup.serviceZone.label.singular, field: 'serviceZone.name', width: 100, headerTooltip: "#" },
      {
        headerName: this.translate?.setup.modules.templates.fields.templateStatus.singular, field: 'active', width: 100, headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.templateStatus == 'ACTIVE') {
              return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value">' + translateData?.general.status.active.singular + '</span></span>';
            } else {
              return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">' + translateData?.general.status.inactive.singular + '</span></span>';
            }
          }
        }
      },

    ];

    this.columnDefs = newData;
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.columnDefs);
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 500);
    }
  }


  getFaultData(change: any = null) {
    

      this.push(
        this.jobOrdersService
          .getFaultsData("")
          .subscribe((res1: any) => {
            this.faults = res1.sort((elementA: any, elementB: any) => {
              if (elementA?.order > elementB?.order) {
                return 1;
              }

              if (elementA?.order < elementB?.order) {
                return -1;
              }

              return 0;
            });

           
          })
      );
  }



}
