import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { GridOptions,IServerSideGetRowsParams } from 'ag-grid-community';
import "ag-grid-enterprise"; 
import { Router } from '@angular/router';
import { AssetService } from 'src/app/_services/asset.service';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
var dateFormateChange: any;

@Component({
  selector: 'app-custella-assets',
  templateUrl: './custella-assets.component.html',
  styleUrls: ['./custella-assets.component.scss']
})
export class CustellaAssetsComponent implements OnInit {

  @Output() assetSelector = new EventEmitter();
  @Output() closeAssetView = new EventEmitter();
  @Output() closeTable = new EventEmitter();
  @Input() assetId: any;
  @Input() serviceZoneName: any;
  @Input() lookupFilter: any ='';
  @Input() searchValue: any
 @Input() siteId:any =''
   assets: any;
   currentRecordNumber: any;
   noCustomers: boolean = false;
   noCustomersIcon: boolean = false;
   filter: any = {assetStatuses : '',assetTypes : '',fromDate: '', toDate : ''}
   dateFormat: any;
   assetitemsHandlerSubscription: Subscription
   filterData: boolean = true
  translate: any;
  constructor(
    private router : Router,
    private assetService:AssetService,
    private languageTranslateService: LanguageTranslateService
  ) { 
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
    this.assetitemsHandlerSubscription = assetService.assetitemsHandler.subscribe((response: any) => {
      console.log(response)
      this.selectAssetRecord( response);
  });
  }

  ngOnInit() {
    console.log(this.lookupFilter,"========")
    this.dateFormat = localStorage.getItem('date_format')
   
  }
 

  selectAssetRecord(val:any){
    this.assetSelector.emit(val);
  }

  closeAsset(){
    this.closeAssetView.emit(false)
    this.closeTable.emit(false)
  }
  ngOnDestroy() {
    
    if (this.assetitemsHandlerSubscription) {
      this.assetitemsHandlerSubscription.unsubscribe();
  }


}
  
}