<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_HillsFaultTypeSyst" >{{translate?.dashboard.national.sections.sec0.singular}}</span>
        <button type="button" class="btn-view-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_HillsFtsRefresh" (click)="$event.stopPropagation();firstClick();" >
            <i class="fa-regular fa-arrows-rotate"></i>
        </button>
    </div>
    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium"  id="label_HillsHtrFaultType">{{translate?.dashboard.national.fields.htrFaultTypes.singular}}</span>
    </div>
    <div class="custella-content-row">
        <div class="col-12">
            <div class="p-3 mb-2" #htrContainer>
                <p *ngIf="multi1.length == 0" class="mb-3 text-4 ms-18px" id="label_HtrNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multi1.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [scheme]="colorSchemeHTR"
                        [results]="multi1"
                        [view]="viewHorizontalBar1"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        (window:resize)="resizeChart(htrContainer.offsetWidth, htrContainer.offsetHeight, 'htr')"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsHostelFaultType">{{translate?.dashboard.national.fields.hostelFaultTypes.singular}}</span>
    </div>
    <div class="custella-content-row">
        <div class="col-12">
            <div class="p-3 mb-2" #hostelContainer>
                <p *ngIf="multi2.length == 0" class="mb-3 text-4 ms-18px" id="label_HostelNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multi2.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar2"
                        [scheme]="colorSchemeHostel"
                        [results]="multi2"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        (window:resize)="resizeChart(hostelContainer.offsetWidth, hostelContainer.offsetHeight, 'hostel')"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsLincorFaultType">{{translate?.dashboard.national.fields.lincorFaultTypes.singular}}</span>
    </div>
    <div class="custella-content-row" #lincorContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi3.length == 0" class="mb-3 text-4 ms-18px" id="label_LincorNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multi3.length != 0" class="d-flex align-items-center mb-2 chartScroll ps-60px">
                    <ngx-charts-bar-horizontal [view]="viewHorizontalBar3" [scheme]="colorSchemeLincor" [results]="multi3"
                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis" [yAxis]="showYAxis"
                        [legend]="false" [xAxisTickFormatting]="xAxisTickFormattingFn" (window:resize)="resizeChart(lincorContainer.offsetWidth, lincorContainer.offsetHeight, 'lincor')">
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsGetwellFaultType">{{translate?.dashboard.national.fields.getwellFaultTypes.singular}}</span>
    </div>
    <div class="custella-content-row" #getWellContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi4.length == 0" class="mb-3 text-4 ms-18px" id="label_GetwellNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multi4.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar4"
                        [scheme]="colorSchemeGetwell"
                        [results]="multi4"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        (window:resize)="resizeChart(getWellContainer.offsetWidth, getWellContainer.offsetHeight, 'getwell')"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title rounded-top flex-between">
        <span class="text-14 font-medium" id="title_HillsResolTypeSyst" >{{translate?.dashboard.national.sections.sec1.singular}}</span>
        <button type="button" class="btn-view-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_HillsResolutionSystemRefresh" (click)="$event.stopPropagation();resolutionClick();" >
            <i class="fa-regular fa-arrows-rotate"></i>
        </button>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsHtrResol">{{translate?.dashboard.national.fields.htrResolution.singular}}</span>
    </div>
    <div class="custella-content-row" #htrResoContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi5.length == 0" class="mb-3 text-4 ms-18px" id="label_HtrResolNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multi5.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar5"
                        [scheme]="colorSchemeHTR"
                        [results]="multi5"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                        (window:resize)="resizeChart(htrResoContainer.offsetWidth, htrResoContainer.offsetHeight, 'htr')"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsHostelResol">{{translate?.dashboard.national.fields.hostelResolution.singular}}</span>
    </div>
    <div class="custella-content-row" #hostelResoContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi6.length == 0" class="mb-3 text-4 ms-18px" id="label_HostelResolNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multi6.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar6"
                        [scheme]="colorSchemeHostel"
                        [results]="multi6"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                        (window:resize)="resizeChart(hostelResoContainer.offsetWidth, hostelResoContainer.offsetHeight, 'hostel')"

                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsLincorResol">{{translate?.dashboard.national.fields.lincorResolution.singular}}</span>
    </div>
    <div class="custella-content-row" #lincorResoContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi7.length == 0" class="mb-3 text-4 ms-18px" id="label_LincorResolNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multi7.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar7"
                        [scheme]="colorSchemeLincor"
                        [results]="multi7"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                        (window:resize)="resizeChart(lincorResoContainer.offsetWidth, lincorResoContainer.offsetHeight, 'lincor')"

                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsGetwellResol">{{translate?.dashboard.national.fields.getwellResolution.singular}}</span>
    </div>
    <div class="custella-content-row" #getWellResoContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi8.length == 0" class="mb-3 text-4 ms-18px" id="label_GetwellResolNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multi8.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar8"
                        [scheme]="colorSchemeGetwell"
                        [results]="multi8"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                        (window:resize)="resizeChart(getWellResoContainer.offsetWidth, getWellResoContainer.offsetHeight, 'getwell')"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_HillsProduct" >
            {{ translate?.product.label.singular }}
        </span>
        <button type="button" class="btn-view-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_HillsProductRefresh" (click)="$event.stopPropagation();productClick();" >
            <i class="fa-regular fa-arrows-rotate"></i>
        </button>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsProdUsage">{{translate?.dashboard.national.fields.productUsage.singular}}</span>
    </div>
    <div class="custella-content-row" #productContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multiProduct.length == 0" class="mb-3 text-4 ms-18px" id="label_ProdUsageNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multiProduct.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBarProduct"
                        [scheme]="colorSchemeProduct"
                        [results]="multiProduct"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        (window:resize)="resizeChart(productContainer.offsetWidth, productContainer.offsetHeight, 'product')"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title rounded-top flex-between">
        <span class="text-14 font-medium" id="title_HillsTechJobs" >{{translate?.dashboard.national.sections.sec2.singular}}</span>
        <button type="button" class="btn-view-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_HillsTechJobsRefresh" (click)="$event.stopPropagation();technicianClick();" >
            <i class="fa-regular fa-arrows-rotate"></i>
        </button>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsTechJobsByTech">{{translate?.dashboard.national.fields.jobsByTechnician.singular}}</span>
    </div>
    <div class="custella-content-row" #joTechContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multiTechnician.length == 0" class="mb-3 text-4 ms-18px" id="label_TechJobsNoRecordsFound">{{translate?.general.messages.noRecordsFound}}</p>
                <div *ngIf="multiTechnician.length != 0" class="d-flex align-items-center mb-2 chartScroll ps-35px">
                    <ngx-charts-bar-horizontal [view]="viewHorizontalBarTechnician" [scheme]="colorSchemeProduct"
                        [results]="multiTechnician" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis"
                        [yAxis]="showYAxis" [legend]="false" (window:resize)="resizeChart(joTechContainer.offsetWidth, joTechContainer.offsetHeight, 'jotech')">
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>
</div>
