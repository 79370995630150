<div id="cancel_contract_container">
    <form (ngSubmit)="onSubmit()">
        <div class="custella-modal d-flex flex-column">
            <div class="custella-modal-header">
                <div class="text-14 font-medium" id="title_CancelContra"> {{ translate?.contracts.sections.sec10.singular }} &dash; <span class="text-12 fst-italic">{{ translate?.contracts.messages.removeItems }}</span></div>

                <i class="pi pi-times w-24px dialog-close-icon" id="btn_CancelContraClose"  [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="modalRef.hide()"></i>
            </div>

            <div class="custella-modal-body p-0">
                <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 p-0 bg-none box-none">
                    <div class="custella-form-container-content m-0 p-0">
                        <p-tabView>
                            <p-tabPanel [header]="translate?.asset.label.plural">
                                <div class="d-block">
                                    <div class="headerTable">
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetName">
                                            {{translate?.contracts.fields.assetName.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetStatus">
                                            {{translate?.contracts.fields.status.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetBin">
                                            {{translate?.contracts.fields.bin.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetQty">
                                            {{translate?.contracts.fields.qty.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetReturnQty">
                                            {{translate?.contracts.fields.returnQty.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetUpdInv">
                                            {{translate?.contracts.fields.updateInventory.singular}}
                                        </span>
                                    </div>
                                    <div class="contentTable" *ngFor="let row of contractItemsAssets; index as i">
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" id="input_CancAssetNameField" type="text" disabled readonly value="{{ row?.assets?.name }}" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <p-dropdown [options]="allStatus" optionValue="value" id="picklist_CancAssetStatusField" optionLabel="label" [placeholder]="translate?.general.messages.none" [(ngModel)]="row.status" [ngModelOptions]="{ standalone: true }">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="" *ngIf="row.status" id="value_CancAssetStatus">
                                                        {{ returnStatus(row.status)  }}
                                                    </div>
                                                </ng-template>

                                                <ng-template let-status pTemplate="item" id="item_CancAssetStatus">
                                                    {{ status.label  }}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>

                                        <div class="d-block col border px-2 py-3">
                                            <p-dropdown
                                                [options]="bins"
                                                id="picklist_CancAssetBinField"
                                                optionValue="id"
                                                optionLabel="name"
                                                [placeholder]="translate?.general.messages.none"
                                                [ngModelOptions]="{ standalone: true }"
                                                (onChange)="selectBin(i)"
                                                [(ngModel)]="row.bin.id"
                                            ></p-dropdown>
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" id="input_CancAssetQtyField" type="text" disabled readonly [(ngModel)]="row.qty" [ngModelOptions]="{ standalone: true }" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" type="number" id="input_CancAssetReturnQtyField" min="0" onkeypress="return event.charCode >= 48" [(ngModel)]="row.returnQty" [ngModelOptions]="{ standalone: true }" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <p-checkbox class="my-2" [binary]="true" [ngModelOptions]="{ standalone: true }" id="check_CancAssetUpdInv" [(ngModel)]="row.updateInventory"></p-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>

                            <p-tabPanel [header]="translate?.accessory.label.plural">
                                <div class="d-block">
                                    <div class="headerTable">
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesName">
                                            {{translate?.contracts.fields.accessoryName.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesStatus">
                                            {{translate?.contracts.fields.status.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesBin">
                                            {{translate?.contracts.fields.bin.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesQty">
                                            {{translate?.contracts.fields.qty.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesReturnQty">
                                            {{translate?.contracts.fields.returnQty.singular}}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesUpdInv">
                                            {{translate?.contracts.fields.updateInventory.singular}}
                                        </span>
                                    </div>
                                    <div class="contentTable" *ngFor="let row of contractItemsAccessories; index as i">
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" type="text" id="input_CancAccesNameField" disabled readonly value="{{ row?.accessory?.name }}" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <p-dropdown [options]="allStatus" optionValue="value" id="picklist_CancAccesStatusField" optionLabel="label" [placeholder]="translate?.general.messages.none" [(ngModel)]="row.status" [ngModelOptions]="{ standalone: true }">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="" *ngIf="row.status" id="value_CancAccesStatus">
                                                        {{ returnStatus(row.status)  }}
                                                    </div>
                                                </ng-template>

                                                <ng-template let-status pTemplate="item" id="item_CancAccesStatus">
                                                    {{ status.label  }}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>

                                        <div class="d-block col border px-2 py-3">
                                            <p-dropdown
                                                [options]="bins"
                                                optionValue="id"
                                                id="picklist_CancAccesBinField"
                                                optionLabel="name"
                                                [placeholder]="translate?.general.messages.none"
                                                [ngModelOptions]="{ standalone: true }"
                                                (onChange)="selectBin1(i)"
                                                [(ngModel)]="row.bin.id"
                                            ></p-dropdown>
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" id="input_CancAccesQtyField" type="text" disabled readonly [(ngModel)]="row.qty" [ngModelOptions]="{ standalone: true }" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" id="input_CancAccesReturnQtyField" type="number" min="0" onkeypress="return event.charCode >= 48" [(ngModel)]="row.returnQty" [ngModelOptions]="{ standalone: true }" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <p-checkbox class="my-2" [binary]="true" id="check_CancAccesUpdInv" [ngModelOptions]="{ standalone: true }" [(ngModel)]="row.updateInventory"></p-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </div>
            </div>

            <div class="custella-modal-footer d-flex align-items-center justify-content-end">
                <p-button type="submit" styleClass="h-32" [label]="translate?.general.buttons.save.singular" id="btn_CancelContraSubmit"></p-button>
            </div>
        </div>
    </form>
</div>
