<div [hidden]="!noCustomers" class="table-space ag-grid-table-full h-100">
    <ag-grid-angular #agGrid class="ag-theme-balham h-100 w-100" [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
         [defaultColDef]="defaultColDef" 
        [maxConcurrentDatasourceRequests]="-1"
        [suppressDragLeaveHidesColumns]="true" 
        [allowContextMenuWithControlKey]="true"
        [getContextMenuItems]="getContextMenuItems"
         [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)" 
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (cellClicked)="viewAsset($event)" 
        [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
    <div class="col-12" *ngIf="noCustomers">
        <p class="ag-record-row-summary-panel padds text-end" id="label_MachinesTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p>
    </div>
</div>
<div *ngIf="noCustomersIcon" class="mt-5 py-4 ag-grid-table-full">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
        <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
         <p class="text-6 my-1 font-weight-bold">{{translate?.general.messages.noRecordsFound}}</p>
    </div>
</div>