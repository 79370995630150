import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PermissionService } from "src/app/auth/permission.service";
import { ScheduledMaintenanceTableComponent } from "./scheduled-maintenance-table/scheduled-maintenance-table.component";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
})
export class MaintenanceComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  public nosMain = false;
  public search = "";
  public filterActive = false;
  create: any;

  @ViewChild(ScheduledMaintenanceTableComponent, { static: false })
  maintenanceTableComp!: ScheduledMaintenanceTableComponent;
  searchValue: any;
  searchValueData: boolean= false;
  isFilterSectionClosed! : boolean;
  translate: any;

  constructor(private router: Router, private perm: PermissionService,
    private languageTranslateService: LanguageTranslateService
  ) {
    super()
   
   }

  ngOnInit(): void {
    this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    this.init();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.create = this.perm.capable("scheduled_maintenance", "create");
  }

  /******************************************** refresh comp ******************************************/
  public refresh() {
    this.searchValue = "";
    this.maintenanceTableComp.refresh();
  }

  /******************************************** toggle filter ******************************************/
  public toggleFilter($event: any) {
    this.maintenanceTableComp.filterToggle();
    if(this.isFilterSectionClosed)
    {
      this.filterActive = !this.filterActive
    }
  }

  public new() {
    this.router.navigate(['dashboard/preventative-maintenance/create']);
  }

  /******************************************** on no data ******************************************/
  public onNoData($event: any) {
    this.nosMain = $event;
  }

  public onSearch() {
    if (this.search.length > 0) {
      this.searchValueData = true
      this.searchValue = this.search;
      console.log("search fire parent", this.searchValue)

    }
  }
  public onSearchCheck()
  {
    if(this.search.length == 0)
    {
      this.searchValueData = false
      this.searchValue = ''
      this.search = ''
      this.refresh()
    }
  }

  public onClear(){
    this.searchValueData = false
    this.searchValue = null
    this.search = null
    //this.refresh()
    console.log("clear fire parent", this.searchValue)
    this.maintenanceTableComp.refresh();
  }

  isFilterClosed(e: any)
  {
    this.isFilterSectionClosed = e;
    if(this.isFilterSectionClosed == false)
    {
        this.filterActive = false;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
