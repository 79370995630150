<!-- TabMenu -->
<div class="position-relative fw-normal col-12 d-flex table h-100-78px">
    <div class="table-space ag-grid-table-full h-100" [ngClass]="{ ' ag-grid-table': isFilterToggleOn }">
        <!-- <ag-grid-angular
            #agGrid
            class="ag-theme-balham"
            [suppressDragLeaveHidesColumns]="true"
            [allowContextMenuWithControlKey]="false"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [maxConcurrentDatasourceRequests]="-1"
            [getContextMenuItems]="getContextMenuItems"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="viewData($event)"
            (gridReady)="onGridReady($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            (cellValueChanged)="onCellValueChanged($event)"

        >
        </ag-grid-angular>
        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end">
                {{ totalRecord }}&nbsp;{{'general.messages.records'}}
            </p>
        </div> -->
    </div>

    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': isFilterToggleOn }">
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="title_CheckFilterBy">{{ "general.messages.filterBy"  }}</span>
            <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="'general.buttons.closeFilter.singular'" tooltipPosition="left" id="btn_CheckFilterBack" (click)="filterToggle()"></i>
        </div>
        <div class="custella-content">
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_CheckFilDateRange">{{ "checklist.fields.dateRange.singular"  }}</span>
                <span class="p-input-icon-right w-100">
                    <i class="pi pi-calendar z-1"></i>
                    <p-calendar class="custella-calendar" placeholder="dd/mm/yyyy - dd/mm/yyyy" id="picklist_CheckFilDateRangeField"appendTo="body"></p-calendar>
                </span>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_CheckFilChecklist">{{ "checklist.label.singular"  }}</span>
                <p-dropdown name="status"  id="picklist_CheckFilChecklistField" [placeholder]="'general.messages.none'"optionLabel="fieldLabel" optionValue="name" >
                </p-dropdown>
            </div>
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_CheckFilAssetType">{{ "checklist.fields.assetType.singular"  }}</span>
                <p-dropdown name="status"  id="picklist_CheckFilAssetTypeField" [placeholder]="'general.messages.none'" optionLabel="fieldLabel" optionValue="name" >
                </p-dropdown>
            </div>
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_CheckFilStatus">{{ "checklist.fields.status.singular"  }}</span>
                <p-dropdown name="status"  id="picklist_CheckFilStatusField" [placeholder]="'general.messages.none'" optionLabel="fieldLabel" optionValue="name" >
                </p-dropdown>
            </div>


            <div>
                <span href="#"  class="clear" id="subtitle_CheckFilClear">{{'general.buttons.clearAll.singular'  }}</span>
            </div>
        </div>
    </div>
</div>

<!-- FOR CENTER IN PAGE -->
<!-- <footer *ngIf="showingTable.customer" class="custella-form-container-footer">
    <p-dialog [header]="'customer.sections.sec6.singular'" [(visible)]="showingTable.customer" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
        <app-custella-customers (selectCustomerEvent)="onCustomerSelect($event)" (closeCustomerEvent)="closeTable('customer')"></app-custella-customers>
    </p-dialog>
</footer> -->
