<div>
    <div class="flex-between">
      <i class="fa-regular fa-xmark w-24px dialog-close-icon" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" id="btn_BinClose" (click)="closeBin()"></i>
    </div>
    <div class="position-relative text-start">
        <span class="mb-0 mt-2 text-14 font-light color-var-500" id="label_BinSelectList">{{translate?.general.commonComponent.messages.selectA}}&nbsp;{{transferType == 'LOCATION_BIN' ? 'Location Bin':'User'}}&nbsp;{{translate?.general.commonComponent.messages.fromTheList}}</span>
        <div class="d-flex align-items-center mt-2">
            <span class="p-input-icon-left w-100 py-2">
                <i class="pi pi-search"></i>
                <input type="text" pInputText autocomplete="off" id="input_BinSelectListField" [placeholder]="translate?.general.messages.none" (keyup)="quickSearch()" [(ngModel)]="searchValue" />
            </span>
        </div>
        <div class="table-space h-42vh">
            <ag-grid-angular
                #agGrid
                class="ag-theme-balham h-100 w-100"
                [columnDefs]="columnDefs"
                [gridOptions]="gridOptions"
                [defaultColDef]="defaultColDef"
                [maxConcurrentDatasourceRequests]="-1"
                [suppressDragLeaveHidesColumns]="true"
                (gridReady)="onGridReady($event)"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                (cellClicked)="selectLocationBinData($event)"
            ></ag-grid-angular>
            <div class="col-12"><p class="ag-record-row-summary-panel padds text-end">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p></div>
        </div>
    </div>
  </div>
  
