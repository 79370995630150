<div>
  <div class="flex-between"> 
   <i class="fa-regular fa-xmark w-24px dialog-close-icon" (click)="closeCustomer()"[pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
  </div>
  <div class="position-relative text-start">
    <span class="mb-0 mt-2 text-14 font-light color-var-500">{{translate?.general.commonComponent.sections.sec1.singular}}</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText autocomplete="off"  [placeholder]="translate?.general.buttons.search.singular" (keyup)="quickSearch()"  [(ngModel)]="searchValue"/>
      </span>
    </div>
    <div  class="table-space h-40vh">
      <ag-grid-angular
      #agGrid
      class="ag-theme-balham h-100"
      
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [defaultColDef]="defaultColDef"
      
      [pagination]=false
      [cacheOverflowSize]="2"
      [maxConcurrentDatasourceRequests]="-1"
      [suppressDragLeaveHidesColumns]="true"
      [frameworkComponents]="frameworkComponents"
      (cellClicked)="selectLocationBinData($event)"
      [allowContextMenuWithControlKey]="false"
      (gridReady)="onGridReady($event)"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [getContextMenuItems]="getContextMenuItems"
      ></ag-grid-angular>
        <div class="col-12"><p class="ag-record-row-summary-panel padds text-end">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p></div>

    </div>
  </div>

</div>