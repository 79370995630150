<div class="h-95 taskMap">
    <div class="custella-taskOpt-header" *ngIf="teamView">
         <img src="assets\images\chubb\ic_arrow_forward.png" id="btn_TaskMapExit" alt="Exit Map" class="w-19px cursor-pointer" (click)="goBack()">
         <p class="text-14 font-white font-semiBold ms-3" id="label_TaskMapExit"  (click)="goBack()">{{translate?.task.sections.sec21.singular}}</p>
    </div>
  
    <!-- Map --> 
    <google-map #plannedMap height="100%"
    width="100%"
    [center]="optionsMap.center"
    [zoom]="zoom"
    [options]="mapOptions">
    <map-polyline *ngFor="let line of markerWaypoints" [path]="line?.polyLine" [options]="line?.newPolyOption"></map-polyline>
    
      <map-marker #trackingUserData="mapMarker" *ngFor="let trackingUser of userMarkers;let i = index" (mapClick)="openInfoWindow(trackingUserData, trackingUser)" [position]="trackingUser" [options]="trackingUser" zIndex="20"  [icon]="{url: trackingUser.markerUrl,labelOrigin:pointer, scaledSize: scaledSize}" [label]="{color:'white',fontWeight: 'normal', fontSize: '14px',text:((trackingUser?.label)?(trackingUser?.label):' ')}"> </map-marker>
       
      <map-marker #markerD="mapMarker" *ngFor="let marker of markers; let i = index" [position]="marker" [options]="marker" zIndex="20" [icon]="{url: marker.icon,labelOrigin:pointer, scaledSize: scaledSize}" [label]="{color:'white',fontWeight: 'normal', fontSize: '14px',text:((marker?.label)?(marker?.label):' ')}" (mapClick)="openInfoWindow2(markerD, marker)"> </map-marker>
         <map-info-window >
              <div [hidden]="!displayActual" class="custella-form-container-marker m-0 h-100 rounded-2">
                  <div class="custella-form-container-marker-title px-p75rem">
                      <div class="flex-between a">
                      <span class="text-12 font-semiBold me-2" id="label_TaskMapFullName">{{selectedUserdata?.firstName}} {{selectedUserdata?.lastName}}</span>
                      </div>
                  </div>
                  <!-- Name and Email -->
                  <div class="custella-form-container-marker-content font-black px-2 m-0 pb-2">
                          <div class="custella-content-row">
                              <div class="custella-content-row-input col-12">
                              <div class="d-flex align-items-center">
                                  <img src="assets/svg/table/map-marker-black.svg" id="img_TaskMapSelUserAddr" alt="Location" class="w-24px">
                                  <span class="text-12 font-medium ms-2 text-wrap" id="value_TaskMapSelUserAddr">{{selectedUserAddress}}</span>
                              </div>
                              </div>
                          </div>
                  </div>
              </div>
  
              <div [hidden]="displayActual" class="custella-form-container-marker m-0 h-100 rounded-2">
                  <div class="custella-form-container-marker-title px-p75rem">
                      <div class="flex-between">
                        <span class="text-12 font-semiBold me-2" id="value_TaskMapSelUserTaskName">{{selectedUserdata?.taskName}}</span>
                       </div>
                  </div>
                  <!-- Name and Email -->
                  <div class="custella-form-container-marker-content font-black px-2 m-0 pb-2">
                  <div class="custella-content-row">
                              <div class="custella-content-row-input">
                                <div class="d-flex align-items-center">
                                    <img src="assets/svg/table/clock.svg" alt="Order Time" class="w-24px">
                                    <span class="text-12 font-medium ms-2" id="value_TaskMapSelUserDateTime">{{selectedUserdata?.startTime | momentDate :'hh:mm a' : timezone}} - {{selectedUserdata?.endTime | momentDate :'hh:mm a' : timezone}}</span>
                                </div>
                              </div>
                          </div>
      
                          <div class="custella-content-row">
                              <div class="custella-content-row-input col-12">
                                <div class="d-flex">
                                    <img src="assets/svg/table/map-marker-black.svg" id="img_TaskMapSelUserLoc" alt="Location" class="w-24px">
                                    <span class="text-12 font-medium ms-2 text-wrap" id="value_TaskMapSelUserLoc">{{selectedUserdata?.location}}</span>
                                </div>
                              </div>
                          </div>
                  </div>
              </div>
        </map-info-window>
     </google-map>
  
    <!-- Footer -->
    <div class="custella-taskOpt-footer mx-3 w-100-223px" *ngIf="teamView == 'true'">
         <div class="custella-taskOpt-footer-string text-center offset-5 w-15-prcnt">
             <div class="pt-2" (click)="pullUp()" id="btn_TaskPullUpDown">
                 <i *ngIf="empClass == 'pullUp'" id="img_TaskPullUp" class="pi pi-chevron-circle-up font-white text-20"></i>
                 <i *ngIf="empClass == 'pullDown'" id="img_TaskPullDown" class="pi pi-chevron-circle-down font-white text-20"></i>
             </div>
         </div>
         <div class="custella-taskOpt-footer-body rounded-top" >
             <div class="custella-taskOpt-footer-body header rounded-top">
                 <span class="ms-2 text-14 font-semiBold" id="title_TaskSelUser">{{translate?.task.sections.sec23.singular}}</span>
                 <div class="flex-between">
                  <p-dropdown class="mx-1" (onChange)="filterUsers()" id="picklist_TaskSelUserField" [(ngModel)]="filter.onlineUsers" name="status"  [options]="onlineUsersList" optionLabel="label"
                  optionValue="value" [placeholder]="translate?.general.messages.none"></p-dropdown>               
                    
                  <p-dropdown class="me-1" [(ngModel)]="filter.typeOfUsers" id="picklist_TaskFilterUserField" (onChange)="filterUsers()" name="status" [options]="typeOfUsersList" optionLabel="label"
                   optionValue="value" [placeholder]="translate?.general.messages.none"></p-dropdown>
                     <span class="p-input-icon-left">
                         <i class="pi pi-search me-3"></i>
                         <input type="text" pInputText autocomplete="off"[(ngModel)]="searchEmployee" id="input_TaskSelStatusField" (ngModelChange)="searchUsers(searchEmployee)" [placeholder]="translate?.general.buttons.search.singular"/>
                     </span>
                 </div>
             </div>
             <div class="custella-taskOpt-footer-body body rounded-bottom">
                 <div class="text-center pt-5" *ngIf="users?.length == 0">
                     <span class="text-14 font-semiBold font-white" id="title_TaskNoUser" >{{translate?.task.sections.sec22.singular}}</span>
                 </div>
                 <div class="row-grid col m-0 p-4 justify-content-between">
                     <div class="custella-taskOpt-footer-body body-card py-3 w-100" *ngFor="let user of users;" id="btn_TaskLoadMoreUser" (click)="loadMoreUserTasks(user)">
                         <div class="col ps-1 p-0">
                             <div class="d-flex px-0 align-items-center">
                                
                                 <div class="h-32 flex-align-center py-2px">
                                  <div  *ngIf="!user.imageUrl || user.imageUrl == ''" [ngClass]="{userOnline : user.online, userOffline : !user.online}" class="mx-2 p-avatar p-component p-avatar-image p-avatar-circle" >
                                      <img src="assets/svg/Avatar.svg" id="img_TaskSelUserAvatar">
                                  </div>
  
                                  <div  *ngIf="user.imageUrl" [ngClass]="{userOnline : user.online, userOffline : !user.online}" class="mx-2 p-avatar p-component p-avatar-image p-avatar-circle" >
                                    <img [src]="user.imageUrl" id="img_TaskSelUserAvatar">
                                  </div>
                                </div>
                                  <div class="pe-3 pt-1">
                                     <div class="col pe-0 text-left text-14" id="value_TaskSelUserFullName">{{ user.firstName }} {{ user.lastName }}</div>
                                     <div class="col pe-0 text-left text-12" id="value_TaskSelUserEmail">{{ user.email }}</div>
                                 </div>
                               </div>
                         </div>
                     </div>   
                 </div>
                          
             </div>
         </div>
    </div>
  </div>