
import {of as observableOf,  Observable, throwError, Subject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';

@Injectable()
export class WorkflowService {
 
  refreshWorkflowList$: EventEmitter<{}>;
  refreshWorkFlowNotificationList$: EventEmitter<{}>;
  public refreshAfterCount$: EventEmitter<any>;

  private ENDPOINT_LIST_WORKFLOWS="/workflows";
  private ENDPOINT_BY_ID_WORKFLOWS="/workflows/";
  private ENDPOINT_LIST_WORKFLOWS_NOTIFICATION="/workflow-alerts";
  
  private soItemsSource = new Subject<any>();
  private checkValidationItem = new Subject<any>();
  soitemsHandler = this.soItemsSource.asObservable();
  checkValidation = this.checkValidationItem.asObservable();

  constructor(private http: HttpClient) {
    this.refreshWorkflowList$ = new EventEmitter();
    this.refreshWorkFlowNotificationList$ = new EventEmitter();
    this.refreshAfterCount$ = new EventEmitter();
   }
   setItems(items: any) {
    this.soItemsSource.next(items);
  }
  setCheckItems(items: any) {
    this.checkValidationItem.next(items);
  }
/************************************* Get the list of workflow  *****************************/
  public getListOfWorkflows(){
    return this.http.get( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS, { observe: "response"}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
 /************************************* Get workflow By Id  *********************************/
  public getWorkflowById(id : any){
   
    return this.http.get( environment.base_url+this.ENDPOINT_BY_ID_WORKFLOWS+id, {observe: "response"}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
  /************************************* Get workflow By Id  *********************************/
  public getWorkflowModules(){
   
    return this.http.get( environment.base_url+'/workflows/modules', {observe: "response"}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
/************************************* Create workflow By Id  *********************************/
public createWorkflow(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/***************************************** Update workflow By Id  *****************************/
public updateWorkflow(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}
/************************************* Delete workflow By Id  *****************************/
public deleteWorkflow(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_BY_ID_WORKFLOWS+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshWorkflowList$.emit('Deleted Workflow');
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}
/************************************* Get workflow By Id  *********************************/
public getEntityFieldsByModule(module : any){
  let params = new HttpParams();
  params = params.set("entity", module);
  
  return this.http.get( environment.base_url+'/workflows/entity-fields',{ observe: 'response', params: params }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/***************************************** Update workflow By Id  *****************************/
public updateWorkflowCondition(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS+"/criteria", body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}

/************************************* Get workflow Notification  *********************************/
public getWorkFlowNotification(id: any){
   
  return this.http.get( environment.base_url+'/workflow-alerts/'+id, {observe: "response"}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/************************************* Create WorkFlowNotification By Id  *********************************/
public createWorkFlowNotification(body:any){
  return this.http.post( environment.base_url+'/workflow-alerts', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshWorkFlowNotificationList$.emit({ data: body });
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/***************************************** Update WorkFlowNotification By Id  *****************************/
public updateWorkFlowNotification(body:any){
  return this.http.put( environment.base_url+'/workflow-alerts', body, { observe: 'response' }).pipe(map( (resp:any) => {
    
    this.refreshWorkFlowNotificationList$.emit(body);
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}
/************************************* Delete WorkFlowNotification By Id  *****************************/
public deleteWorkFlowNotification(id: any){
  return this.http.delete( environment.base_url+'/workflow-alerts/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshWorkFlowNotificationList$.emit('Deleted WorkFlow Notification');
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}
/************************************* Get workflow Notification  *********************************/
public getWorkflowTemplates(moduleName: any){
  let params = new HttpParams();
  
    params = params.set('moduleName', moduleName);
   
  return this.http.get( environment.base_url+'/workflow-email-templates/picklist', {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/************************************* Get workflow Notification  *********************************/
public getUsersInFieldValues(moduleName: any){
  let params = new HttpParams();
  
    params = params.set('entity', moduleName);
   
  return this.http.get( environment.base_url+'/workflow-alerts/users-in-field-values', {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

/************************************* Get the list of workflow  *****************************/
public getListOfWorkflowsNotification(moduleName: any,notificationType: any){
  let params = new HttpParams();
  
 if(moduleName){ params = params.set('moduleName', moduleName);}
  if(notificationType){ params = params.set('notificationType', notificationType);}

  return this.http.get( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS_NOTIFICATION, { observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/************************************* Get workflow External Email  *********************************/
public getWorkflowExternalEmails(moduleName: any){
  let params = new HttpParams();
  
    params = params.set('entity', moduleName);
   
  return this.http.get( environment.base_url+'/workflow-alerts/external-emails', {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/************************************* Get workflow By Id  *********************************/
public getWorkflowNotificationById(id : any){
 
  return this.http.get( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS_NOTIFICATION+'/'+id, {observe: "response"}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

/************************************* Create workflow By Id  *********************************/
public createWorkflowNotification(body:any){
return this.http.post( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS_NOTIFICATION, body, { observe: 'response' }).pipe(map( (resp:any) => {
  return resp;
}
),catchError( error => {
    return throwError( error );
}),);
}
/***************************************** Update workflow By Id  *****************************/
public updateWorkflowNotification(body:any){
return this.http.put( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS_NOTIFICATION, body, { observe: 'response' }).pipe(map( (resp:any) => {
  return resp;
}
),catchError( error => {

    return throwError( error );
}),);
}
/************************************* Delete workflow By Id  *****************************/
public deleteWorkflowNotification(id: any){
return this.http.delete( environment.base_url+this.ENDPOINT_LIST_WORKFLOWS_NOTIFICATION+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
  this.refreshWorkflowList$.emit('Deleted Workflow Notification');
  return resp;
}
),catchError( error => {

    return throwError( error );
}),);
}
/************************************* Get Message Field *********************************/
public getWorkFlowNotificationFields(moduleName: any){
  let params = new HttpParams();
  
    params = params.set('entity', moduleName);
   
  return this.http.get( environment.base_url+'/workflows/entity-fields/notification', {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/***************************************** Add/update Notification in WorkflowBy Id  *****************************/
public updateNotificationIdWorkFlow(body:any,workFlowId: any){
  return this.http.put( environment.base_url+'/workflows/'+workFlowId+'/add/alert', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshWorkflowList$.emit(resp);
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}
/***************************************** Remove Notification in WorkflowBy Id  *****************************/
public deleteNotificationIdWorkFlow(workFlowId: any, notificationId: any){
  return this.http.put( environment.base_url+'/workflows/'+workFlowId+'/remove/alert/'+notificationId, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refreshWorkflowList$.emit("Workflow Action");
    return resp;
  }
),catchError( error => {
  
      return throwError( error );
  }),);
}

/************************************* Get Message Field *********************************/
public getLookupData(url: any,search: any){
  let params = new HttpParams();
  
    params = params.set('search', search);
   
  return this.http.get( environment.base_url+url, {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/************************************* Get workflow By Id  *********************************/
public getDatByAPI(url : any){
  let params = new HttpParams();
  //params = params.set("entity", module);
  
  return this.http.get( environment.base_url+url,{  params: params }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
public getTotalCount(data: any){
  this.refreshAfterCount$.emit(data);
}
}
