<div class="helpdesk-table-container overflow-scroll"[ngStyle]="helpdeskTableStyle">
    <!-- outer of showing no result if search result of all table contains no data -->
    <div [hidden]="currentTableState.general.zeroResultsFound">
        <!-- Ticket Table -->
        <div class="table-wrapper" [hidden]="currentTableState.ticketsTable.isVisible === false">
            <div class="table-header d-flex align-items-center" *ngIf="currentTableState.ticketsTable.isActive">
                <span class="table-text m-2 pl-2 text" id="label_HelpTixRow">{{ translate?.ticket.label.plural }} <span id="value_HelpTixRow">({{
                        currentTableState.ticketsTable.totalRowData }})</span></span>

                <div class="ms-auto">
                    <button type="button" class="btn-list-icon ms-2"  [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" *ngIf="currentTableState.ticketsTable.isExpand" (click)="onToggleFilter('tickets')" id="btn_HelpTixFilter">
                        <i class="fa-regular fa-filter-list"></i>
                    </button>

                    <button pButton class="ms-auto h-25 " *ngIf="
            currentTableState.ticketsTable.showAllBtn &&
            currentTableState.ticketsTable.isExpand === false
          " (click)="showAll('tickets')" id="btn_HelpTixExpandAll">
                        {{ translate?.general.buttons.seeAll.singular }}
                    </button>
                    <button pButton class="ms-auto h-24px" id="btn_HelpTixClearAll"
                        *ngIf="currentTableState.ticketsTable.isExpand" (click)="showAll('tickets')"
                        icon="pi pi-times"></button>
                </div>
            </div>

            <div class="table-header d-flex align-items-center" *ngIf="!currentTableState.ticketsTable.isActive">
                <div class="ms-auto">
                    <button type="button" class="btn-list-icon ms-2"  [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" id="btn_HelpTixInactiveFilter" (click)="onToggleFilter('tickets')">
                        <i class="fa-regular fa-filter-list"></i>
                    </button>
                </div>
            </div>

            <div class="" [ngClass]="currentTableState.ticketsTable.filterOn ? 'd-flex position-relative' : ''">
                <div class="single-table table-space ag-grid-table-full"
                    [ngStyle]="currentTableState.ticketsTable.tableStyle"
                    [ngClass]="{ 'ag-grid-table': currentTableState.ticketsTable.filterOn }"
                    [style.width]="currentTableState.ticketsTable.filterOn ? 'calc(100% - 265px)' : 'unset'"
                    #ticketTable>
                    <ag-grid-angular #ticketsGrid class="ag-theme-balham h-100"
                        [suppressDragLeaveHidesColumns]="true" [allowContextMenuWithControlKey]="true"
                        [gridOptions]="currentTableState.ticketsTable?.gridOptions" [defaultColDef]="defaultColDef"
                        [maxConcurrentDatasourceRequests]="-1" [cacheOverflowSize]="2"
                        [sideBar]="currentTableState.ticketsTable.sideBar" [frameworkComponents]="frameworkComponents"
                        (cellClicked)="onClickCell($event)" (gridReady)="onGridReady($event, 'tickets')"
                        (gridSizeChanged)="onGridSizeChange($event, 'tickets')"
                        [getContextMenuItems]="getContextMenuItems" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
                    </ag-grid-angular>
                </div>

                <div class="custella-form-container-content-pane layout-filter-static"
                    [ngClass]="{ 'layout-filter-active': currentTableState.ticketsTable.filterOn }"
                    [ngStyle]="{height: ticketTable.style.height, position: 'absolute', right: 0}">
                    <div class="custella-form-container-content-pane-title">
                        <span class="text-14 font-medium" id="label_HelpTixFilterBy">{{translate?.general.messages.filterBy }}</span>
                        <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" (click)="onToggleFilter('tickets')" id="btn_HelpTixFilter"></i>
                    </div>
                    <div class="custella-content">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_HelpTixNextMainDate">{{translate?.asset.fields.nextMaintenanceDate.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <i class="pi pi-calendar z-1"></i>
                                <p-calendar type="text" class="w-100" dateFormat="dd/mm/yy" name="dueDate"
                                    [readonlyInput]="true" [placeholder]="translate?.general.messages.none"
                                    selectionMode="range" [(ngModel)]="currentTableState.ticketsTable.filters.dateRange"
                                    (ngModelChange)="onDateChange($event, 'tickets')" [yearNavigator]="true" id="picklist_HelpTixNextMainDateField"
                                    yearRange="2000:2030" class="custella-calendar" [showButtonBar]="false"appendTo="body"></p-calendar>
                            </span>
                        </div>
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_HelpTixStatus">{{translate?.asset.fields.status.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown class="pe-2" [(ngModel)]="currentTableState.ticketsTable.filters.status"
                                    name="status" [options]="allTicketStatus" optionLabel="fieldLabel" filterBy="name"
                                    optionValue="name" [filter]="true" id="picklist_HelpTixStatusField"
                                    [placeholder]="translate?.general.messages.none"
                                    (onChange)="onStatusChange($event, 'tickets')"
                                    [showClear]="currentTableState.ticketsTable.filters.status ? true : false"></p-dropdown>
                            </span>
                        </div>

                        <div>
                            <span href="#" class="clear" (click)="clearTable('tickets')" id="subtitle_HelpTixFilterClear">{{translate?.general.buttons.clearAll.singular }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end">
                    {{ currentTableState.ticketsTable.totalRowData }}&nbsp;{{'general.messages.records'}}
                </p>
            </div> -->
        </div>

        <!-- Assets Table -->
        <div class="table-wrapper" [style.marginTop]="currentTableState.assetsTable.isActive ? '1rem' : 0"
            [hidden]="!currentTableState.assetsTable.isVisible">
            <div class="table-header d-flex align-items-center" *ngIf="currentTableState.assetsTable.isActive">
                <span class="table-text m-2 pl-2 text" id="label_HelpAssetRow">{{ translate?.asset.label.plural}} <span id="value_HelpAssetRow">({{
                        currentTableState.assetsTable.totalRowData }})</span></span>

                <div class="ms-auto">
                    <button type="button" class="btn-list-icon ms-2"  [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom"  id="btn_HelpAssetExpandFilter" *ngIf="currentTableState.assetsTable.isExpand"
                        (click)="onToggleFilter('assets')">
                        <i class="fa-regular fa-filter-list"></i>
                    </button>

                    <button pButton class="h-25 " *ngIf="
            currentTableState.assetsTable.showAllBtn &&
            currentTableState.assetsTable.isExpand === false
          " (click)="showAll('assets')" id="btn_HelpAssetSeeAll">
                        {{ translate?.general.buttons.seeAll.singular }}
                    </button>
                    <button pButton class="h-25 " id="btn_HelpAssetClearAll" *ngIf="currentTableState.assetsTable.isExpand"
                        (click)="showAll('assets')" icon="pi pi-times"></button>
                </div>

            </div>

            <div class="" [ngClass]="currentTableState.assetsTable.filterOn ? 'd-flex position-relative' : ''">
                <div class="single-table table-space ag-grid-table-full"
                    [ngStyle]="currentTableState.assetsTable.tableStyle"
                    [ngClass]="{ 'ag-grid-table': currentTableState.assetsTable.filterOn }"
                    [style.width]="currentTableState.assetsTable.filterOn ? 'calc(100% - 265px)' : 'unset'" #assetTable>
                    <ag-grid-angular #assetssGrid class="ag-theme-balham h-100"
                        [suppressDragLeaveHidesColumns]="true" [allowContextMenuWithControlKey]="true"
                        [columnDefs]="currentTableState.assetsTable?.tableColumns"
                        [gridOptions]="currentTableState.assetsTable?.gridOptions" [defaultColDef]="defaultColDef"
                        [maxConcurrentDatasourceRequests]="1" [sideBar]="currentTableState.assetsTable.sideBar"
                        [frameworkComponents]="frameworkComponents" (cellClicked)="onClickCell($event)"
                        (gridReady)="onGridReady($event, 'assets')"
                        (gridSizeChanged)="onGridSizeChange($event, 'assets')"
                        [getContextMenuItems]="getContextMenuItems"
                        [overlayNoRowsTemplate]="overlayNoRowsTemplate"></ag-grid-angular>
                </div>

                <div class="custella-form-container-content-pane layout-filter-static"
                    [ngClass]="{ 'layout-filter-active': currentTableState.assetsTable.filterOn }"
                    [ngStyle]="{height: assetTable.style.height, position: 'absolute', right: 0}">
                    <div class="custella-form-container-content-pane-title">
                        <span class="text-14 font-medium" id="label_HelpAssetFilterBy">{{translate?.general.messages.filterBy}}</span>
                        <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" id="btn_HelpAssetFilterBy" (click)="onToggleFilter('assets')"></i>
                    </div>
                    <div class="custella-content">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_HelpAssetCust" >{{ translate?.customer.label.singular }}</span>
                            <div class="p-inputgroup search">
                                <span class="p-input-icon-right w-100">
                                    <p-autoComplete styleClass="w-100" (onSelect)="onCustomerSelect($event, 'assets')" field="name"
                                    [suggestions]="allCustomers" (completeMethod)="getCustomer($event)"
                                    [(ngModel)]="currentTableState.assetsTable.filters.customer"
                                    [placeholder]="translate?.general.messages.none" id="picklist_HelpAssetCustField">
                                    </p-autoComplete>
                                    <i *ngIf="currentTableState.assetsTable.filters.customer && currentTableState.assetsTable.filters.customer?.id"
                                        (click)="clearValue('customer', 'assets')" id="btn_HelpAssetCustClear"
                                        class="p-autocomplete-clear-icon pi pi-times"></i>
                                </span>
                                <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.customer.tooltips.customerLookup.singular" tooltipPosition="left" (click)="showTable('customer', 'assets')" id="btn_HelpAssetCustSearch"></button>
                            </div>
                        </div>

                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_HelpAssetType">{{translate?.customer.fields.type.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown class="pe-2" [(ngModel)]="currentTableState.assetsTable.filters.type"
                                    name="status" [options]="allAssetType" optionLabel="name" filterBy="name"
                                    optionValue="name" [filter]="true" [placeholder]="translate?.general.messages.none"
                                    (onChange)="onTypeChange($event, 'assets')" id="picklist_HelpAssetTypeField"
                                    [showClear]="currentTableState.assetsTable.filters.type ? true : false"></p-dropdown>
                            </span>
                        </div>

                        <div>
                            <span href="#" class="clear" (click)="clearTable('assets')" id="subtitle_HelpAssetFilterClear">{{translate?.general.buttons.clearAll.singular }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end">
                    {{ currentTableState.assetsTable.totalRowData }}&nbsp;{{'general.messages.records'}}
                </p>
            </div> -->
        </div>

        <div class="table-wrapper" [style.marginTop]="currentTableState.customersTable.isActive ? '1rem' : 0"
            [hidden]="!currentTableState.customersTable.isVisible">
            <!-- Customers Table -->
            <div class="table-header d-flex align-items-center" *ngIf="currentTableState.customersTable.isActive">
                <span class="table-text m-2 pl-2 text" id="label_HelpCustRow">{{ translate?.customer.label.plural }} <span id="value_HelpCustRow">({{
                        currentTableState.customersTable.totalRowData }})</span></span>

                <div class="ms-auto">
                    <button type="button" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" *ngIf="currentTableState.customersTable.isExpand"
                        (click)="onToggleFilter('customers')" id="btn_HelpCustFilter">
                        <i class="fa-regular fa-arrows-rotate"></i>
                    </button>

                    <button pButton class="h-25" *ngIf="
            currentTableState.customersTable.showAllBtn &&
            currentTableState.customersTable.isExpand === false
          " (click)="showAll('customers')" id="btn_HelpCustSeeAll">
                        {{ translate?.general.buttons.seeAll.singular }}
                    </button>
                    <button pButton class="h-25" *ngIf="currentTableState.customersTable.isExpand"
                        (click)="showAll('customers')" icon="pi pi-times" id="btn_HelpCustClearAll"></button>
                </div>
            </div>

            <!-- <div class="single-table table-space" [ngStyle]="currentTableState.customersTable.tableStyle">
                <ag-grid-angular
                    #assetssGrid
                    class="ag-theme-balham"
                    [suppressDragLeaveHidesColumns]="true"
                    [allowContextMenuWithControlKey]="true"
                    [columnDefs]="currentTableState.customersTable?.tableColumns"
                    [gridOptions]="currentTableState.customersTable?.gridOptions"
                    [defaultColDef]="defaultColDef"
                    [maxConcurrentDatasourceRequests]="1"
                    [sideBar]="currentTableState.customersTable.sideBar"
                    [frameworkComponents]="frameworkComponents"
                    (cellClicked)="onClickCell($event)"
                    (gridReady)="onGridReady($event, 'customers')"
                    (gridSizeChanged)="onGridSizeChange($event, 'customers')"
                    [getContextMenuItems]="getContextMenuItems"
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                >
                </ag-grid-angular>
            </div> -->

            <div class="" [ngClass]="currentTableState.customersTable.filterOn ? 'd-flex position-relative' : ''">
                <div class="single-table table-space ag-grid-table-full"
                    [ngStyle]="currentTableState.customersTable.tableStyle"
                    [ngClass]="{ 'ag-grid-table': currentTableState.customersTable.filterOn }"
                    [style.width]="currentTableState.customersTable.filterOn ? 'calc(100% - 265px)' : 'unset'"
                    #customerTable>
                    <ag-grid-angular #assetssGrid class="ag-theme-balham h-100"
                        [suppressDragLeaveHidesColumns]="true" [allowContextMenuWithControlKey]="true"
                        [columnDefs]="currentTableState.customersTable?.tableColumns"
                        [gridOptions]="currentTableState.customersTable?.gridOptions" [defaultColDef]="defaultColDef"
                        [maxConcurrentDatasourceRequests]="1" [sideBar]="currentTableState.customersTable.sideBar"
                        [frameworkComponents]="frameworkComponents" (cellClicked)="onClickCell($event)"
                        (gridReady)="onGridReady($event, 'customers')"
                        (gridSizeChanged)="onGridSizeChange($event, 'customers')"
                        [getContextMenuItems]="getContextMenuItems" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
                    </ag-grid-angular>
                </div>

                <div class="custella-form-container-content-pane layout-filter-static"
                    [ngClass]="{ 'layout-filter-active': currentTableState.customersTable.filterOn }"
                    [ngStyle]="{height: customerTable.style.height, position: 'absolute', right: 0}">
                    <div class="custella-form-container-content-pane-title">
                        <span class="text-14 font-medium" id="label_HelpCustFilterBy">{{translate?.general.messages.filterBy}}</span>
                        <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" (click)="onToggleFilter('customers')" id="btn_HelpCustFilterBy"></i>
                    </div>
                    <div class="custella-content">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_HelpCustServZone">{{translate?.setup.serviceZone.label.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown class="pe-2"
                                    [(ngModel)]="currentTableState.customersTable.filters.serviceZone" id="picklist_HelpCustServZoneField"
                                    name="serviceZone" [options]="allServiceZones" optionLabel="name" filterBy="name"
                                    optionValue="name" [filter]="true"
                                    [placeholder]="translate?.general.messages.none"
                                    (onChange)="onServiceZoneChange($event, 'customers')"
                                    [showClear]="currentTableState.customersTable.filters.serviceZone ? true : false"></p-dropdown>
                            </span>
                        </div>

                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_HelpCustCatType">{{translate?.customer.fields.categoryType.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown class="pe-2"
                                    [(ngModel)]="currentTableState.customersTable.filters.categoryType"
                                    name="categoryType" [options]="allCategoryTypes" optionLabel="name" filterBy="name"
                                    optionValue="name" [filter]="true" id="picklist_HelpCustCatTypeField"
                                    [placeholder]="translate?.general.messages.none"
                                    (onChange)="onCategoryTypeChange($event, 'customers')"
                                    [showClear]="currentTableState.customersTable.filters.categoryType ? true : false"></p-dropdown>
                            </span>
                        </div>

                        <div>
                            <span href="#" class="clear" (click)="clearTable('customers')" id="subtitle_HelpCustClearAll">{{translate?.general.buttons.clearAll.singular }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end">
                    {{ currentTableState.customersTable.totalRowData }}&nbsp;{{'general.messages.records'}}
                </p>
            </div> -->
        </div>

        <div class="table-wrapper" [style.marginTop]="currentTableState.jobOrdersTable.isActive ? '1rem' : 0"
            [hidden]="!currentTableState.jobOrdersTable.isVisible">
            <!-- Job Orders Table -->
            <div class="table-header d-flex align-items-center" *ngIf="currentTableState.jobOrdersTable.isActive">
                <span class="table-text m-2 pl-2 text" id="label_HelpJobRow">{{ translate?.jobOrder.label.plural }} <span id="value_HelpJobRow">({{
                        currentTableState.jobOrdersTable.totalRowData }})</span></span>

                <div class="ms-auto">
                    <button type="button" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" *ngIf="currentTableState.jobOrdersTable.isExpand"
                        (click)="onToggleFilter('jobOrders')" id="btn_HelpJobFilter">
                        <i class="fa-regular fa-arrows-rotate"></i>
                    </button>

                    <button pButton class="h-25" *ngIf="
            currentTableState.jobOrdersTable.showAllBtn &&
            currentTableState.jobOrdersTable.isExpand === false
          " (click)="showAll('jobOrders')" id="btn_HelpJobSeeAll">
                        {{ translate?.general.buttons.seeAll.singular }}
                    </button>
                    <button pButton class="h-25" *ngIf="currentTableState.jobOrdersTable.isExpand" id="btn_HelpJobClearAll"
                        (click)="showAll('jobOrders')" icon="pi pi-times"></button>
                </div>

            </div>
            <!-- <div class="single-table table-space" [ngStyle]="currentTableState.jobOrdersTable.tableStyle">
                <ag-grid-angular
                    #assetssGrid
                    class="ag-theme-balham"
                    [suppressDragLeaveHidesColumns]="true"
                    [allowContextMenuWithControlKey]="true"
                    [columnDefs]="currentTableState.jobOrdersTable?.tableColumns"
                    [gridOptions]="currentTableState.jobOrdersTable?.gridOptions"
                    [defaultColDef]="defaultColDef"
                    [maxConcurrentDatasourceRequests]="1"
                    [sideBar]="currentTableState.jobOrdersTable.sideBar"
                    [frameworkComponents]="joFrameworkComponents"
                    (cellClicked)="onClickCell($event)"
                    (gridReady)="onGridReady($event, 'jobOrders')"
                    (gridSizeChanged)="onGridSizeChange($event, 'jobOrders')"
                    [getContextMenuItems]="getContextMenuItems"
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                >
                </ag-grid-angular>
            </div> -->

            <div class="" [ngClass]="currentTableState.jobOrdersTable.filterOn ? 'd-flex position-relative' : ''">
                <div class="single-table table-space ag-grid-table-full"
                    [ngStyle]="currentTableState.jobOrdersTable.tableStyle"
                    [ngClass]="{ 'ag-grid-table': currentTableState.jobOrdersTable.filterOn }"
                    [style.width]="currentTableState.jobOrdersTable.filterOn ? 'calc(100% - 265px)' : 'unset'"
                    #jobOrderTable>
                    <ag-grid-angular #jobOrderGrid class="ag-theme-balham h-100"
                        [suppressDragLeaveHidesColumns]="true" [allowContextMenuWithControlKey]="true"
                        [columnDefs]="currentTableState.jobOrdersTable?.tableColumns"
                        [gridOptions]="currentTableState.jobOrdersTable?.gridOptions" [defaultColDef]="defaultColDef"
                        [maxConcurrentDatasourceRequests]="1" [sideBar]="currentTableState.jobOrdersTable.sideBar"
                        [frameworkComponents]="joFrameworkComponents" (cellClicked)="onClickCell($event)"
                        (gridReady)="onGridReady($event, 'jobOrders')"
                        (gridSizeChanged)="onGridSizeChange($event, 'jobOrders')"
                        [getContextMenuItems]="getContextMenuItems" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
                    </ag-grid-angular>
                </div>

                <div class="custella-form-container-content-pane layout-filter-static"
                    [ngClass]="{ 'layout-filter-active': currentTableState.jobOrdersTable.filterOn }"
                    [ngStyle]="{height: jobOrderTable.style.height, position: 'absolute', right: 0}">
                    <div class="custella-form-container-content-pane-title">
                        <span class="text-14 font-medium" id="label_HelpJobFilterBy">{{translate?.general.messages.filterBy}}</span>
                        <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" (click)="onToggleFilter('jobOrders')" id="btn_HelpJobFilterBy"></i>
                    </div>
                    <div class="custella-content">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_HelpJobCust" >{{ "customer.label.singular" }}</span>
                            <div class="p-inputgroup search">
                                <span class="p-input-icon-right w-100">
                                    <p-autoComplete styleClass="w-100" (onSelect)="onCustomerSelect($event, 'jobOrders')" field="name"
                                    [suggestions]="allCustomers" (completeMethod)="getCustomer($event)"
                                    [(ngModel)]="currentTableState.jobOrdersTable.filters.customer"
                                    [placeholder]="translate?.general.messages.none" id="picklist_HelpJobCustField">
                                    </p-autoComplete>
                                    <i *ngIf="currentTableState.jobOrdersTable.filters.customer && currentTableState.jobOrdersTable.filters.customer?.id"
                                        (click)="clearValue('customer', 'jobOrders')" id="btn_HelpJobCustClear"
                                        class="p-autocomplete-clear-icon pi pi-times"></i>
                                </span>
                                <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.customer.tooltips.customerLookup.singular" tooltipPosition="left" (click)="showTable('customer', 'jobOrders')" id="btn_HelpJobCustSearch"></button>
                            </div>
                        </div>

                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_HelpJobStatus">{{translate?.jobOrder.fields.status.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown class="pe-2" [(ngModel)]="currentTableState.jobOrdersTable.filters.status"
                                    name="status" [options]="allJobOrderStatus" optionLabel="fieldLabel"
                                    filterBy="fieldLabel" optionValue="fieldLabel" [filter]="true" id="picklist_HelpJobStatusField"
                                    [placeholder]="translate?.general.messages.none"
                                    (onChange)="onStatusChange($event, 'jobOrders')"
                                    [showClear]="currentTableState.jobOrdersTable.filters.status ? true : false"></p-dropdown>
                            </span>
                        </div>

                        <div>
                            <span href="#" class="clear" (click)="clearTable('jobOrders')" id="btn_HelpJobFilterClear">{{translate?.general.buttons.clearAll.singular }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end">
                    {{ currentTableState.jobOrdersTable.totalRowData }}&nbsp;{{'general.messages.records'}}
                </p>
            </div> -->
        </div>
    </div>

    <!-- NO DATA IMAGE -->
    <ng-container *ngIf="currentTableState.general.zeroResultsFound">
        <div class="d-flex justify-content-center align-items-center flex-column mt-4">
            <img src="assets\svg\empty-state.svg" alt="No tickets available" id="img_NoRecordsFound" class="not-available-image" />
            <p class="mt-4">{{ translate?.general.messages.noRecordsFound }}</p>
        </div>
    </ng-container>

    <footer *ngIf="showingTable.customer" class="custella-form-container-footer">
        <p-dialog [header]="translate?.customer.sections.sec5.singular" [(visible)]="showingTable.customer" showEffect="fade"
            [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
            <app-custella-customers (selectCustomerEvent)="onCustomerSelect($event, currentSelectedTable)"
                (closeCustomerEvent)="closeTable('customer')"></app-custella-customers>
        </p-dialog>
    </footer>
</div>