<div class="modal-header flex-between border-0 pb-1">
    <div class="">
        <span class="text-16 font-medium" id="title_SetIntegrationsUpdateCalendly">{{translate?.setup.integrations.sections.sec3.singular }}</span>
        <span class="text-12 pt-1 color-var-500 d-block" id="subtitle_SetIntegrationsUpdateCalendly">{{translate?.setup.integrations.sections.sec4.singular }}</span>
    </div>
    <i class="fa-regular fa-xmark" (click)="modalRef.hide()" id="btn_SetIntegrationsUpdateCalendlyClose"></i>
</div>
<div class="modal-body tix pt-2" *ngIf="themeConfg">
    <div class="custella-form-container rounded-2 ms-0 me-2 box-none border-dee">
        <!-- Container Content -->
        <div class="custella-form-container-title text-14 font-medium ps-4 rounded-top" id="title_SetIntegrationsCalendlyTheme">{{translate?.setup.integrations.sections.sec5.singular }}</div>
        <div class="d-flex align-items-start">
            <div class="custella-form-container-content my-2 col-6">
                <!-- Title -->
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="text-12 font-medium font-grey6" id="label_SetIntegrationsThemeLogo">{{translate?.setup.integrations.fields.logo.singular }}</span>
                        <div class="custella-vp-picture d-flex align-items-end mt-1">
                            <!-- PREETI CONFIGURATION -->
                            <div class="cal-logo-two">
                                <img *ngIf="!themeConfg.logoAttachment" src="../../../../../assets/svg/table/calendly-logo.svg" alt="" id="img_SetIntegrationsThemeLogo">
                                <img *ngIf="themeConfg.logoAttachment" [src]="themeConfg.logoAttachment.attachmentUrl" id="img_SetIntegrationsThemeLogo">
                            </div>
                            <!----------------------------------------------------------------------------------------------->

                            
                            <!-- <img src="../../../../../assets/svg/table/calendly-logo.svg" alt=""> -->
                            <!-- <p-avatar [image]="logoAttachment" styleClass="mr-2 mt-1 preview-calendly-mini"size="xlarge"></p-avatar> -->
                            <div class="flex-between">
                                <div class="dropdown ps-2">
                                    <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                        <button pButton type="button" icon="pi pi-pencil" [label]="translate?.general.buttons.edit.singular" id="btn_SetIntegrationsThemeLogoEdit"
                                            class="p-button-secondary mx-1"></button>

                                           

                                    </div>
                                    <ul class="dropdown-menu dropdown-collaps p-0">
                                        <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_SetIntegrationsThemeLogoUpload" (click)="clearUpload()">{{translate?.general.buttons.removePhoto.singular }}</li>
                                        <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_SetIntegrationsThemeLogoUploadPhoto" (click)="openModal('uploadCalendlyLogo',themeConfg)">{{translate?.general.buttons.uploadPhoto.singular }}</li>
                                    </ul>
                                    <!-- (click)="openModal('uploadCalendlyLogo',themeConfg)" -->
                                </div>
                            </div>
                            
                          
                        </div>
                    </div>
                </div>
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <form  [formGroup]="addThemeFrom">
                        <span class="text-12 font-medium font-grey6 mandatory"  id="label_SetIntegrationsThemeTitle">{{translate?.setup.integrations.fields.title.singular }}</span>
                        <div class="custella-color-picker flex-align-center mt-1">
                           
                            <input type="text" formControlName="title" pInputText autocomplete="off" [placeholder]="translate?.general.messages.none " id="input_SetIntegrationsThemeTitleField"[(ngModel)]="themeConfg.title">
                            <div class="ms-5px"><p-colorPicker [ngModelOptions]="{standalone: true}" [(ngModel)]="color" (onChange)="changeFontcolor(color)" id="color_SetIntegrationsThemeTitleColorField"></p-colorPicker></div>
                            
                        </div>
                        <div *ngIf="addThemeFrom.controls['title'].touched && addThemeFrom.controls['title'].invalid">
                            <span class="text-10 p-error" *ngIf="addThemeFrom.controls['title'].errors?.required" id="label_SetIntegrationsThemeTitleReqField" >{{translate?.general.validationFE.requiredField }}</span>
                        </div>
                    </form>
                    </div>
                </div>
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="text-12 font-medium font-grey6" id="label_SetIntegrationsThemeSubTitle">{{translate?.setup.integrations.fields.subTitle.singular }}</span>
                        <div class="custella-color-picker flex-align-center mt-1">
                            <input type="text" pInputText autocomplete="off" [placeholder]="translate?.general.messages.none " class="me-1"  [(ngModel)]="themeConfg.subTitle" id="input_SetIntegrationsThemeSubTitleField">
                        </div>
                    </div>
                </div>
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="text-12 font-medium font-grey6" id="label_SetIntegrationsThemeBackground">{{translate?.setup.integrations.fields.background.singular }}</span>
                        <div class="custella-color-picker flex-align-center mt-1">
                            <div class="color-scheme showOption color1 " (click)="changeBGcolor('#e7e7e7','#1a1a1a')" id="btn_SetIntegrationsThemeSubTitleColorOne"></div>
                            <div class="color-scheme showOption color2 ms-2" (click)="changeBGcolor('#1a1a1a','#FFFFFF')" id="btn_SetIntegrationsThemeSubTitleColorTwo"></div>
                            <div class="color-scheme showOption color3 ms-2" (click)="changeBGcolor('#FFFFFF','#1a1a1a')" id="btn_SetIntegrationsThemeSubTitleColorThree"></div>
                            <div class="color-scheme showOption color4 ms-2" (click)="changeBGcolor('#6366f1','#FFFFFF')" id="btn_SetIntegrationsThemeSubTitleColorFour"></div>
                            <div class="ms-2 color-scheme">
                                <p-colorPicker [(ngModel)]="color5" appendTo="body" (onChange)="changeBGcolor_(color5)"id="btn_SetIntegrationsThemeSubTitleColorRandom"></p-colorPicker>
                                <i class="pi pi-pencil colorEdit" id="btn_SetIntegrationsThemeSubTitleColorRandomChoose"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content my-2 col-6 border-start">
                <!-- Title -->
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="text-14 font-medium font-grey6" id="title_SetIntegrationsThemePreview">{{translate?.setup.integrations.fields.preview.singular }}</span>
                        <span class="text-12 color-9e d-block" id="subtitle_SetIntegrationsThemePreview">{{translate?.setup.integrations.messages.sampleCustomerBookTime }}</span>
                        <div class="preview-calendly mt-1">
                            <div class="flex-align-center">
                                <!-- PREETI CONFIGURATION -->
                                <div class="cal-logo-two-mini"> 
                                    <img *ngIf="!themeConfg.logoAttachment" src="../../../../../assets/svg/table/calendly-logo.svg" alt="" id="img_SetIntegrationsThemePreviewLogo">
                                    <img *ngIf="themeConfg.logoAttachment" [src]="themeConfg.logoAttachment.attachmentUrl" id="img_SetIntegrationsThemePreviewLogo">
                                </div>
                                <!----------------------------------------------------------------------------------------------->
                                <!-- <p-avatar [image]="themeConfg?.logoAttachment?.attachmentUrl" styleClass="mr-2 mt-1"></p-avatar> -->
                                <div>
                                    <span class="text-12 font-medium ms-2" id="comapny-name" id="label_SetIntegrationsThemePreviewTitle">{{themeConfg.title ?? 'Company Name'}}</span>
                                    <span class="text-10 ms-2 d-block" id="subTitle" id="label_SetIntegrationsThemePreviewSubTitle">{{themeConfg.subTitle ?? 'Confirm your slot'}}</span>
                                </div>
                            </div>
                            <div class="flex-align-center justify-content-center p-2">
                                <img src="../../../../../assets/svg/table/CALENDLY-schedule 1.svg" alt="" id="img_SetIntegrationsThemePreviewExampleScheduler">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="custella-form-container-footer flex-between mb-0">
            <button type="button" class="custella-btn-white text-12" id="btn_SetIntegrationsUpdateCalendlyRevertDefault" (click)="setRevertToDefault()">{{ translate?.general.buttons.revertToDefault.singular  }}</button>
            <div class="flex-align-center justify-content-end">
                <button type="button" class="custella-btn-white text-12 ms-2" id="btn_SetIntegrationsUpdateCalendlyCancel" (click)="modalRef.hide()">{{ translate?.general.buttons.cancel.singular}}</button>
                <p-button type="button" (onClick)="onSubmit()"  [disabled]="submitTouched" styleClass="ms-2 h-32" id="btn_SetIntegrationsUpdateCalendlySubmit" [label]="translate?.general.buttons.save.singular "></p-button>
            </div>
        </div>
    </div>
</div>

<div class="custella-custom-dialog">
    <p-confirmDialog
      #cd
      [style]="{ width: '30vw' }"
      [baseZIndex]="10000"
      key="confirm"
    >
      <ng-template pTemplate="header">
        <div class="d-flex align-items-center">
          <img src="assets\svg\dialog\Confirmation-w.svg" />
          <div class="text-16 font-medium ms-3" id="title_SetIntegrationsUpdateCalendlyConfirm">
            {{ translate?.general.messages.confirm  }}
          </div>
        </div>
      </ng-template>
      <p-footer>
        <div class="d-flex align-items-center justify-content-end">
          <button class="custella-btn-white text-12 ms-2 me-0" (click)="cd.reject()" id="btn_SetIntegrationsUpdateCalendlyConfirmCancel">
            {{ translate?.general.buttons.cancel.singular }}
          </button>
          <button
            pButton
            type="button"
            [label]="translate?.general.buttons.yes.singular "
            iconPos="left"
            class="p-button-info ms-2 me-0 h-32"
            (click)="cd.accept()"
            id="btn_SetIntegrationsUpdateCalendlyConfirmYes"
          ></button>
        </div>
      </p-footer>
    </p-confirmDialog>
  </div>