<div class="custella-modal">
    <form>
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2"> {{translate?.general.status.messages.updateStatus }} </span>
            <i class="fa-regular fa-xmark" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
        </div>

        <div class="">
            <!-- Container -->
            <div class="custella-form-container rounded-2 height-80 m-0">
                <form>
                    <div class="custella-form-container-title-two-grey fst-italic">
                        <div class="text-12 font-medium d-flex"><p class="font-bold">{{translate?.general.status.draft.singular}}</p>&nbsp;&#8208;&nbsp;{{translate?.general.status.messages.forIncompleteChecklists}}</div>
                        <div class="text-12 font-medium d-flex"><p class="font-bold">{{translate?.general.status.completed.singular}}</p>&nbsp;&#8208;&nbsp;{{translate?.general.status.messages.thisStatusWillTriggerAnyJobOrders}}</div>
                    </div>
                    <div class="custella-form-container-content my-2">
                        <div class="custella-content-row mb-3 pe-0">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title mandatory" >{{translate?.general.status.messages.updateStatusTo}}</span>
                                
                                <p-dropdown name="status"   [(ngModel)]="status" [placeholder]="translate?.general.messages.none" [options]="satatus" optionLabel="label" filterBy="label" optionValue="value" ></p-dropdown>
                                <!-- <button class="border rounded-1 px-06 ms-2 btn-32"><i class="pi pi-search pt-04"></i> </button> -->
                            </div>
                        </div>
                    </div>
                </form>
                <div class="custella-form-container-footer-two">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                        <p-button [label]="buttonLabel" styleClass="ms-2 h-32" (onClick)="onSubmitData()"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>