import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin } from 'rxjs';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { VisitsService } from 'src/app/_services/visits.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { PERSISTANT_ITEM, ServerRequest } from "src/app/_models/global.data.model";
import * as moment from 'moment';
import { SiteService } from 'src/app/_services/site.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { SelectAllComponent } from 'src/app/renderer/select-all.component';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-all-visits',
  templateUrl: './all-visits.component.html'
})
export class AllVisitsComponent extends SubscriptionUtil
  implements OnInit, OnDestroy {

  @Input() display: any = null
  @Input() pendingStatus: any = null
  @Input() machineSpecialists: any = null
  @Input() siteData: any;
  @Input() tabName: any;

  filterView = false;

  visitTypes: any[] = [];
  approvalStatuses: any[] = [];
  statuses: any[] = [];
  refresher!: Subscription
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  noVisitDataIcon!: boolean;
  attributeIdName: any;

  searchValue: any = "";
  currentRecordNumber: any;
  totalRecord: any;
  visitData: any;
  visitType: any;
  approvalStatus: any;

  filterActive: boolean = false;
  filterClick: boolean = false;

  dialogStatusData = {
    module: "",
    recordNumber: "",
    actionStatus: "",
    actionDescription: false,
    multipleDataAccept: "",
    multipleDataAcceptTitle: "",
    headerTitle: "",
  };
  selectedId: any;


  noVisits = false;
  displayIconVisits = false;
  dateRange!: Date[];
  myGroup!: any;
  hideOnDateTimeSelect: boolean = true;
  @ViewChild('dateFilter', { static: false })
  private dateFilter: any;
  allSites: any;
  site: any;
  status: any;
  filter: any = {
    status: null,
    visitType: null,
    dueEndDate: null,
    fromDate: null,
    toDate: null,
    approvalStatus: null,
    siteId: null
  };
  filterAPILoaded: boolean = false;
  searchValueData: boolean = false;

  public showingTable = {
    sites: false,
    machineSpecialist: false,
  };
  selectedSite: any;
  selectMachineSpecialist: any;
  allMachineSpecialist: any;
  selectAllChecked: any = false;
  selectedRows: any = [];

  showStatusDialog: boolean = false;
  selectedIds: any[] = []
  createVisist!: boolean;
  translate: any;

  constructor(private router: Router,
    private auth: AuthenticationService,
    private visitService: VisitsService,
    private perm: PermissionService,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private sitesService: SiteService,
    private checklistService: ChecklistService,
    private languageTranslateService: LanguageTranslateService) {
    super();
    this.push(  this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    }))
    this.push(
      this.visitService.getSelectItem().subscribe((data) => {
        if (data) {
          this.selectAllChecked = data;
        }
      })
    );

  }

  ngOnInit(): void {
    this.createVisist = this.perm.capable("visit", "create");
    this.util.resetTabView();
    console.log(this.siteData)
    this.tabName == "pending" ? this.attributeIdName = "VisitPend": null;
    this.tabName == "todayPending" ? this.attributeIdName = "VisitTodPend": null;
    this.tabName == "all" ? this.attributeIdName = "Visit": null;
    console.log("tabb",this.attributeIdName)

    if (this.display) {
      var todays = new Date()
      this.dateRange = [todays, todays]
      let startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      let endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      this.filter.fromDate = this.util.timeFormatter(moment("00:00:00", "h:mm A").format("h:mm A"), moment(this.dateRange[0], "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();
      this.filter.toDate = this.util.timeFormatter(moment("23:59:00", "h:mm A").format("h:mm A"), moment(this.dateRange[1], "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();


    }

    if (this.pendingStatus) {
      this.filter.approvalStatus = this.pendingStatus
    }
    /***********************  Data Set for MS-> View **************************/
    if (this.machineSpecialists) {
      let dataObj = {
        fullname: this.machineSpecialists.user.firstName + ' ' + this.machineSpecialists.user.lastName,
        userId: this.machineSpecialists.user.id,
        siteId:  this.machineSpecialists.defaultSite.id,
        SiteName:  this.machineSpecialists.defaultSite.siteName,
        userGroup:  this.machineSpecialists.defaultSite.userGroup ? this.machineSpecialists.defaultSite.userGroup.name:null,
        comingFrom:'ms'
      };
      this.util.setPersistantItem(PERSISTANT_ITEM.MS_VISIT, dataObj);
      this.selectMachineSpecialist = this.machineSpecialists.user.firstName
      this.filter.userId = this.machineSpecialists.user.id
    }
    /***********************  Data Set for Site-> View **************************/
    if (this.siteData) {
      let dataObj = {
        fullname: null,
        userId: null,
        siteId:  this.siteData.id,
        SiteName:  this.siteData.siteName,
        userGroup:  this.siteData.userGroup ? this.siteData.userGroup.name:null,
        comingFrom:'site'
      };
      this.selectedSite = this.siteData;
      this.filter.siteId = this.siteData.id;
      this.util.setPersistantItem(PERSISTANT_ITEM.MS_VISIT, dataObj);
    }

    this.init();
    this.preInit();
  }

  ngOnDestroy(): void {
    this.onDestroy();

  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.push(
      this.refresher = this.visitService.refreshList$.subscribe((visit) => {
        this.refresh();
      })
    );

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      selectAllComponent: SelectAllComponent
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      paginationPageSize: 25,
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
    console.log(this.pendingStatus)
    this.columnDefs = [
      {
        hide: this.pendingStatus == null ? true : false,
        width: 70, sortble: false,
        headerComponent: 'selectAllComponent',
        headerComponentParams: {
          checkboxSelection: true,
        },
        checkboxSelection: true,

        cellRenderer: (params: any) => {
          //////////////debugger
          if (params.data) {
            console.log("&&&&&&&&&&", params.data)
            if (this.selectAllChecked) {
              params.node.setSelected(true);
            }
            else if (!this.selectAllChecked) {
              //   console.log(params.node,"************************************************************")
              params.node.setSelected(false);

            }
            else {
              params.node.setSelected(false);
            }
          }
        }
      },
      { headerName: "Visit No", field: "runningNo", width: 250, headerTooltip: "#", cellClass: function (params: any) { return ["table_default_color"]; } },
      {
        headerName: "Visit Time & Date", field: "dateTime", width: 250, headerTooltip: "#",
        cellRenderer: (params: any) => {
          if (params.data) {
            if (params.data.dateTime !== null && params.data.dateTime !== undefined && params.data.dateTime !== "") {
              return (
                moment(params.data.dateTime).format(
                  localStorage.getItem("date_format")?.toUpperCase()
                ) +
                ", " +
                moment(params.data.dateTime).format("hh:mm A")
              );
            } else {
              return moment(params.data.dateTime).format(
                localStorage.getItem("date_format")?.toUpperCase()
              );
            }
          }
        }
      },
      { headerName: "Estate", field: "site.siteName", width: 250, headerTooltip: "#", cellClass: function (params: any) { return ["table_default_color"]; } },
      { headerName: "Visit Type", field: "visitType.name", width: 220, headerTooltip: "#" },
      {
        headerName: "Visit Status",
        field: "status.label",
        width: 220,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.status) {
            return ' <span class="p-mr-2 p-tag p-component" style="background-color:' + params.data.status.colorCode + '50 !important;"> <span class="p-tag-value text-dark"> ' + params.data.status.label + '</span></span>'
          }
        }
      },
      {
        headerName: "Approval Status", field: "approvalStatus.name", width: 220, headerTooltip: "#", cellRenderer: function (params: any) {
          if (params.data && params.data.approvalStatus) {
            return ' <span class="p-mr-2 p-tag p-component" style="background-color:' + params.data.approvalStatus.colorCode + '50 !important;"> <span class="p-tag-value text-dark"> ' + params.data.approvalStatus.label + '</span></span>'
          }
        }
      },
      {
        headerName: "Action",
        sortable: false,
        filter: false,
        width: 200,
        headerTooltip: "#",
        headerClass: "marging-auto hide-action-border",
        cellStyle: { "text-align": "right" },
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.editVisits.bind(this),
          actionMS: "ActionMS",
          permissions: {
            update: this.perm.capable("visit", "update"),
            remove: this.perm.capable("visit", "remove"),
          },
          hidden: {
            update: !this.perm.capable("visit", "update"),
            remove: !this.perm.capable("visit", "remove"),
          },
        },
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };

    //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";
    this.myGroup = new UntypedFormGroup({
      dateRange: new UntypedFormControl(""),

    });
  }
  onSelectionChanged(event: any): void {
    const selectedRows: string[] = event.api.getSelectedRows().map((t: any) => t);
    console.log('selectedRows');
    console.log(selectedRows);
    ////debugger
    this.selectedRows = [];
    this.selectedRows = selectedRows;
    if (this.selectedRows.length > 0) {
      $('.ms-action-button').css('visibility', 'hidden');
    } else {
      $('.ms-action-button').css('visibility', 'visible');
    }
    //$('#checkboxCustella1').addClass('c-checkbox-icon');

  }
  /******************************************** Init ******************************************/
  private init() {
    this.spinner.show()
    //this.getTeams();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    this.refresher.unsubscribe()
    super.ngOnDestroy();
  }

  /******************************************** Filter List ******************************************/
  getFilterList() {
    const sources = [
      this.visitService.getAllVisitTypes(),
      this.visitService.getAllApprovalStatus(),
      this.visitService.getAllVisitStatus()
    ];
    this.push(
      forkJoin(sources).subscribe(
        ([
          typeResponce,
          approveResponce,
          statusResponce,
        ]) => {
          console.log(typeResponce)
          console.log(approveResponce)
          console.log(statusResponce)
          this.visitTypes = typeResponce.body
          this.approvalStatuses = approveResponce.body;
          this.statuses = statusResponce.body;
        }
      )
    );
  }

  public toggleFilterView() {
    this.filterActive = !this.filterActive;
    this.filterClick = !this.filterClick;
    if (this.filterAPILoaded === false) {
      this.getFilterList()
    }
    this.filterAPILoaded = true;
  }

  createView() {
    this.router.navigate(['dashboard/visits/create'])
  }

  viewPage() {
    this.router.navigate(['dashboard/visits/view'])
  }

  /********************************** Click Cell Event *****************************/
  private editVisits(e: any) {
    if (e.action === "edit") {
      this.router.navigate(["dashboard/visits/update"], {
        queryParams: { id: e.rowData.id },
      });
    } else if (e.action === "delete") {
      console.log(e)
      e.rowData.deleteType = "Visits";
      e.rowData.message =
        "Are you sure you want to delete " + e.rowData.runningNo + "?";

      this.deleteRecordService.getItems(e.rowData);
    } else if (e.action === "acceptMS") {
      this.selectedId = e.rowData.id
      this.showStatusDialog = true;
      this.dialogStatusData.module = "Visits"
      this.dialogStatusData.recordNumber = e.rowData.runningNo
      this.dialogStatusData.actionStatus = "APPROVED"
      this.dialogStatusData.actionDescription = false
      this.dialogStatusData.multipleDataAccept = "SINGLE",
        this.dialogStatusData.headerTitle = "Approve Visit"
      this.showStatusDialog = true;
    }
  }


  /********************************** View Singal Record **************************/
  onGridReady(params: any) {
    //console.log(params)
    this.gridParams = params;
    this.gridApi = params.api;
    //this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    // this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }
  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    console.log(this.filter)

    this.push(
      this.visitService.getAllVisits(this.searchValue, serverRequest, this.filter).subscribe((data: any) => {
        this.spinner.hide();
        if (data.status === 200 || data.status === 201) {
          this.visitData = data.body.data;
          console.log(this.visitData);
          this.totalRecord = data.body.total;
          this.visitData.forEach((element: any) => {
            element.hideActionButton = 'hideAction';//for hide delete button
          });
          this.setGridData(
            agGridGetRowsParams,
            this.visitData,
            data.body.total,
            data.body.status
          );
          console.log(this.visitData);
        } else {
          this.errorUtil.setErrorMessage(data.status, null);
        }
      })
    );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    //if (resultItems) {

    if (status === "NO_DATA" || status === "SEARCH_NO_RESULTS") {
      this.noVisits = false;
      this.noVisitDataIcon = true;
      this.gridParams.api.showNoRowsOverlay();
    } else {
      this.noVisits = true;
      this.noVisitDataIcon = false;
    }
    if (resultItems.length === 0) {
      this.gridParams.api.showNoRowsOverlay();
    } else {
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "runningNo", sort: "desc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,

    };
  }


  /******************************************** Refresh ******************************************/
  public refresh() {
    this.spinner.show()
    console.log(this.site);
    this.onGridReady(this.gridParams);
  }

  /******************************************** Search ******************************************/
  public onSearch() {
    if (this.searchValue && this.searchValue.length > 0) {
      this.searchValueData = true
      this.refresh();
    }
  }

  /******************************************** call table after filter ******************************************/
  public filterResults($event: any) {
    console.log(this.approvalStatus);
    this.refresh();
  }

  /******************************************** reset filter ******************************************/
  public resetSearch() {
    //this.dateRange = undefined;
    this.myGroup.get("dateRange").setValue(null);
    this.filter = {
      status: null,
      customerId: null,
      salesOrderId: null,
      fromDate: "",
      toDate: "",
    };

    this.refresh();
  }

  /********************************** View Singal Record **************************/
  viewVisit(e: any) {
    console.log(e.colDef)
    if (e.colDef.field === "runningNo") {
      this.router.navigate(['dashboard/visits/view/' + e.data.id], { queryParams: { id: e.data.id } });
    }
    if (e.colDef.field === "site.siteName") {
      this.util.resetTabView();
      this.router.navigate(["/dashboard/sites/view/" + e.data.site.id], {
        queryParams: { id: e.data.site.id },
      });
    }
    //this.selectCustomer(e.data)
  }


  public onChangeDate() {
    console.log(this.dateRange)
    if (this.dateRange[0] != null && this.dateRange[1] !== null) {
      let startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      let endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      this.filter.fromDate = this.util.timeFormatter(moment("00:00:00", "h:mm A").format("h:mm A"), moment(this.dateRange[0], "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();
      this.filter.toDate = this.util.timeFormatter(moment("23:59:00", "h:mm A").format("h:mm A"), moment(this.dateRange[1], "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();

      this.dateFilter.hideOverlay()
      this.refresh();
    }

  }

  /******************************************** Clear Value Dynamic Picklist ******************************************/
  public clearValue() {
    this.myGroup.get("dateRange").setValue(null);
    this.filter = {
      status: null,
      visitType: null,
      dueEndDate: null,
      toDate: this.display ? this.util.timeFormatter(moment("23:59:00", "h:mm A").format("h:mm A"), moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString() : null,
      fromDate: this.display ? this.util.timeFormatter(moment("00:00:00", "h:mm A").format("h:mm A"), moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString() : null,
      approvalStatus: this.pendingStatus ?? null,
      siteId: null,
      userId: this.machineSpecialists ? this.machineSpecialists.user.id : null
    };
    this.selectedSite = null
    this.selectMachineSpecialist = null;
    this.refresh();

  }



  onClickDialogYes(event: any) {
    this.spinner.show();
    
    if (event) {
      if (event.actionStatus == "APPROVED") {
        const status = "APPROVED"
        let data = {
          id: this.selectedIds.length > 0 ? this.selectedIds : [this.selectedId],

          status: status
        }
        this.visitService.updateApprovalStatus(data,true).subscribe((res: any) => {

          if (res.status == 200) {
            this.errorUtil.setErrorMessage(res.status, "Visit Approval Status updated successfully", null, "success", 2000);
            if (this.selectedId) {
              this.router.navigate(['dashboard/visits/view/' + this.selectedId], { queryParams: { id: this.selectedId } });
            }
            if (this.selectedIds.length > 0) {
              this.selectedRows = [];
              this.refresh();
            }
          }
        }, err => {
          this.errorUtil.setErrorMessage(err.status, null, err.error.title, "error", 3000);
        });


      
    }
  }
    this.showStatusDialog = false;
  }

visibleOut(e: any) {
  this.showStatusDialog = e;
}
  
  public onSearchCheck()
{
  if (this.searchValue.length == 0) {
    this.searchValueData = false
    this.searchValue = ''
    this.refresh()
  }
}
  public onClear(){
  this.searchValueData = false
  this.searchValue = ''
  this.refresh()

}

  /******************************************** On Site Select Value ******************************************/
  public onSiteSelect(event: any) {
  
  this.selectedSite = event;
  this.filter.siteId = event.id;
  this.closeTable("sites");
  this.refresh()
}

  /******************************************** Get All Site By Query ******************************************/
  public getSite(event: any) {
  const query = event.query.replace(
    /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
    ""
  );

  if (query && query.length > 2) {
    this.push(
      this.sitesService.getSitePicklist(query).subscribe((sites: any) => {
        this.allSites = sites;
      })
    );
  }
}

  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string) {
  if (what === "sites") {
    this.showingTable.sites = true;
  }

  if (what === "machineSpecialist") {
    this.showingTable.machineSpecialist = true;
  }
}

  /******************************************** Close Table Dynamic Picklist ******************************************/
  public closeTable(what: string) {
  if (what === "sites") {
    this.showingTable.sites = false;
  }

  if (what === "machineSpecialist") {
    this.showingTable.machineSpecialist = false;
  }

}

  /******************************************** On Machine Specialist Select Value ******************************************/
  public onMachineSpecialistSelect(event: any) {
  console.log(event)
  this.selectMachineSpecialist = { name: event.user.firstName + ' ' + event.user.lastName };
  this.filter.userId = event.user.id;
  this.closeTable("machineSpecialist");
  this.refresh()
}

  /******************************************** Get All Machine Specialist By Query ******************************************/
  public getMachineSpecialist(event: any) {
  const query = event.query.replace(
    /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
    ""
  );

  if (query && query.length > 2) {
    var serverRequest: any = {
      startRow: 0,
      pageSize: 25,
      filterModel: null,
      sortModel: [{ colId: "id", sort: "asc" }]
    }
    this.push(this.checklistService.getAllMachinesSpecialist(query, serverRequest, null, null).subscribe((response: any) => {
      this.allMachineSpecialist = response.body.data;
      console.log(response)
      if (this.allMachineSpecialist) {
        this.allMachineSpecialist.forEach((element: any) => {
          element.name = element.user.firstName + ' ' + element.user.lastName
        });

      }
    })
    );
  }
}
   /******************************************** clear customer ******************************************/
   public clearValueFrom(what: string) {

  if (what === "site") {
    this.selectedSite = null;
    this.filter.siteId = null;
  }

  if (what === "machineSpecialist") {
    this.selectMachineSpecialist = null;
    this.filter.userId = null;
  }
  this.refresh()
}


/********************************** Accept multip-le statuses **************************/
onAcceptMultipleStatus() {

  console.log("", this.selectedRows);
  this.selectedIds = [];
  this.selectedRows.forEach((row: any) => {
    this.selectedIds.push(row.id)
  })

  console.log(this.selectedRows.length)
  if (this.selectedRows.length > 0) {
    this.showStatusDialog = true;
    this.dialogStatusData.actionStatus = "APPROVED"
    this.dialogStatusData.module = "Visits"
    this.dialogStatusData.multipleDataAccept = "MULTI";
    this.dialogStatusData.multipleDataAcceptTitle = this.selectedRows.length + " visit records will now be approved. Do you wish to continue ? "
    this.dialogStatusData.headerTitle = "Approve Visit"
    this.showStatusDialog = true;

  }
  else if (this.selectedRows.length < 1) {
    window.alert("select atleast one record")
  }



}


  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
  params.node.data.field = params.column.colId;
  let url = "";
  let data = "";

  let results = [];

  if (params.column.colId == "runningNo" || params.column.colId === "site.siteName") {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  return results;
};

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
  this.util.resetTabView();
  let url;
  if (data.field === "runningNo") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ['dashboard/visits/view/' + data.id], { queryParams: { id: data.id } }
      )
    );
  } else if (data.field === "site.siteName") {
    this.util.resetTabView();
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ['dashboard/sites/view/' + data.site.id], { queryParams: { id: data.site.id } }
      )
    );

  }

  if (url) {
    url = url.replace("%23", "#");
    this.util.openItemToNewTab(url);
  }
}

}
