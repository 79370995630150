<!-- <div class="modal-content modal-fullscreen p-0"> -->
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <img  *ngIf="option == 'Decline'" src="assets\svg\dialog\Danger-w.svg" id="img_TaskDecline">
            <img  *ngIf="option == 'Accept'" src="assets\svg\dialog\Confirmation-w.svg" id="img_TaskAccept"> 
            <span *ngIf="option == 'Decline'" class="text-16 font-medium ms-3" id="title_TaskDecline">{{translate?.task.fields.confirmTaskDecline.singular}}</span>
            <span *ngIf="option == 'Accept'" class="text-16 font-medium ms-3" id="title_TaskAccept">{{translate?.task.fields.confirmTaskAcceptance.singular}}</span>
        </div>
        <i class="fa-regular fa-xmark" (click)="modalRef.hide()"></i>
    </div>
      
                <div class="modal-body pt-0">
                <div class="container text-left px-0 pt-14px">
                    <p class="mb-0 text-4" id="label_TaskOptionThisTask">{{translate?.task.messages.areYouSure}} {{ option }} {{translate?.task.messages.areThisTask}}</p>
                    <div class="d-flex justify-content-between" *ngIf="option == 'Decline'">
                        <div class="d-block position-relative me-3 col-12 mt-2">
                            <textarea pInputTextarea autocomplete="off" id="input_TaskOptionThisTaskField" [(ngModel)]="reason" [ngModelOptions]="{standalone: true}" [placeholder]="translate?.task.messages.reasonOptional " [maxlength]="320000"></textarea>
                                       
                        </div>
                    </div>
                </div>
                </div>
                <div class="modal-footer p-0">
                    <button type="button" id="btn_TaskOptionDecline" class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">{{translate?.general.buttons.no.singular}}</button>
                    <p-button type="button" id="btn_TaskOptionAccept" (onClick)="onSubmit()"  [label]="translate?.general.buttons.yes.singular"></p-button>
                </div>

          
            
        

    <!-- </div> -->