<div class="topbar">
    <div class="custella-topbar-left" id="topbar-left">
        <!-- Service dropdown -->
        <!-- <div class="dropdown">
            <a id="custella-menu-button" href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="assets\svg\header\top-dots.svg" />
            </a>

            <ul class="dropdown-menu custella-service">
                <li class="flex-between mt-1" [ngClass]="{'disabledMenu':!salesEnabled || !salePer}" (click)="salesEnabled ? menuType('sales'):null">
                    <a [ngClass]="{'active':defaultTenantCategory === 'sales' && salePer}" class="custella-service dropdown-item flex-between">
                        <span class="me-5 py-2 text-12" >{{sales}}</span>
                        <img *ngIf="salesEnabled && salePer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        <p-button *ngIf="!salePer" label="Add On" (onClick)="executeAddon()"></p-button>
                    </a>
                </li>
                <li class="mt-1" [ngClass]="{'disabledMenu':!serviceEnabled || !servicePer}" (click)="serviceEnabled && servicePer?menuType('service'):null">
                    <div class="d-flex flex-between">
                        <a [ngClass]="{'active': defaultTenantCategory === 'service' && servicePer}" class="custella-service dropdown-item flex-between">
                            <span class="me-5 py-2 text-12" >{{services}}</span>
                            <img *ngIf="serviceEnabled && servicePer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        </a>
                        <p-button *ngIf="!servicePer" label="Add On" (onClick)="executeAddon()"></p-button>
                    </div>



                    <a [ngClass]="{'active': defaultTenantCategory === 'service' && servicePer}" class="custella-service dropdown-item flex-between">
                        <span class="me-5 py-2 text-12" >{{services}}</span>
                        <img *ngIf="serviceEnabled && servicePer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        <p-button *ngIf="!servicePer" label="Add On" (onClick)="executeAddon()"></p-button>
                    </a>
                </li>
                <li class="mb-1" [ngClass]="{'disabledMenu':!fleetEnabled || !fleetPer}" (click)="fleetEnabled && fleetPer ?menuType('fleet'):null">
                    <div class="d-flex flex-between">
                        <a [ngClass]="{'active': defaultTenantCategory === 'fleet' && fleetPer}" class="custella-service dropdown-item flex-between">
                            <span class="me-3 py-2 text-12" >{{fleet}}</span>
                            <img *ngIf="fleetEnabled && fleetPer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        </a>
                        <p-button *ngIf="!fleetPer" label="Add On" (onClick)="executeAddon()" ></p-button>
                    </div>

                    <a [ngClass]="{'active': defaultTenantCategory === 'fleet' && fleetPer}" class="custella-service dropdown-item flex-between">
                        <span class="me-3 py-2 text-12" >{{fleet}}</span>
                        <img *ngIf="fleetEnabled && fleetPer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        <p-button *ngIf="!fleetPer" label="Add On" (onClick)="executeAddon()" ></p-button>
                    </a>
                </li>
            </ul>
        </div>

        <button pButton [label]="defaultTenantCategory" class="text-capitalize" style="height: 24px;"></button>-->
        <span class="custella-topbar-title" id="value_HeaderCompanyName">{{companyName}}</span>
    </div>

    <a id="options-menu-button" href="javascript:void(0)"> <span class="pi pi-ellipsis-h"></span> </a>
    <div class="d-flex position-relative justify-content-center align-items-center me-2">
        <!-- Language start -->
        <div class="custella-lang dropdown">
            <!-- <div class="" id="language"> -->
                <button class="justify-content-between px-1 py-2"data-bs-toggle="dropdown" aria-expanded="false" id="btn_HeaderLanguageSelection">
                    <div>
                        <span class="fi fi-{{flag}} me-2" id="img_HeaderLanguageSelection"></span>
                        <span class="text-12 font-white" *ngIf="currentLang === 'English'" id="value_HeaderLanguageSelectionEng">
                            {{words?.header.fields.english.singular}}
                        </span>

                        <span class="text-12 font-white" *ngIf="currentLang === 'Malay'" id="value_HeaderLanguageSelectionMy">
                            {{words?.header.fields.malay.singular}}
                        </span>

                        <span class="text-12 font-white" *ngIf="currentLang === 'Chinese'" id="value_HeaderLanguageSelectionZh">
                            {{words?.header.fields.chinese.singular}}
                        </span>
                        <span class="text-12 font-white" *ngIf="currentLang === 'Deutsch'" id="value_HeaderLanguageSelectionDe">
                           Deutsch
                        </span>
                        <span class="text-12 font-white" *ngIf="currentLang === 'Español'" id="value_HeaderLanguageSelectionEs">
                            Español
                        </span>
                    </div>
                    <i class="fa-regular fa-chevron-down ms-2" style="color: #e0e0e0;" id="btn_HeaderLanguageSelectionDropdown"></i>
                </button>

            <!-- </div> -->
            <ul class="dropdown-menu mt-3" #chooseLanguage>
                <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('en')"
                    *ngIf="currentLang !== 'English'" id="link_HeaderLanguageSelectionEng">
                    <a class="px-1">
                        <span class="fi fi-us me-2" id="img_HeaderLanguageSelectionEngFlag"></span>
                        <span class="text-12 font-white" id="label_HeaderLanguageSelectionEng">{{words?.header.fields.english.singular}}</span>
                    </a>
                </li>
                <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('my')"
                    *ngIf="currentLang !== 'Malay'" id="link_HeaderLanguageSelectionMy">
                    <a class="px-1">
                        <span class="fi fi-my me-2" id="img_HeaderLanguageSelectionMyFlag"></span>
                        <span class="text-12 font-white" id="label_HeaderLanguageSelectionMy">{{words?.header.fields.malay.singular}}</span>
                    </a>
                </li>
                <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('zh')"
                    *ngIf="currentLang !== 'Chinese'" id="link_HeaderLanguageSelectionZh">
                    <a class="px-1">
                        <span class="fi fi-cn me-2" id="img_HeaderLanguageSelectionZhFlag"></span>
                        <span class="text-12 font-white" id="label_HeaderLanguageSelectionZh">{{words?.header.fields.chinese.singular}}</span>
                    </a>
                </li>
                <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('de')"
                    *ngIf="currentLang !== 'Deutsch'" id="link_HeaderLanguageSelectionDe">
                    <a class="px-1">
                        <span class="fi fi-de me-2" id="img_HeaderLanguageSelectionDeFlag"></span>
                        <span class="text-12 font-white" id="label_HeaderLanguageSelectionDe">Deutsch</span>
                    </a>
                </li>
                <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('es')"
                    *ngIf="currentLang !== 'Español'" id="link_HeaderLanguageSelectionEs">
                    <a class="px-1">
                        <span class="fi fi-es me-2" id="img_HeaderLanguageSelectionEsFlag"></span>
                        <span class="text-12 font-white" id="label_HeaderLanguageSelectionEs">Español</span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- Language end -->

        <!-- <a class="ng-star-inserted">
            <span class="position-relative mx-2"><img src="assets/svg/header/search.svg" class="cursor-pointer w-28px" /></span>
        </a> -->
        <a class="ng-star-inserted header" routerLink="messages" id="btn_HeaderMessages">
            <img src="assets/svg/header/comments.svg" class="cursor-pointer w-28px mx-2" id="img_HeaderMessages"/>
            <span *ngIf="messageCount > 0" class="position-relative mx-2" pBadge [value]="messageCount" id="badge_HeaderMessagesValue"></span>
        </a>

        <!-- Notifications -->
        <div class="dropdown header">
            <a class="ng-star-inserted" data-bs-toggle="dropdown" aria-expanded="false" (click)="openNotificationPanel()" id="btn_HeaderNotifications">
                <img src="assets/svg/header/bell.svg" class="cursor-pointer w-28px mx-2" id="img_HeaderNotifications"/>
                <span *ngIf="notificationCount > 0" class="position-relative mx-2" pBadge id="badge_HeaderNotificationsValue"
                    [value]="notificationCount"></span>
            </a>
            <ul class="custella-notification dropdown-menu">
                <li class="flex-between border-bottom border-secondary px-1 py-2">
                    <div class="d-flex align-items-center me-3">
                        <img class="mx-1 w-28px" src="assets\svg\header\bell.svg" id="img_HeaderNotificationsTitle"/>
                        <span class="fs-6 ps-1" id="title_HeaderNotificationsTitle"
                            style="padding-top: 2px;">{{words?.header.sections.sec2.singular}}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <a href="javascript:void(0)" class="text-decoration-underline"
                            (click)="clearAllNotifications()"><small
                                class="d-flex align-items-center text-nowrap text-10" id="label_HeaderNotificationsMarkAllRead">{{words?.general.buttons.markAllAsRead.singular}}</small></a>
                    </div>
                </li>
                <div class="custella-notification-body overflow-scroll p-0 m-0">
                    <li class="border-bottom border-secondary px-1"
                        *ngFor="let notification of notifications | orderBy: '-createdDate'; let i = index">
                        <a href="javascript:void(0)" (click)="viewNotification(notification)"
                            [ngClass]="{'notifcation-active' : notification.status === 'UNREAD'}"
                            class="dropdown-item d-flex align-items-start p-2" [id]="'link_HeaderNotificationsItems' + i">
                            <div id="img_HeaderNotificationsItems">
                                <img [src]="notification.icon" class="pt-1" [id]="'img_HeaderNotificationsItems' + i" />
                                
                            </div>
                           
                            <div class="text-left mx-2">
                                <span class="text-wrap text-12" [id]="'title_HeaderNotificationsItems' + i">{{notification.title}}</span>
                                <span class="d-block color-var-500 text-10" [id]="'value_HeaderNotificationsItemsDateTime' + i">{{notification.calendarTime}}
                                    {{words?.header.messages.at}} {{notification.createdDate | date :
                                    'shortTime'}}</span>
                            </div>
                        </a>
                    </li>
                </div>

                <!-- <li class="border-bottom border-secondary px-1">
                    <a  href="javascript:void(0)" class="dropdown-item d-flex align-items-start p-2">
                        <img src="assets\svg\header\task-white.svg" class="pt-1" />
                        <div class="text-left mx-2">
                            <span class="text-wrap text-12"><strong class="font-violet">Nathan</strong> has completed task <strong class="font-violet">Task 2</strong>.</span>
                            <span class="d-block color-var-500 text-10">31/03/2021, 1:07PM</span>
                        </div>
                    </a>
                </li> -->
            </ul>
        </div>
        <!-- Profile Settings -->
        <div class="dropdown profie">
            <a class="position-relative" href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenu">
                <p-avatar [image]="photoUrl" styleClass="mx-2 mt-2 display-dp" shape="circle" id="img_HeaderProfile"></p-avatar>
                <div [ngClass]="{'custella-online-mini' : onlineStatus, 'custella-offline-mini' : !onlineStatus}" id="img_HeaderProfileStatus"></div>
            </a>

            <ul class="custella-profile dropdown-menu mt-3" aria-labelledby="dropdownMenu">
                <li class="border-bottom border-secondary pb-1">
                    <div class="dropdown-item d-flex align-items-center profile">
                        <div class="position-relative">
                            <p-avatar [image]="photoUrl" styleClass="mt-2" shape="circle" size="large" id="img_HeaderMyProfile"></p-avatar>
                            <div class="custella-online"
                                [ngClass]="{'custella-online' : onlineStatus, 'custella-offline' : !onlineStatus}" id="img_HeaderMyProfileStatus">
                            </div>
                        </div>
                        <div class="m-1 p-2">
                            <span class="d-block text-12" id="value_HeaderMyProfileName">{{fullName}}</span>
                            <span class="text-muted text-12" id="value_HeaderMyProfileUserEmail">{{userEmail}}</span>
                        </div>
                    </div>
                </li>
                <div class="border-bottom border-secondary p-2">
                    <li>
                        <a class="dropdown-item px-1 py-2 text-12" routerLinkActive="active"
                            routerLink="/dashboard/setup/allUser/view" (click)="resetUserTab()" id="link_HeaderMyProfileAccess"><img
                                src="assets\svg\header\customer-white.svg"
                                class="me-1 w-24px" id="img_HeaderMyProfileAccess"/>{{words?.header.fields.myProfile.singular}}</a>
                    </li>
                    <!-- <li>
                        <p-panelMenu [model]="panelMenuItems"></p-panelMenu>
                    </li> -->
                    <!-- <li class="custella-lang">
                        <div class="custella-lang-menu accordion accordion-flush">
                            <div class="accordion-item">
                                <div class="accordion-header" id="language" (click)="$event.stopPropagation()">
                                    <button class="accordion-button collapsed justify-content-between px-1 py-2" data-bs-toggle="collapse" data-bs-target="#choose-lang" aria-expanded="false" aria-controls="choose-lang">
                                        <div>
                                            <img src="assets\svg\header\globe.svg" class="me-1 w-24px" />
                                            <span class="text-12 font-white" *ngIf="currentLang === 'English'">
                                                {{words?.header.fields.english.singular}}
                                            </span>

                                            <span class="text-12 font-white" *ngIf="currentLang === 'Malay'">
                                                {{words?.header.fields.malay.singular}}
                                            </span>

                                            <span class="text-12 font-white" *ngIf="currentLang === 'Chinese'">
                                                {{words?.header.fields.chinese.singular}}
                                            </span>
                                        </div>
                                        <i class="pi pi-angle-down" style="color: #e0e0e0;"></i>
                                    </button>

                                </div>
                                <ul class="p-0 m-0 accordion-collapse collapse"  id="choose-lang" (click)="$event.stopPropagation()">
                                    <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('en')" *ngIf="currentLang !== 'English'">
                                        <a class="px-1">
                                            <span class="text-12 font-white" style="padding-left: 1.75rem;">{{words?.header.fields.english.singular}}</span>
                                        </a>
                                    </li>
                                    <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('my')" *ngIf="currentLang !== 'Malay'">
                                        <a class="px-1">
                                            <span class="text-12 font-white" style="padding-left: 1.75rem;">{{words?.header.fields.malay.singular}}</span>
                                        </a>
                                    </li>
                                    <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('zh')" *ngIf="currentLang !== 'Chinese'">
                                        <a class="px-1">
                                            <span class="text-12 font-white" style="padding-left: 1.75rem;">{{words?.header.fields.chinese.singular}}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li> -->
                    <li>
                        <a *ngIf="onlineStatus" class="dropdown-item px-1 py-2 text-12 d-flex"
                            (click)="changeOnlineStatus(false)" id="link_HeaderMyProfileGoOffline">
                            <div class="custella-go-offline" id="img_HeaderMyProfileGoOffline"></div>{{words?.header.fields.goOffline.singular}}
                        </a>
                        <a *ngIf="!onlineStatus" class="dropdown-item px-1 py-2 text-12 d-flex"
                            (click)="changeOnlineStatus(true)" id="link_HeaderMyProfileGoOnline">
                            <div class="custella-go-online" id="img_HeaderMyProfileGoOffline"></div>{{words?.header.fields.goOnline.singular}}
                        </a>
                    </li>
                </div>
                <div class="p-2">
                    <!-- <li>
                        <a class="dropdown-item px-1 py-2 text-12" href="javascript:void(0)"><img src="assets\svg\header\phone.svg" class="me-1 w-24px" />Contact Support</a>
                    </li>
                    <li>
                        <a class="dropdown-item px-1 py-2 text-12" href="javascript:void(0)"><img src="assets\svg\header\question-circle.svg" class="me-1 w-24px" /><span class="ps-1">FAQ</span></a>
                    </li> -->
                    <li>
                        <a class="dropdown-item px-1 py-2" (click)="userLogout()" id="link_HeaderMyProfileLogOut"><img src="assets\svg\header\log-out.svg"
                                class="w-24px" /><span class="ps-1 text-12" id="label_HeaderMyProfileLogOut"
                                style="color: #ffb1b1;">{{words?.header.fields.logOut.singular}}</span></a>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</div>
<p-sidebar (onHide)="handleClick($event)" [(visible)]="visibleSidebarTask"
    [style]="{width:'calc(100% - 20px)', background:'#fafafa'}" position="right" [baseZIndex]="1000"
    [showCloseIcon]="showCloseIcon">
    <app-view-tasks *ngIf="newTaskId" [taskId]="newTaskId" showClose="no" (closeSlide)="closeSlide()"></app-view-tasks>
</p-sidebar>