<div class="">
    <div class="custella-modal d-flex flex-column">
        <div class="custella-modal-header">
            <div class="custella-modal-header-text" id="title_SetUserUploadPhoto">
                <span>{{ translate?.setup.users.teams.sections.sec8.singular  }}</span>
            </div>

            <i class="fa-regular fa-xmark pt-1"  [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="modalRef.hide()" id="btn_SetUserUploadPhotoClose"></i>
        </div>

        <div class="custella-modal-body">
            
            <div class="custella-content-row-input">
                <div class="col-md-12 d-flex justify-content-center">
                    <p-button type="button" icon="pi pi-upload" [label]="translate?.general.buttons.uploadImage.singular" id="btn_SetUserUploadImg" (onClick)="uploadFile()"></p-button>
                    <input type="file" (change)="onFileChange($event)" [hidden]="true" id="input_SetUserUploadImgField" id="uploaderFile"/>
                   </div>
                <div class="col-md-12" *ngIf="imgChangeEvt">
                <image-cropper *ngIf="type !== 'org'"
                    [imageChangedEvent]="imgChangeEvt" 
                    [imageBase64]="imageChangedEvent"
                    id="link_SetUserCropImgNonOrg"
                    [maintainAspectRatio]="true" 
                    [aspectRatio]="1/1"
                    [resizeToWidth]="400"
                    [roundCropper]="false"
                    [imageQuality]="100"
                    (imageCropped)="cropImg($event)" 
                    (imageLoaded)="imgLoad()"
                    (loadImageFailed)="imgFailed()"
                    [style.display]="cropperReady ? null : 'none'"
                    class="max-h-200px">
                </image-cropper>

                <image-cropper *ngIf="type === 'org'"
                [imageBase64]="imageChangedEvent"
                id="link_SetUserCropImgOrg"
                [maintainAspectRatio]="false"
                [aspectRatio]="1/1"
                [resizeToWidth]="400"
                [roundCropper]="false"
                [imageQuality]="100"
                (imageCropped)="cropImg($event)"
                (imageLoaded)="imgLoad()"
                (loadImageFailed)="imgFailed()"
                [style.display]="cropperReady ? null : 'none'"
                class="max-h-200px"
                ></image-cropper>

                </div>
                  <!-- <div class="col-md-4">
                    <h6>Image Preview</h6>
                    <img [src]="cropImgPreview" />
                  </div> -->
            </div>
        </div>

        <div class="custella-modal-footer d-flex align-items-center justify-content-end">
            <p-button [label]="translate?.general.buttons.save.singular " [disabled]="submitTouched" id="btn_SetUserUploadPhotoSubmit" (onClick)="submitForm()" type="button"></p-button>
        </div>
    </div>
</div>
