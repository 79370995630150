<div class="menu" *ngIf="this.addonFeature && !loading">
    <ul class="layout-menu" *ngIf="addonFeature.addonMS != 'MACHINE_SPECIALIST'">
        <div class="border-bottom py-2 mx-2">
            <li class=" my-05" ng-reflect-index="0" *ngIf="menuType === 'sales' || menuType === 'service'" (click)="restSomeData()">
                <a routerLinkActive="active-menuitem" class=" d-flex align-items-center" routerLink="home"
                    href="javascript:void(0)" id="title_MenuHomeSalesServices">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/home.svg" /><span class="menuText"
                        >{{translate?.dashboard?.label.singular}}</span>
                </a>
            </li>
            
        </div>
        <div class="border-bottom py-2 mx-2">
            <li class="my-05" *ngIf="tasks && (menuType === 'sales'|| menuType === 'service')"  (click)="restSomeData()">
                <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="tasks"
                    href="javascript:void(0)" id="title_MenuTask">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/task.svg" /><span class="menuText">{{translate?.task?.label.plural}}</span>
                </a>
            </li>
            <li class="my-05"
                *ngIf="(jobs && menuType === 'service' && servicePer) || (jobs && menuType === 'sales' && servicePer)" (click)="restSomeData()">
                <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="jobOrders"
                    href="javascript:void(0)" id="title_MenuJob">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/job-order.svg" /><span
                        class="menuText">{{translate?.jobOrder?.label.plural}}</span>
                </a>
            </li>
            <li class="my-05" *ngIf="customers">
                <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="customers" (click)="restSomeData()"
                    href="javascript:void(0)" id="title_MenuCust">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/customer.svg" /><span class="menuText">{{translate?.customer?.label.plural}}</span>
                </a>
            </li>
            <li class="my-05" *ngIf="site && (menuType === 'sales'|| menuType === 'service')"  (click)="restSomeData()">
                <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="sites"
                    href="javascript:void(0)" id="title_MenuSites">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/sites.svg" /><span class="menuText">{{translate?.site?.label.plural}}</span>
                </a>
            </li>
            <li class="my-05" *ngIf="project && (menuType === 'sales'|| menuType === 'service') && addonFeature.addonPROJECT == 'PROJECT'"  (click)="restSomeData()">
                <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="projects" href="javascript:void(0)" id="title_MenuProj">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/project.svg" /><span class="menuText">{{translate?.project?.label.plural}}</span>
                </a>
            </li>
        </div>
        <div class="border-bottom py-2 mx-2" *ngIf="(menuType === 'sales'|| menuType === 'service' && addonFeature.addonINVENTORY == 'INVENTORY') && (assets || checklistsFull || scheduledMaintenace || assetContracts)">
            <li class="my-05" *ngIf="checklistsFull">
                <a (click)="restSomeData()" routerLinkActive="active-menuitem" routerLink="checklist" href="javascript:void(0)"
                    class="d-flex align-items-center" (click)="restSomeData()"id="title_MenuChecklist">
                    <img class="menuIcon custella-short-icon" src="assets/svg/sidebar/productivity-icon.svg" /><span class="menuText">{{translate?.checklist?.label.plural}}</span>
                </a>
            </li>


            <li class="my-05" *ngIf="assets">
                <a (click)="restSomeData()" routerLinkActive="active-menuitem" routerLink="assets" href="javascript:void(0)"
                    class="d-flex align-items-center" (click)="restSomeData()"id="title_MenuAssets">
                    <img class="menuIcon custella-short-icon" src="assets/svg/sidebar/desktop.svg" /><span class="menuText">{{translate?.asset?.label.plural}}</span>
                </a>
            </li>


            <li class="my-05" *ngIf="scheduledMaintenace">
                <a (click)="restSomeData()" routerLinkActive="active-menuitem" routerLink="preventative-maintenance" href="javascript:void(0)"
                    class="d-flex align-items-center pm-h" id="title_MenuPm"> 
                    <img class="menuIcon custella-short-icon" src="assets/svg/sidebar/pm-light.svg" /><span class="pm menuText">{{translate?.preventiveMaintenance?.label.plural}}</span>
                </a>
            </li>


            <li class="my-05" *ngIf="assetContracts">
                <a (click)="restSomeData()" routerLinkActive="active-menuitem" routerLink="contracts" href="javascript:void(0)"
                    class="d-flex align-items-center" id="title_MenuContracts"> 
                    <img class="menuIcon custella-short-icon" src="assets/svg/sidebar/contracts-light.svg" /><span class="menuText">{{translate?.contracts?.label.plural}}</span>
                </a>
            </li>
          
        </div>
        <div class="border-bottom py-2 mx-2" *ngIf="(menuType === 'sales'|| menuType === 'service') && (inventory || products)">
            <li class="my-05"
                *ngIf="inventory && addonFeature.addonINVENTORY == 'INVENTORY'">
                <a (click)="restSomeData()" [ngClass]="{'active-menuitem':subMenu == 'inventory'}" class="d-flex align-items-center"
                    id="main-menu" href="javascript:void(0)" id="title_MenuInventory">
                    <img (click)="itemClick('inventory');openUrl('inventory')" class="custella-mini-icon  menuIcon"
                        src="assets/svg/sidebar/inventory-ic-1.svg" />
                    <span class="menuText w-100" (click)="itemClick('inventory');openUrl('inventory')">{{translate?.inventory?.label.singular}}</span>
                    <i *ngIf="subMenu != 'inventory' || !toggelMenu" (click)="collapsOut('inventory')"
                        class="pi pi-fw pi-angle-right ms-auto layout-submenu-toggler menuText"></i>
                    <i (click)="collapsIn('inventory')" *ngIf="subMenu == 'inventory' && toggelMenu"
                        class="pi pi-fw pi-angle-down ms-auto layout-submenu-toggler menuText"></i>
                </a>
                <ul *ngIf="subMenu == 'inventory'" class="subMenu" (click)="itemClick('inventory')"
                    [@children]="(subMenu == 'inventory' && toggelMenu ? 'visibleAnimated' : 'hiddenAnimated')">
                    <li class="my-05">
                        <a class="menu-list" href="javascript:void(0)" (click)="itemClick('inventory')"
                            routerLinkActive="active-menuitem" routerLink="inventory/bin" id="title_MenuBins">
                            <span class="menuSubText ">{{translate?.inventory.fields.bin.singular}}</span>
                        </a>
                    </li>
                    <li class="my-05">
                        <a class="menu-list" href="javascript:void(0)" routerLinkActive="active-menuitem"
                            routerLink="inventory/binTransfer" id="title_MenuBinTransfer">
                            <span class="menuSubText ">{{translate?.binTransfer?.label.singular}}</span>
                        </a>
                    </li>
                    <li class="my-05">
                        <a class="menu-list" href="javascript:void(0)" (click)="itemClick('inventory')"
                            routerLinkActive="active-menuitem" routerLink="inventory/history"
                            id="title_MenuInventoryHist">
                            <span class="menuSubText ">{{translate?.history?.label.singular}}</span>
                        </a>
                    </li>
                    <li class="my-05">
                        <a class="menu-list" href="javascript:void(0)" *ngIf="addonFeature.addonMAGNUM == 'MAGNUM'"
                            id="title_MenuMagnumConsign" (click)="itemClick('inventory')"
                            routerLinkActive="active-menuitem" routerLink="inventory/consignment">
                            <span class="menuSubText ">{{translate?.consignment?.label.singular}}</span>
                        </a>
                    </li>
                    <li class="my-05">
                        <a class="menu-list" href="javascript:void(0)" *ngIf="addonFeature.addonMAGNUM == 'MAGNUM'"
                            id="title_MenuMagnumRequisition" (click)="itemClick('inventory')"
                            routerLinkActive="active-menuitem" routerLink="inventory/requisition">
                            <span class="menuSubText ">{{translate?.requisition?.label.singular}}</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="my-05" *ngIf="products">
                <a id="main-menu"  (click)="restSomeData()" [ngClass]="{'active-menuitem':subMenu == 'products'}"  class="d-flex align-items-center" href="javascript:void(0)" id="title_MenuProduct">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/product-ic-1.svg"  (click)="itemClick('products');openUrl('products')"/><span
                        class="menuText w-100"  (click)="itemClick('products');openUrl('products');restSomeData()">{{translate?.product?.label.plural}}</span>
                    <i *ngIf="(subMenu != 'products' || !toggelMenu) && addonFeature.addonPRICEBOOK == 'PRICE_BOOK'" (click)="collapsOut('products')"
                        class="pi pi-fw pi-angle-right ms-auto layout-submenu-toggler menuText"></i>
                    <i (click)="collapsIn('products')" *ngIf="subMenu == 'products' && toggelMenu && addonFeature.addonPRICEBOOK === 'PRICE_BOOK'"
                        class="pi pi-fw pi-angle-down ms-auto layout-submenu-toggler menuText"></i>
                </a>
                <ul *ngIf="subMenu == 'products' && addonFeature.addonPRICEBOOK == 'PRICE_BOOK'" class="subMenu" (click)="itemClick('products')"
                    [@children]="(subMenu == 'products' && toggelMenu ? 'visibleAnimated' : 'hiddenAnimated')">
                    <li class="my-05">
                        <a class="menu-list" href="javascript:void(0)" (click)="itemClick('products');restSomeData()"
                            routerLinkActive="active-menuitem" routerLink="products/priceBook" id="title_MenuBins">
                            <span class="menuSubText ">{{translate?.priceBook?.label.plural}}</span>
                        </a>
                    </li>
                </ul>
            </li>
        </div>
        <div class="border-bottom py-2 mx-2" *ngIf="addonFeature.addonSLA == 'SLA' &&  (menuType === 'sales'|| menuType === 'service') && (helpdesk || tickets)">
            <li class="my-05" *ngIf="helpdesk">
                <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="helpdesk"
                    href="javascript:void(0)" id="title_MenuHelpdesk">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/helpdesk.svg" /><span
                        class="menuText" >{{translate?.helpdesk?.label.singular}}</span>
                </a>
            </li>
            <li class="my-05" *ngIf="tickets">
                <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="tickets"
                    href="javascript:void(0)" id="title_MenuTix">
                    <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/ticket.svg" /><span class="menuText">{{translate?.ticket?.label.plural}}</span>
                </a>
            </li>
        </div>
        <div class="py-2 mx-2">
            <li class="my-05" *ngIf="menuType === 'sales'|| menuType === 'service'">
                <a routerLinkActive="active-menuitem" routerLink="leave" href="javascript:void(0)"
                    class="d-flex align-items-center" id="title_MenuLeave">
                    <img class="custella-mini-icon" src="assets/svg/sidebar/leave.svg" /><span class="menuText"
                        >{{translate?.leave?.label.singular}}</span>
                </a>
            </li>
            <li class="my-05">
                <a routerLinkActive="active-menuitem" routerLink="activereports" href="javascript:void(0)"
                    class="d-flex align-items-center" id="title_MenuReports">
                    <img class="custella-mini-icon menuIcon" src="assets/svg/sidebar/report.svg" /><span
                        class="menuText">{{translate?.report?.label.singular}}</span>
                </a>
            </li>
        </div>

      
    </ul>
    
    <!-- BOTTOM MENU -->
    <ul class="bottom-menu"  *ngIf="addonFeature.addonMS != 'MACHINE_SPECIALIST'">
        <li [pTooltip]="translate?.setup?.label.singular" tooltipPosition="top" *ngIf="menuInactiveDesktop" class="setupIconswithDots">
            <span class="icon threeDots">
                <i class="fa-light fa-ellipsis-vertical fa-xl"></i>
            </span>
        </li>
        <li routerLink="setup" routerLinkActive="active-icon" (click)="goToSetup()" [pTooltip]="translate?.setup?.label.singular"
            tooltipPosition="top" [ngStyle]="{'display':!menuInactiveDesktop ? 'flex':'none'}" id="title_MenuSetup">  
            <span class="icon">
                <i class="fa-light fa-gear-complex fa-xl"></i>
            </span>
        </li>
        <li (click)="openItemToNewTab()" [pTooltip]="translate?.academy?.label.singular" tooltipPosition="top" [ngStyle]="{'display':!menuInactiveDesktop ? 'flex':'none'}" id="title_MenuAcademy">
            <span class="icon">
                <i class="fa-light fa-book-open-cover fa-xl"></i>
            </span>
        </li>
        <!-- <li  class="icon" [pTooltip]="'contact_us'" tooltipPosition="top" id="title_MenuContactUs">
            <span class="icon">
                <i class="fa-light fa-user-headset fa-xl"></i>
            </span>
        </li> -->
    </ul>
         





    <ul class="layout-menu" *ngIf="addonFeature.addonMS == 'MACHINE_SPECIALIST'">
        <li class=" my-05" ng-reflect-index="0"  *ngIf="menuType === 'sales' || menuType === 'service'" (click)="restSomeData()">
            <a routerLinkActive="active-menuitem" class=" d-flex align-items-center" routerLink="home"  href="javascript:void(0)" id="title_MenuHomeSalesServices">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/home.svg" /><span
                    class="menuText" >{{translate?.dashboard?.label.singular}}</span>
            </a>
        </li>
        <li class="my-05" *ngIf="attendance &&  addonFeature.addonMS == 'MACHINE_SPECIALIST' && (menuType === 'sales'|| menuType === 'service')">
            <a routerLinkActive="active-menuitem" routerLink="attendance" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuMsAttend"  (click)="restSomeData()">
                <img class="menuIcon custella-short-icon"  src="assets/svg/sidebar/attendance-icon.svg" /><span class="menuText" >{{translate?.attendance?.label.singular}}</span>
            </a>
        </li>
        
        <li class="my-05" *ngIf="overtime && addonFeature.addonMS == 'MACHINE_SPECIALIST' && (menuType === 'sales'|| menuType === 'service')">
            <a routerLinkActive="active-menuitem" routerLink="overtime" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuMsOvertime"  (click)="restSomeData()">
                <img class="menuIcon custella-short-icon"  src="assets/svg/sidebar/overtime-icon.svg" /><span class="menuText" >{{translate?.overtime?.label.singular}}</span>
            </a>
        </li>
        <li class="my-05" *ngIf="productivity && addonFeature.addonMS == 'MACHINE_SPECIALIST' && (menuType === 'sales'|| menuType === 'service')">
            <a routerLinkActive="active-menuitem" routerLink="productivity" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuMsProductivity"  (click)="restSomeData()">
                <img class="menuIcon custella-short-icon"  src="assets/svg/sidebar/productivity-icon.svg" /><span class="menuText" >{{translate?.productivity?.label.singular}}</span>
            </a>
        </li>
        <li class="my-05" *ngIf="pmv && addonFeature.addonMS == 'MACHINE_SPECIALIST' && (menuType === 'sales'|| menuType === 'service')">
            <a routerLinkActive="active-menuitem" routerLink="pmv" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuMsPmv"  (click)="restSomeData()">
                <img class="menuIcon custella-short-icon"  src="assets/svg/sidebar/pmv-icon.svg" /><span class="menuText" >{{translate?.pmv?.label.singular}}</span>
            </a>
        </li>
        
        <li class="my-05" *ngIf="visits && addonFeature.addonMS == 'MACHINE_SPECIALIST' && (menuType === 'sales'|| menuType === 'service')">
            <a routerLinkActive="active-menuitem" routerLink="visits" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuMsVisits"  (click)="restSomeData()">
                <img class="menuIcon custella-short-icon" src="assets/svg/sidebar/visits-icon.svg" /><span class="menuText" >{{translate?.visits?.label.singular}}</span>
            </a>
        </li>
        <li class="my-05" *ngIf="menuType === 'sales'|| menuType === 'service'">
            <a routerLinkActive="active-menuitem"  routerLink="leave" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuLeave">
                <img class="custella-mini-icon" src="assets/svg/sidebar/leave.svg" /><span class="menuText">{{translate?.leave?.label.plural}}</span>
            </a>
        </li>
    
    
   
        <li class="my-05" *ngIf="customers">
            <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="customers" href="javascript:void(0)" (click)="restSomeData()" id="title_MenuCust">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/customer.svg" /><span
                    class="menuText">{{translate?.customer?.label.plural}}</span>
            </a>
        </li>
        <li class="my-05" *ngIf="site && (menuType === 'sales'|| menuType === 'service')" (click)="restSomeData()">
            <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="sites" href="javascript:void(0)" id="title_MenuSites">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/sites.svg" /><span
                    class="menuText">{{translate?.site?.label.plural}}</span>
            </a>
        </li>
    
    
        <li class="my-05" *ngIf="tickets && addonFeature.addonSLA == 'SLA' && (menuType === 'sales'|| menuType === 'service')" (click)="restSomeData()">
            <a routerLinkActive="active-menuitem"  class="d-flex align-items-center" routerLink="tickets" href="javascript:void(0)" id="title_MenuTix">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/ticket.svg" /><span
                    class="menuText">{{translate?.ticket?.label.plural}}</span>
            </a>
        </li>
    
        <li class="my-05" *ngIf="project && (menuType === 'sales'|| menuType === 'service') && addonFeature.addonPROJECT == 'PROJECT'" (click)="restSomeData()">
            <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="projects" href="javascript:void(0)" id="title_MenuProj">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/project.svg" /><span
                    class="menuText">{{translate?.project?.label.plural}}</span>
            </a>
        </li>
        <!-- <li class="my-05"  *ngIf="(sales && menuType === 'sales' && salePer) || (sales && menuType === 'service' && salePer) || addonFeature.addonSWAPASIA == 'SWAP_ASIA'">
            <a routerLinkActive="active-menuitem"  routerLink="salesOrders" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuSales" (click)="resetItems()">
                <img class="custella-mini-icon " src="assets/svg/sidebar/sales-order.svg" /><span
                    class="menuText" >{{sales_orders}}</span>
            </a>
        </li> -->
        <li class="my-05" (click)="restSomeData()" *ngIf="assets && addonFeature.addonINVENTORY == 'INVENTORY' && (menuType === 'sales'|| menuType === 'service')">
            <a id="main-menu" [ngClass]="{'active-menuitem':subMenu == 'assets'}" href="javascript:void(0)"
                class="d-flex align-items-center"  id="title_MenuAssets">
                <img (click)="itemClick('assets');openUrl('assets')" class="custella-mini-icon  menuIcon"
                    src="assets/svg/sidebar/desktop.svg" /><span class="menuText w-100"
                    (click)="itemClick('assets');openUrl('assets')">{{translate?.asset?.label.plural}}</span>

                <i *ngIf="subMenu != 'assets' || !toggelMenu" (click)="collapsOut('assets')"
                    class="pi pi-fw pi-angle-right ms-auto layout-submenu-toggler menuText"></i>
                <i (click)="collapsIn('assets')" *ngIf="subMenu == 'assets' && toggelMenu"
                    class="pi pi-fw pi-angle-down ms-auto layout-submenu-toggler menuText"></i>
            </a>
            <ul *ngIf="subMenu == 'assets'" (click)="itemClick('assets')" [ngClass]="{'custella-profile dropdown-menu dropdown-collaps':collapseNav}" [@children]="(subMenu == 'assets' && toggelMenu ? 'visibleAnimated' : 'hiddenAnimated')">
                <li class="my-05" *ngIf="scheduledMaintenace">
                    <a class="menu-list h-40px" href="javascript:void(0)" routerLinkActive="active-menuitem" routerLink="assets/preventative-maintenance" id="title_MenuPm">
                        <span class="menuSubText  d-block top-0">{{translate?.preventiveMaintenance?.label.plural}}</span>
                    </a>
                </li>
                <li class="my-05" *ngIf="assetContracts">
                    <a class="menu-list" href="javascript:void(0)" routerLinkActive="active-menuitem" routerLink="assets/contracts" id="title_MenuContarct">
                        <span class="menuSubText ">{{translate?.contracts?.label.plural}}</span>
                    </a>
                </li>
            </ul>
        </li>
        <li class="my-05" *ngIf="inventory && addonFeature.addonINVENTORY == 'INVENTORY' && (menuType === 'sales'|| menuType === 'service')">
            <a  (click)="restSomeData()" [ngClass]="{'active-menuitem':subMenu == 'inventory'}"   class="d-flex align-items-center" id="main-menu" href="javascript:void(0)" id="title_MenuInventory">
                <img (click)="itemClick('inventory');openUrl('inventory')" class="custella-mini-icon  menuIcon"
                    src="assets/svg/sidebar/inventory-ic-1.svg" />
                <span class="menuText w-100" (click)="itemClick('inventory');openUrl('inventory')">{{translate?.inventory?.label.singular}}</span>
                <i *ngIf="subMenu != 'inventory' || !toggelMenu" (click)="collapsOut('inventory')" class="pi pi-fw pi-angle-right ms-auto layout-submenu-toggler menuText"></i>
                <i (click)="collapsIn('inventory')" *ngIf="subMenu == 'inventory' && toggelMenu" class="pi pi-fw pi-angle-down ms-auto layout-submenu-toggler menuText"></i>
            </a>
            <ul *ngIf="subMenu == 'inventory'" class="subMenu" (click)="itemClick('inventory')" [@children]="(subMenu == 'inventory' && toggelMenu ? 'visibleAnimated' : 'hiddenAnimated')">
                <li class="my-05">
                    <a class="menu-list" href="javascript:void(0)" routerLinkActive="active-menuitem"   routerLink="inventory/binTransfer" id="title_MenuBinTransfer">
                        <span class="menuSubText " >{{translate?.binTransfer?.label.singular}}</span>
                    </a>
                </li>
                <li class="my-05">
                    <a class="menu-list" href="javascript:void(0)" (click)="itemClick('inventory')" routerLinkActive="active-menuitem"  routerLink="inventory/bin" id="title_MenuBins">
                        <span class="menuSubText " >{{translate?.bin?.label.singular}}</span>
                    </a>
                </li>
                <li class="my-05">
                    <a class="menu-list" href="javascript:void(0)" (click)="itemClick('inventory')" routerLinkActive="active-menuitem" routerLink="inventory/history" id="title_MenuInventoryHist">
                        <span class="menuSubText ">{{translate?.history?.label.singular}}</span>
                    </a>
                </li>
                <li class="my-05">
                    <a class="menu-list" href="javascript:void(0)" *ngIf="addonFeature.addonMAGNUM == 'MAGNUM'" id="title_MenuMagnumConsign" (click)="itemClick('inventory')" routerLinkActive="active-menuitem"  routerLink="inventory/consignment">
                        <span class="menuSubText ">{{translate?.consignment?.label.singular}}</span>
                    </a>
                </li>
                <li class="my-05">
                    <a class="menu-list" href="javascript:void(0)" *ngIf="addonFeature.addonMAGNUM == 'MAGNUM'" id="title_MenuMagnumRequisition" (click)="itemClick('inventory')" routerLinkActive="active-menuitem"  routerLink="inventory/requisition">
                        <span class="menuSubText ">{{translate?.requisition?.label.singular}}</span>
                    </a>
                </li>
            </ul>
        </li>
    
        
    
        <li class="my-05"  *ngIf="helpdesk && addonFeature.addonSLA == 'SLA' && (menuType === 'sales'|| menuType === 'service')" (click)="restSomeData()">
            <a routerLinkActive="active-menuitem"  class="d-flex align-items-center" routerLink="helpdesk" href="javascript:void(0)" id="title_MenuHelpdesk">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/helpdesk.svg" /><span
                    class="menuText" >{{translate?.helpdesk?.label.singular}}</span>
            </a>
        </li>
        
    
        <li class="my-05" *ngIf="machine_specialist && addonFeature.addonMS == 'MACHINE_SPECIALIST' && (menuType === 'sales'|| menuType === 'service')">
            <a routerLinkActive="active-menuitem" routerLink="machines" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuMsMachines"  (click)="restSomeData()">
                <img class="menuIcon custella-short-icon"  src="assets/svg/sidebar/ms-icon.svg" /><span class="menuText text-nowrap" >{{translate?.machineSpecialist?.label.singular}}</span>
            </a>
        </li>
    
        <li class="my-05" *ngIf="(jobs && menuType === 'service' && servicePer) || (jobs && menuType === 'sales' && servicePer)">
            <a routerLinkActive="active-menuitem" class="d-flex align-items-center" routerLink="jobOrders" (click)="restSomeData()"
                href="javascript:void(0)" id="title_MenuJob">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/job-order.svg" /><span
                    class="menuText"  >{{translate?.jobOrder?.label.singular}}</span>
            </a>
        </li>
        <li class="my-05" *ngIf="tasks && (menuType === 'sales'|| menuType === 'service')" (click)="restSomeData()">
            <a routerLinkActive="active-menuitem"  class="d-flex align-items-center" routerLink="tasks" href="javascript:void(0)" id="title_MenuTask">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/task.svg" /><span
                    class="menuText" >{{translate?.task?.label.singular}}</span>
            </a>
        </li>
        
        <!-- <li class="my-05" *ngIf="teams && (menuType === 'sales'|| menuType === 'service')">
            <a routerLinkActive="active-menuitem"  routerLink="teams" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuTeam">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/team.svg" /><span
                    class="menuText" >{{teams}}</span>
            </a>
        </li> -->

        <li class="my-05" *ngIf="products && (menuType === 'sales'|| menuType === 'service')"  (click)="restSomeData()">
            <a routerLinkActive="active-menuitem"   class="d-flex align-items-center" routerLink="products" href="javascript:void(0)" id="title_MenuProduct">
                <img class="custella-mini-icon  menuIcon" src="assets/svg/sidebar/product-ic-1.svg" /><span
                    class="menuText">{{translate?.product?.label.plural}}</span>
            </a>
        </li>
        <li class="my-05">
            <a routerLinkActive="active-menuitem"   routerLink="messages" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuMessages">
                <img class="custella-mini-icon" src="assets/svg/sidebar/comments.svg" /><span class="menuText" >{{translate?.message?.label.plural}}</span>
            </a>
        </li>
        <li class="my-05">
            <a routerLinkActive="active-menuitem"   routerLink="activereports" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuReports">
                <img class="custella-mini-icon menuIcon" src="assets/svg/sidebar/report.svg" /><span class="menuText">{{translate?.report?.label.plural}}</span>
            </a>
        </li>
        <li class="my-05" *ngIf="setup">
            <a routerLinkActive="active-menuitem"  routerLink="setup" (click)="goToSetup()" href="javascript:void(0)" class="d-flex align-items-center" id="title_MenuSetup">
                <img class="custella-mini-icon" src="assets/svg/sidebar/setup.svg" /><span class="menuText">{{translate?.setup?.label.singular}}</span>
            </a>
        </li>
        <li class="my-05">
                <a (click)="openItemToNewTab()" href="javascript:void(0)" class="d-flex align-items-center">
                    <img class="custella-mini-icon " src="assets/svg/sidebar/customer.svg" /><span class="menuText"
                        >{{translate?.academy?.label.singular}}</span>
                </a>
            </li>
    </ul> 
  
</div>