<ag-grid-angular
#agGrid
class="ag-theme-balham h-100"

[columnDefs]="columnDefs"
[gridOptions]="gridOptions"
[defaultColDef]="defaultColDef"
[maxConcurrentDatasourceRequests]="-1"
[suppressDragLeaveHidesColumns]="true"
(cellClicked)="selectCustomer($event)"
(gridReady)="onGridReady($event)"
[overlayNoRowsTemplate]="overlayNoRowsTemplate"
[getContextMenuItems]="getContextMenuItems"
></ag-grid-angular>
<div class="col-12"><p class="ag-record-row-summary-panel padds text-end">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p></div>
