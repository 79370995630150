<form (ngSubmit)="onSubmit()" >
  <div class="pt-2 pr-3">
    <!-- <h5 class="modal-title"><i class="mr-4 custella-modal-icon icon-profile"></i>{{title}}</h5> -->
    <button type="button" [pTooltip]="translate?.general.buttons.close.singular" class="close" id="btn_DynamicDataClose" (click)="closeDeleteModel()">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container text-center">       
        <img class="confirm-icon my-3" src="../../../../../assets/images/confirm/delete_icon.png" alt="">              
        <p id="label_DynamicDataConfirm">{{translate?.general.messages.confirmAction}}</p>
    </div>
  </div>
  <div class="modal-footer d-block">
      <div class="row p-0">
        <div class="col-6 text-center">
          <button type="submit" class="btn no-bk mauto d-flex align-items-center px-6">
            <img class="acc-dec-icon mr-3" src="../../../../assets/images/accepted.png" alt="">
            <p class="mb-0" id="btn_DynamicDataYes">{{translate?.general.buttons.yes.singular}}</p>
          </button>
        </div>
        <div class="col-6 border-right text-center">
          <button type="button" (click)="closeDeleteModel()" class="btn no-bk mauto d-flex align-items-center px-6">
              <img class="acc-dec-icon mr-3" src="../../../../assets/images/declined.png" alt="">
              <p class="mb-0" id="btn_DynamicDataNo">{{translate?.general.buttons.no.singular}}</p>
          </button>
        </div>
      </div>
    </div>
</form>
        