<div class="custella-form-container-content">  
  <!-- Billing Address -->
        <div class="custella-content-row p-0">
        <div class="custella-content-row-input col-12">
            <input type="text" pInputText autocomplete="off" class="w-100-110px" id="input_SearGoogAddressField" (keyup)="getPlaceApiCall($event)" (change)="manualSearch()" [ngModelOptions]="{standalone: true}"  [placeholder]="translate?.general.messages.searchAddress" autocorrect="off" autocapitalize="off" spellcheck="off" #input_SearGoogAddressField [(ngModel)]="homeLocation">
          
            <p-accordion (onOpen)="toggle($event)" (onClose)="toggle($event)">
                <p-accordionTab [transitionOptions]="'0ms'" [(selected)]="activeState">
                    <p-header>
                        <span #header id="btn_SearGoogAddressMap">
                            {{translate?.general.buttons.showMap.singular}}
                        </span>
                    </p-header>
                    <p-gmap #gmap id="gmap"  (onOverlayDragEnd)="markerDragEnd($event)" (onMapClick)="mapClicked($event, 'home')"  [overlays]="overlays" [options]="options" [style]="{'width':'calc(100% - 3px)','height':'320px', 'margin-left':'3px', 'border': '1px solid #e2e2e2'}"></p-gmap>
                </p-accordionTab>
            </p-accordion>
        </div>
        </div>
        <div class="custella-content-row p-0">
        <div class="custella-content-row-input col-12">
            <p class="text-10 p-error" *ngIf="errorMessage" id="label_SearGoogStreetErrMsg">{{errorMessage}}</p>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogStreet">{{ translate?.general.locationDetails.fields.street.singular }}</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogStreetField" [(ngModel)]="loadingBay.street">
            </div>  
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogCity">{{ translate?.general.locationDetails.fields.city.singular }}</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogCityField" [(ngModel)]="loadingBay.city"  class="w-100">
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogState">{{ translate?.general.locationDetails.fields.state.singular }}</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogStateField" [(ngModel)]="loadingBay.state" class="w-100">
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogPostcode">{{ translate?.general.locationDetails.fields.postcode.singular }}</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogPostcodeField" [(ngModel)]="loadingBay.postCode">
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogCountry">{{ translate?.general.locationDetails.fields.country.singular }}</span>
                <!-- <input type="text" pInputText autocomplete="off" id="input_SearGoogCountryField" [(ngModel)]="loadingBay.country" class="w-100"> -->
                <p-dropdown
                class="w-100"
                [(ngModel)]="loadingBay.country"
                name="country"
                [options]="countries"
                filterBy="name"
                optionLabel="name"
                optionValue="name"
                [filter]="true"
                [showClear]="true"
                [placeholder]="translate?.general.messages.none"
                id="input_SearGoogCountryField"
            ></p-dropdown>
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogLat">{{ translate?.general.locationDetails.fields.latitude.singular }}</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogLatField"  [(ngModel)]="loadingBay.locationLat" [readonly]="readOnlyFields.latitude">
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogLong">{{ translate?.general.locationDetails.fields.longitude.singular }}</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogLongField" [(ngModel)]="loadingBay.locationLong" [readonly]="readOnlyFields.longitude">
            </div>
            <div class="custella-content-row-input me-0 mt-18px" *ngIf="showLocateBtn">
                <div class="custella-content-row-input col-12">
                     <button type="button" class="custella-btn-long" id="btn_SearGoogLocate" (click)="manualAddressSearch()">
                        <span class="text-12" id="label_SearGoogLocate">{{translate?.general.buttons.locate.singular}}</span>
                    </button>
                </div>
            </div>
          </div>
     </div>
</div>

