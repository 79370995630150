<div class="custella-modal">
    <!-- Container -->
    <form>
        <div class="custella-modal-header align-items-center">
            <div class="custella-modal-header-text" id="title_WorkflowAddNotification">{{translate?.setup.workflows.sections.sec7.singular }} </div>
            <i class="pi pi-times" id="btn_SetWorkflowAddNotificationClose"  [pTooltip]="translate?.general?.buttons?.close.singular" tooltipPosition="left"  (click)="modalRef.hide()"></i>
        </div>

        <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3 height-fit-content">
           
             <form [formGroup]="addNotificationForm">
                <div class="custella-form-container-content m-0">
                    <div class="custella-content-row pe-0">
                        <div class="custella-content-row-input col-12" >
                            <p-multiSelect 
                            [placeholder]="translate?.setup?.workflows?.messages.addExistingNotification"
                            [options]="filteredWorkflowData"
                            [(ngModel)]="selectedNotification" 
                            optionLabel="name"  
                            display="chip"
                            formControlName="name"
                            id="picklist_SetWorkflowAddNotificationField"></p-multiSelect>
                            <div class="alert col px-0 py-0 text-left" *ngIf="addNotificationForm.controls['name'].touched && addNotificationForm.controls['name'].invalid">
                                <span class="text-10 p-error" *ngIf="addNotificationForm.controls['name'].errors?.required" id="label_SetWorkflowAddNotificationReqField" >{{translate?.general.validationFE.requiredField}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="custella-content-row mb-2 mt-0 ms-1">
                        <div class="custella-content-row-input d-flex col-12" >
                            <span class="custella-content-row-input-title-two" id="label_SetWorkflowNoNotification" >{{translate?.setup.workflows.messages.haveNotCreateNotification }}</span>
                        </div>
                    </div>
                </div>
            </form>
            <!-- Container Footer -->
            <div class="custella-form-container-footer flex-between mb-0">
                <div class="flex-between">
                    <span>
                        <p-button *ngIf="data.typeMode == 'Email'" [label]="translate?.general?.buttons?.createNewEmailNotification?.singular" id="btn_SetWorkflowAddNotificationSubmit" (onClick)="newNotification()" styleClass="h-32"></p-button>
                        <p-button *ngIf="data.typeMode == 'InApp'" [label]="translate?.general?.buttons?.createNewAppNotification?.singular" id="btn_SetWorkflowAddNotificationSubmit" (onClick)="newNotification()" styleClass="h-32"></p-button>
                    </span>
                    <div class="custella-content-row-input d-flex ms-2" *ngIf="data.typeMode == 'Email'">
                        <span class="custella-content-row-input-title-three" id="label_SetWorkflowCreateEmailNotification" >{{translate?.setup.workflows.messages.forEmailNotification }}&nbsp;</span>
                        <span class="custella-content-row-input-title-three-link" id="label_SetWorkflowCreateEmailNotificationHere"  (click)="newEmailTemplate()" >{{translate?.setup.workflows.messages.here }}</span>
                        <span class="custella-content-row-input-title-three" id="label_SetWorkflowCreateEmailNotificationFirst" >&nbsp;{{translate?.setup.workflows.messages.first }}</span>
                    </div>
                </div>

                <div class="flex-align-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" id="btn_SetWorkflowAddNotificationCancel" (click)="modalRef.hide()">{{translate?.general?.buttons?.cancel.singular}}</button>
                    <p-button [label]="translate?.general?.buttons?.save.singular" id="btn_SetWorkflowAddNotificationSubmit" styleClass="ms-2 h-32" (onClick)="onSubmitForm()" ></p-button>
                </div>
            </div>
        </div>



    </form>
</div>