export const environment = {
  // --- HRM UAT -- //
  version: '4.3.1',
  production: false,
  hmr: false,
  base_url: 'https://trial-api.fieldex.com/custella-0.0.1-SNAPSHOT/api',
  webSocket_url: 'https://trial-api.fieldex.com/custella-0.0.1-SNAPSHOT/ws?token=',
  sf_url: 'https://trial-api.fieldex.com/salesforce/connect',
  google_calender_url: 'https://trial-api.fieldex.com/google-calender/connect',
  node_url: 'https://trial-node.fieldex.com:3000/api',
  magnum_node_url: 'https://trial-node.fieldex.com:4000/api',
  active_report_url: 'https://trial-report.fieldex.com/integration?token=',
  touchandgo_node_url: "https://trial-node.fieldex.com:6001/api",
  coffex_node_url: "https://trial-node.fieldex.com:5004/api",
  multiTenant_node_url: "https://trial-node.fieldex.com:3008/api",
  agribio_node_url: "https://trial-node.fieldex.com:3008/api",
  active_report_dashboard_url:'https://trial-report.fieldex.com',
  ms_base_url: 'https://trial-api.fieldex.com/custella-0.0.1-SNAPSHOT/api/ms',
  ms_node_base_url:"https://trial-api.fieldex.com/machine-0.0.1-SNAPSHOT/api/",
  ai_node_url: 'https://trial-node.fieldex.com:3018/api',
  ai_base_url: 'https://dev-ai.fieldex.com',
  environment_name: 'trial',
  
};