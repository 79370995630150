import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class JobOrdersService {
  public refreshList$: EventEmitter<any>;
  public refreshTemplateList$: EventEmitter<any>;
  public refreshJOTemplateProductList$: EventEmitter<any>;

  private ENDPOINT_FILTER_JOB_ORDERS = "/v4-1/job-orders";
  private ENDPOINT_ALL_JOB_ORDERS = "/v4/job-orders";
  private ENDPOINT_JOB_ORDER_DELETE = "/job-orders/";
  
  private ENDPOINT_JOB_ORDERS_BY_ID = "/v4/job-orders/";
  private ENDPOINT_JOB_ORDER_PRODUCT_STATUSES = "/job-order-product-statuses";
  private ENDPOINT_JOB_ORDER_TYPES = "/job-order-types";
  private ENDPOINT_UPLOAD_JO_ATTACHMENTS =
    "/attachments/upload?parentType=JO_ATTACHENT&parentId=";
  private ENDPOINT_DELETE_JO_ATTACHMENTS = "/attachments/";
  private ENDPOINT_PART_ORDER_GET_ALL = "/part-orders/get-all?jobOrderId=";
  private ENDPOINT_JOB_ORDER_PRODUCTS_BY_ID = "/v4/job-order-products/"
  private ENDPOINT_JOB_ORDER_ATTACHMENTS_BY_ID = "/v4/attachments/job-order/"
  private ENDPOINT_GET_JOB_ORDER_TOTAL_TIME_DURATION = "/v4/job-orders/duration-spent"

  private ENDPOINT_JOB_ORDERS_TEMPLATES = "/ms/v1/tmpl-job-orders";
  private ENDPOINT_JOB_ORDERS_TEMPLATES_PRODUCT = "/ms/v1/tmpl-job-order-products";

  private jobSelectSubject = new Subject<any>();
  public refresherPickList$: EventEmitter<any>;

  private externalJODATA: any;


  constructor(
    private http: HttpClient,
    private httpOld: Http,
    private auth: AuthenticationService
  ) {
    this.refresherPickList$ = new EventEmitter();
    this.refreshList$ = new EventEmitter();
    this.refreshTemplateList$ = new EventEmitter();
    this.refreshJOTemplateProductList$ = new EventEmitter();
  }

  /******************************************** set item select ******************************************/
  public setSelectItem(val: any) {
    this.jobSelectSubject.next(val);
  }

  /******************************************** get job order after select ******************************************/
  public getSelectJobOrder() {
    return this.jobSelectSubject.asObservable();
  }

  /******************************************** get list column for job order ******************************************/
  public getListColumn(apiModule: any) {
    return this.http
      .get(
        environment.base_url +
          "/table-column-setups/by-module?apiModule=" +
          apiModule
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get job order status ******************************************/
  public getJobOrderStatusesByType(jobOrderTypeId:any = null) {
    let params = new HttpParams();
    params = params.set("profileBasedFilter", true);

    if (jobOrderTypeId) {
      params = params.set("jobOrderTypeId", jobOrderTypeId);
      
    }
    return this.http.get(environment.base_url + "/v4/job-order-statuses",{ params: params }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public getJobOrderStatuses() {
    return this.http.get(environment.base_url + "/v4/job-order-statuses").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public createJOStatus(body: any) {
    return this.http
      .post(environment.base_url + "/job-order-statuses", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateJOStatus(body: any) {
    return this.http
      .put(environment.base_url + "/job-order-statuses", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getJOStatusById(id: any) {
    return this.http
      .get(environment.base_url + "/job-order-statuses/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteJOStatus(id: any) {
    return this.http
      .delete(environment.base_url + "/job-order-statuses/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted JO Status");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

    // This Method Not in Use
  /******************************************** get all job orders ******************************************/
  public getAllJobOrders(params: any, searchValue: any) {
    let startFrom = params.request.startRow;
    let sortOrder;

    if (params.request.sortModel.length >= 1) {
      if (params.request.sortModel[0].sort == "desc") {
        sortOrder = "asc"; //reverse the value bcoz default send 'DESC'
      } else {
        sortOrder = "desc";
      }
    } else {
      sortOrder = "desc";
    }

    var sortName =
      params.request.sortModel.length >= 1
        ? params.request.sortModel[0].colId
        : "jobOrderNumber";
    return this.http
      .get(
        environment.base_url +
          "/v4/job-orders?startFrom=" +
          startFrom +
          "&perPage=25&search=" +
          searchValue +
          "&columnName=" +
          sortName +
          "&sortOrder=" +
          sortOrder
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get job order 2 ******************************************/
  public getAllJobOrders_2(
    startFrom: any,
    perPage: any,
    fromDate: any,
    toDate: any,
    status: any,
    customerId: any,
    salesOrderId: any,
    search: any,
    parameters: any,
    origin: any,
    assetsId: any,
    checkListRecordId: any,
    pmvId: any
  ) {
    let params = new HttpParams();
    params = params.set("startFrom", startFrom);

    if (perPage) {
      params = params.set("perPage", perPage);
    }

    if (fromDate !== "" && toDate !== "") {
      params = params.set("fromDate", fromDate).set("toDate", toDate);
    }

    if (status !== "" && status !== null) {
      params = params.set("status", status);
    }

    if (customerId !== "" && customerId != null) {
      params = params.set("customerId", customerId);
    }

    if (salesOrderId !== "" && salesOrderId != null) {
      params = params.set("salesOrderId", salesOrderId);
    }

    if (fromDate !== "" && toDate !== "") {
      params = params.set("fromDate", fromDate).set("toDate", toDate);
    }

    if (assetsId !== "" && assetsId != null) {
      params = params.set("assetsId", assetsId);
    }

    if (origin !== "" && origin != null) {
      params = params.set("origin", origin);
    }

    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (checkListRecordId !== "" && checkListRecordId !== null) {
      params = params.set("checklistRecordId", checkListRecordId);
    }
    if (pmvId !== "" && pmvId !== null) {
      params = params.set("pmvId", pmvId);
    }

    if (parameters) {
      params = params
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    }

    return this.http
      .get(environment.base_url + this.ENDPOINT_FILTER_JOB_ORDERS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** change jo status ******************************************/
  public changeJOStatus(joId: any, status: any) {
    var body = new FormData();
    return this.http
      .post(
        environment.base_url + "/job-orders/" + joId + "/to-status/" + status,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get customers for job order ******************************************/
  public getCustomersForJobOrderPicklist() {
    return this.http
      .get(`${environment.base_url}/v2/job-orders/customer-list`)
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** create job order ******************************************/
  public createJobOrder(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_ALL_JOB_ORDERS, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  

  /******************************************** edit job order ******************************************/
  public editJobOrder(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_ALL_JOB_ORDERS, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** delete job order ******************************************/
  public deleteJobOrder(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_JOB_ORDER_DELETE + id, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** get job order by id ******************************************/
  public getJobOrderById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOB_ORDERS_BY_ID + id)
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

   /******************************************** get job order products by id ******************************************/
   public getJobOrderProductsById(id: any) {
    let params = new HttpParams();
    params = params.set("jobOrderId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOB_ORDER_PRODUCTS_BY_ID,{
        params : params
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

   /******************************************** get job order Attachments by id ******************************************/
   public getJobOrderAttachmentsById(id: any) {
    let params = new HttpParams();
    params = params.set("jobOrderId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOB_ORDER_ATTACHMENTS_BY_ID,{
        params : params
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }


   /******************************************** get job order Total Time Duration by id ******************************************/
   public getJobOrderTimeDurationById(id: any) {
    let params = new HttpParams();
    params = params.set("jobOrderId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_JOB_ORDER_TOTAL_TIME_DURATION,{
        params : params
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** get job order product status ******************************************/
  public getJobOrderProductStatuses() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOB_ORDER_PRODUCT_STATUSES)
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** get job order types ******************************************/
  public getJobOrderTypes(active: any = null) {
    var active1=''
    if(active){ active1='?isActive=true'}
    return this.http.get(environment.base_url + this.ENDPOINT_JOB_ORDER_TYPES+active1)
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  public createJobOrderTypes(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_JOB_ORDER_TYPES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateJobOrderTypes(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_JOB_ORDER_TYPES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getJobOrderTypesById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOB_ORDER_TYPES + "/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteJobOrderTypes(id: any) {

    return this.http
      .delete(environment.base_url + this.ENDPOINT_JOB_ORDER_TYPES + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted JO Types");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /******************************************** get fault data ******************************************/
  public getFaultsData(name: any) {
    //return this.http.get( environment.base_url+'/fault-mappings/filter?systemCategoryId='+id).map( (resp:any) => {
    let params = new HttpParams();
    console.log("systemCategoryName ==", name);
    if (name !== "" && name !== null) {
      params = params.set("systemCategoryName", name);
    }
    return this.http
      .get(environment.base_url + "/fault-mappings/filter", { params: params })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** get resolution data ******************************************/
  public getResolutionData(name: any) {
    // return this.http.get( environment.base_url+'/resolution-mappings/filter?systemCategoryId='+id).map( (resp:any) => {
    let params = new HttpParams();
    console.log("systemCategoryName ==", name);
    if (name !== ""  && name !== null) {
      params = params.set("systemCategoryName", name);
    }
    return this.http
      .get(environment.base_url + "/resolution-mappings/filter", {
        params: params,
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

    /******************************************** get resolution custom data ******************************************/
    public getCustomResolutionData(arrayWithItemSystemandFaultId: any[] = []) {
      return this.http
        .post(environment.base_url + "/resolution-mappings/resolutions/filter", arrayWithItemSystemandFaultId)
        .pipe(
          map(
            (resp: any) => {
              return resp;
            },
            catchError((error) => {
              //console.log(error);
              return throwError(error);
            })
          )
        );
    }

  /******************************************** search job order ******************************************/
  public searchJobOrder(searchValue: any, parameters: any) {
    let params = new HttpParams();
    if (searchValue) {
      params = params.set("search", searchValue);
    }

    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(environment.base_url + "/job-orders/filter/pick-list", {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get job order fault types ******************************************/
  public getJobOrderFaultTypes() {
    return this.http.get(environment.base_url + "/job-order-fault-types").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  /******************************************** get job order system category ******************************************/
  public getJobOrderSyatemCategory(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?active=true";
    }

    return this.http
      .get(environment.base_url + "/system-categories" + active1)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public createJobOrderSystem(body: any) {
    return this.http
      .post(environment.base_url + "/system-categories", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateJobOrderSystem(body: any) {
    return this.http
      .put(environment.base_url + "/system-categories", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getJobOrderSystemById(id: any) {
    return this.http
      .get(environment.base_url + "/system-categories/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteJobOrderSystem(id: any) {
    return this.http
      .delete(environment.base_url + "/system-categories/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted JO System");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /******************************************** get job order service zone picklist ******************************************/
  public getJobOrderServicePickList() {
    return this.http
      .get(environment.base_url + "/service-zones/pick-list")
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get tasks by job order id ******************************************/
  public getTasksByJobOrderId(id: any) {
    return this.http
      .get(
        environment.base_url + "/job-orders/" + id + "/tasks"
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** upload job order attachment ******************************************/
  public uploadJOAttachment(id: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });

    return this.httpOld
      .post(
        environment.base_url + this.ENDPOINT_UPLOAD_JO_ATTACHMENTS + id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** delete attachment ******************************************/
  public deleteAttachment(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_JO_ATTACHMENTS + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get job order first response ******************************************/
  public getJobOrderFirstResponse(jobOrderId: any) {
    return this.http
      .get(
        environment.base_url +
          "/sla-job-orders/job-order?jobOrderId=" +
          jobOrderId
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get job order history ******************************************/
  public getJobOrderHistoryById(jobOrderId: any) {
    return this.http
      .get(
        environment.base_url +
          "/job-order-audit-event-histories/by-job-order/" +
          jobOrderId
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get job order by ticket id ******************************************/
  // Not In Use(Checked On 1/oct/2024) getAllJobOrderByTickets
  public getAllJobOrderByTickets(
    ticketId: any,
    searchValue: any,
    customerId: any,
    status: any,
    parameters: any
  ) {
    let params = new HttpParams();

    if (ticketId !== "" && ticketId != null) {
      params = params.set("ticketId", ticketId);
    }

    params = params.set("ticket", "ticket");

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    if (status !== "" && status !== null) {
      params = params.set("status", status);
    }

    if (customerId !== "" && customerId != null) {
      params = params.set("customerId", customerId);
    }

    if (parameters) {
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25");
    }

    return this.http
      .get(`${environment.base_url}/v4/job-orders`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get job order via params ******************************************/
  public getAllJobOrdersViaParams(customParams: any, agGridParams: any) {
    console.log("CUSTOM PARAMS", customParams);
    let params = new HttpParams();

    if (
      customParams.hasOwnProperty("ticketId") &&
      customParams.ticketId !== "" &&
      customParams.ticketId != null
    ) {
      params = params.set("ticketId", customParams.ticketId);
      params = params.set("ticket", "ticket");
    }

    if (
      customParams.hasOwnProperty("search") &&
      customParams.search !== "" &&
      customParams.search != null
    ) {
      params = params.set("search", customParams.search);
    }

    if (
      customParams.hasOwnProperty("status") &&
      customParams.status !== "" &&
      customParams.status !== null
    ) {
      params = params.set("jobOrderStatuses", customParams.status);
    }

    if (
      customParams.hasOwnProperty("customerId") &&
      customParams.customerId !== "" &&
      customParams.customerId != null
    ) {
      params = params.set("customerId", customParams.customerId);
    }

    if (
      customParams.hasOwnProperty("salesOrderId") &&
      customParams.salesOrderId !== "" &&
      customParams.salesOrderId != null
    ) {
      params = params.set("salesOrderId", customParams.salesOrderId);
    }

    if (
      customParams.hasOwnProperty("fromDate") &&
      customParams.hasOwnProperty("toDate") &&
      customParams.fromDate !== "" &&
      customParams.toDate !== ""
    ) {
      params = params
        .set("fromDate", customParams.fromDate)
        .set("toDate", customParams.toDate);
    }

    if (
      customParams.hasOwnProperty("assetsId") &&
      customParams.assetsId !== "" &&
      customParams.assetsId != null
    ) {
      params = params.set("assetsId", customParams.assetsId);
    }

    if (
      customParams.hasOwnProperty("siteId") &&
      customParams.siteId !== "" &&
      customParams.siteId != null
    ) {
      params = params.set("siteId", customParams.siteId);
    }

    if (
      customParams.hasOwnProperty("projectId") &&
      customParams.projectId !== "" &&
      customParams.projectId != null
    ) {
      params = params.set("projectId", customParams.projectId);
    }

    if (
      customParams.hasOwnProperty("origin") &&
      customParams.origin !== "" &&
      customParams.origin != null
    ) {
      params = params.set("origin", customParams.origin);
    }

    if (agGridParams) {
      params = params
        .set("startFrom", agGridParams.startRow)
        .set("perPage", "25");

      params = params
        .set("columnName", agGridParams.sortModel[0].colId)
        .set("sortOrder", agGridParams.sortModel[0].sort);
    }

    return this.http
      .get(`${environment.base_url}/v4-1/job-orders`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** ## hp form start ## ******************************************/

  /******************************************** get hp form ******************************************/
  public getViewHpForm(id: any, number: any) {
    return this.http
      .get(
        environment.base_url +
          "/hp-form/by/job-order?jobOrderId=" +
          id +
          "&jobOrderNumber=" +
          number
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** get hp form codes ******************************************/
  public getCodes(type: any) {
    return this.http
      .get(environment.base_url + "/code-mapping/get-all-codes?type=" + type)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** edit hp form ******************************************/
  public editHpForm(id: any, number: any, body: any) {
    return this.http
      .put(
        environment.base_url +
          "/hp-form/save?jobOrderId=" +
          id +
          "&jobOrderNumber=" +
          number,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /******************************************** ## hp form end ## ******************************************/
  /******************************************** get job order Fault Setup ******************************************/
  public getJobOrderFault(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?active=true";
    }

    return this.http.get(environment.base_url + "/faults" + active1).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public createJobOrderFault(body: any) {
    return this.http
      .post(environment.base_url + "/faults", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateJobOrderFault(body: any) {
    return this.http
      .put(environment.base_url + "/faults", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getJobOrderFaultById(id: any) {
    return this.http.get(environment.base_url + "/faults/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteJobOrderFault(id: any) {
    return this.http
      .delete(environment.base_url + "/faults/" + id, { observe: "response" })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted JO faults");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /******************************************** get job order Resolution ******************************************/
  public getJobOrderResolution(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?active=true";
    }

    return this.http.get(environment.base_url + "/resolutions" + active1).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public getAllJobOrderResolution() {
    return this.http.get(environment.base_url + "/resolutions").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public createJobOrderResolution(body: any) {
    return this.http
      .post(environment.base_url + "/resolutions", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateJobOrderResolution(body: any) {
    return this.http
      .put(environment.base_url + "/resolutions", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getJobOrderResolutionById(id: any) {
    return this.http.get(environment.base_url + "/resolutions/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteJobOrderResolution(id: any) {
    return this.http
      .delete(environment.base_url + "/resolutions/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted JO resolutions");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getJobOrderChecklist(id: any) {
    return this.http
      .get(`${environment.base_url}/job-order-checklists/by-job-order/${id}`)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getJobOrderForm(id: any) {
    return this.http
      .get(`${environment.base_url}/test-forms/by-job-order/${id}`)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public createJOFaultMappingSystemCheck(body: any) {
    return this.http
      .post(environment.base_url + "/fault-mappings/check", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public getSyaytemToFault(body: any) {
   return this.http.post(environment.base_url + "/fault-mappings/faults/filter",body).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public createJOFaultMappingSystemUncheck(body: any) {
    return this.http
      .post(environment.base_url + "/fault-mappings/un-check", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
/**************************************************** Resolution Mapping *****************************************/
public createJOResolutionMappingSystem(body: any) {
    return this.http
      .post(environment.base_url + "/resolution-mappings/resolutions/filter", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public createJOResolitionMappingSystemCheck(body: any) {
    return this.http
      .post(environment.base_url + "/resolution-mappings/check", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  
  public createJOResolitionMappingSystemUncheck(body: any) {
    return this.http
      .post(environment.base_url + "/resolution-mappings/un-check", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  
  public generateChubbPDF(parentId: any,parentType: any){
    const formData = new FormData();
    var plan="";
    if(parentId !== null){
     plan="?parentId="+parentId
    }
    if(parentType !== null){
      plan+="&parentType="+parentType
     }

    return this.http.post( environment.base_url+'/attachments/generate-pdf/jo-forms'+plan, formData, { observe: 'response' }).pipe(
      map((resp: any) => {
        this.refreshList$.emit(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}
/*================= Servicing Checklist Attchement ==============*/
public uploadJOChubbAttachment(id: any, formData:any,parentType : any){
  const headers = new Headers({});
  headers.append('Authorization', 'Bearer '+this.auth.getToken());
  let options = new RequestOptions( { headers: headers } )
  var ENDPOINT_UPLOAD_CHUBB_ATTACHMENTS = "/attachments/v2/job-order-forms/upload?parentType="+parentType+"&parentId=";

  return this.httpOld.post(environment.base_url+ENDPOINT_UPLOAD_CHUBB_ATTACHMENTS+id, formData, options).pipe(map((resp: any) => {
      this.refreshList$.emit(resp);
      return resp;
    }),
    catchError((error) => {
      //console.log(error);
      return throwError(error);
    })
  );
}

/*================= Customer service report sales and invoice update ==============*/
public updateCSR(data : any){
  return this.http.put(environment.base_url+'/customer-service-reports/change-sales-invoice-details?'+data, { observe: 'response' }).pipe(map((resp: any) => {
    this.refreshList$.emit(resp);
    return resp;
  }),
  catchError((error) => {
    //console.log(error);
    return throwError(error);
  })
);
}
/*================= Customer service report Item update ==============*/
public updateCSRItems(data:any){
  return this.http.put(environment.base_url+'/customer-service-reports/v2/update-item-details',data, { observe: 'response' }).pipe(map((resp: any) => {
    this.refreshList$.emit('CSR update');
    return resp;
  }),
  catchError((error) => {
    //console.log(error);
    return throwError(error);
  })
);
}
/*================= Customer service report complaint and fault update ==============*/
public updateCSRcomplaint(data: any){
  
  
  return this.http.put(environment.base_url+'/customer-service-reports/change-fault-complaint-details?'+data, { observe: 'response'}).pipe(map((resp: any) => {
    this.refreshList$.emit('CSR update');
    return resp;
  }),
  catchError((error) => {
    //console.log(error);
    return throwError(error);
  })
);
}
/*================= Customer service report complaint and fault update ==============*/
public updateCSRJob(data : any){
  return this.http.put(environment.base_url+'/customer-service-reports/change-operator-remarks-details?'+data, { observe: 'response' }).pipe(map((resp: any) => {
    this.refreshList$.emit('CSR update');
    return resp;
  }),
  catchError((error) => {
    //console.log(error);
    return throwError(error);
  })
);
}


/******************************************** List of job order Templates ******************************************/
public getAllJobOrderTemplates( search: any, parameters: any,typeId: any,systemId: any, serviceZoneId: any, isCreation: any, faultId: any) {
  let params = new HttpParams();
  if (search !== "" && search != null) {
    params = params.set("search", search);
  }
  if (typeId !== "" && typeId != null) {
    params = params.set("typeId", typeId);
  }
  if (systemId !== "" && systemId != null) {
    params = params.set("systemId", systemId);
  }
  if (serviceZoneId !== "" && serviceZoneId != null) {
    params = params.set("serviceZoneId", serviceZoneId);
  }
  if (isCreation !== "" && isCreation != null) {
    params = params.set("isCreation", isCreation);
  }
  if (faultId !== "" && faultId != null) {
    params = params.set("faultId", faultId);
  }

  if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);


  return this.http .get(environment.base_url + this.ENDPOINT_JOB_ORDERS_TEMPLATES+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
}
  /******************************************** create job order Template ******************************************/
  public createJobOrderTemplate(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_JOB_ORDERS_TEMPLATES, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }


    /******************************************** create job order ******************************************/
    public createJobOrderUsingTemplate(body: any) {
      return this.http
        .post(environment.base_url + this.ENDPOINT_ALL_JOB_ORDERS+'/template', body, {
          observe: "response",
        })
        .pipe(
          map(
            (resp: any) => {
              return resp;
            },
            catchError((error) => {
              //console.log(error);
              return throwError(error);
            })
          )
        );
    }


  /******************************************** edit job order Template ******************************************/
  public editJobOrderTemplate(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_JOB_ORDERS_TEMPLATES, body, {observe: "response", }).pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }
/******************************************** Get job order Template ******************************************/
  public getJOTemplateById(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_JOB_ORDERS_TEMPLATES+'/'+ id).pipe(
        map((resp: any) => {
          console.log(resp)
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /******************************************** delete job order Template ******************************************/
  public deleteJobOrderTemplate(id: any) {
    return this.http.delete(environment.base_url + this.ENDPOINT_JOB_ORDERS_TEMPLATES+'/'+ id, {observe: "response", }).pipe( map(
          (resp: any) => {
            this.refreshTemplateList$.emit("Deleted Job Order Template");
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** List of job order Templates ******************************************/
public getAllJobOrderTemplatesProduct( search: any, parameters: any,tmplJobOrderId: any) {
  let params = new HttpParams();
  if (search !== "" && search != null) {
    params = params.set("search", search);
  } 
   params = params.set("tmplJobOrderId", tmplJobOrderId);
 
  if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);


  return this.http .get(environment.base_url + this.ENDPOINT_JOB_ORDERS_TEMPLATES_PRODUCT+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
}
  /******************************************** create job order Template ******************************************/
  public createJobOrderTemplateProduct(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_JOB_ORDERS_TEMPLATES_PRODUCT, body, { observe: "response" }).pipe( map(
          (resp: any) => {
            this.refreshJOTemplateProductList$.emit(resp);
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** delete job order Template Product ******************************************/
  public deleteJobOrderTemplateProduct(id: any) {
    let params = new HttpParams();
    params = params.set("tmplJoProdId", id);
     return this.http.delete(environment.base_url + this.ENDPOINT_JOB_ORDERS_TEMPLATES_PRODUCT+'/'+ id, {observe: "response", params: params }).pipe( map(
          (resp: any) => {
            this.refreshJOTemplateProductList$.emit("Deleted Job Order Template Product");
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }
  
}
