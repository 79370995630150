<div class="">
    <div class="custella-modal d-flex flex-column">
        <div class="custella-modal-header">
            <div class="custella-modal-header-text" id="title_SetWorkflowNotificationPreviewEmail">
                <span>{{data.name}}</span>
            </div>
            <i class="pi pi-times pt-1"  [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="modalRef.hide()" id="btn_SetWorkflowNotificationPreviewEmailClose"></i>
        </div>
    </div>  

    <div class="custella-modal-body" *ngIf="translate">
        <div class="custella-form-container box-none m-0">
            <div class="custella-form-container-content my-3">
                <div class="mb-3 col-12 flex-align-center"> 
                    <span class="text-12 me-2" id="label_SetWorkflowNotificationPreviewEmailSubject" >{{translate?.setup.configuration.notifications.fields.subject.singular }}</span>
                    <input type="text" pInputText autocomplete="off" disabled [(ngModel)]="data.subject" id="input_SetWorkflowNotificationPreviewEmailSubjectField">
                </div>
                <div class="mb-3 col-12"> 
                    <div class="example-template mx-0">
                        <div class="header border-bottom" *ngIf="data.htmlHeader.imgUrl">
                            <div class="image" [ngStyle]="{'justify-content':data.htmlHeader.align}">
                                <p-image width="250" [src]="data.htmlHeader.imgUrl" alt="Image" styleClass="logo" [preview]="false" id="img_SetWorkflowNotificationPreviewEmailHeader"></p-image>
                            </div>
                        </div>
                        <div class="content-email border-bottom">
                            <div class="mb-3 htmlContent" [innerHTML]="data.htmlContent | safeHtml" id="value_SetWorkflowNotificationPreviewEmailBody"></div>
                            

                            <div class="me-2" *ngIf="data.includeTable && (!data.tableHeaders || data.tableHeaders.length ==0)">
                                <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll">
                                    <ng-template pTemplate="header">
                                        <tr class="">
                                            <th class="col-4" id="label_SetWorkflowNotificationPreviewEmailBodyTable">{{translate?.setup.configuration.notifications.fields.table.singular }}</th>
                                        </tr>
                                    </ng-template>
                                    
                                </p-table>
                                </div>

                                <div class="me-2" *ngIf="data.includeTable && (data.tableHeaders)">
                                    <p-table  styleClass="p-datatable-gridlines" responsiveLayout="scroll">
                                        <ng-template pTemplate="header">
                                            <tr class="" >
                                                <th *ngFor="let dd of data.tableHeaders" class="col-4" id="value_SetWorkflowNotificationPreviewEmailBodyTable">{{dd.header}}</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-list>
                                            <tr class="text-12 font-medium check-list">
                                                <td id="label_SetWorkflowNotificationPreviewEmailBodyTable">
                                                   -
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                        </div>

                        <div class="content-table" *ngIf="data.includeButton">
                            <p-button [label]="data.buttonLabel" id="btn_SetWorkflowNotificationPreviewEmailBodyButtonLabel"></p-button>
                            
                        </div>

                       
                        <div class="bottom border-top" *ngIf="data.htmlFooter.imgUrl">
                            <div class="image" [ngStyle]="{'justify-content':data.htmlFooter.align}">
                                <p-image width="250" [src]="data.htmlFooter.imgUrl" alt="Image" styleClass="logo" [preview]="false" id="img_SetWorkflowNotificationPreviewEmailFooter"></p-image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>