<ngx-spinner bdColor = "rgb(255 255 255 / 76%)" size = "medium" color = "#6366f1" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p class="font-white" > Loading... </p></ngx-spinner>

<div class="custella-login">
    <!-- Language -->
    <div class="language">
        <li class="custella-lang-front">
            <div class="custella-lang-menu accordion accordion-flush">
                <div class="accordion-item">
                    <div class="accordion-header" id="language">
                        <button class="accordion-button collapsed justify-content-between px-1 py-2" data-bs-toggle="collapse" data-bs-target="#choose-lang" aria-expanded="false" aria-controls="choose-lang">
                            <div class="flex-align-center">
                                <!-- <i class="fa-light fa-globe"></i> -->
                                <span class="fi fi-{{currentLang.flag}} me-2"></span>
                                <span class="text-12 ms-2">{{currentLang.value}}</span>

                                <!-- <span class="text-12 font-white" *ngIf="currentLang === 'Malay'">
                                    Malay
                                </span>

                                <span class="text-12 font-white" *ngIf="currentLang === 'Chinese'">
                                    Chinese
                                </span> -->
                            </div>
                            <i class="fa-regular fa-chevron-down ms-4"></i>
                        </button>

                    </div>
                    <ul class="p-0 m-0 accordion-collapse collapse"  id="choose-lang" #chooseLang>
                        <ng-container *ngFor="let lang of languageList">
                            <li class="py-1 px-0 my-0"aria-labelledby="language"  *ngIf="currentLang != lang" (click)="onChangeLanguage(lang)">
                                <a class="px-1">
                                    <span class="fi fi-{{lang.flag}} me-2"></span>
                                <span class="text-12 ms-2">{{lang.value}}</span>
                                </a>
                            </li>
                        </ng-container>
                        <!-- <li class="py-1 px-0 my-0" aria-labelledby="language"  (click)="onChangeLanguage('my')" >
                            <a class="px-1">
                                <span class="text-12">Malay</span>
                            </a>
                        </li>
                        <li class="py-1 px-0 my-0" aria-labelledby="language"  (click)="onChangeLanguage('ch')" >
                            <a class="px-1">
                                <span class="text-12">Chinese</span>
                            </a>
                        </li> -->
                        <!-- <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('my')" *ngIf="currentLang !== 'Malay'">
                            <a class="px-1">
                                <span class="text-12 font-white">Malay</span>
                            </a>
                        </li>
                        <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('ch')" *ngIf="currentLang !== 'Chinese'">
                            <a class="px-1">
                                <span class="text-12 font-white">Chinese</span>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </li>
    </div>

    


    <div class="container">
        <div class="row">
            <div class="mx-auto">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body py-4 px-3 px-sm-3 py-sm-4">
                    <span>
                        <img src="../../../assets/svg/FieldEx_L_T.svg" alt="FieldEx Logo">
                    </span>
                    <h3 class="card-title font-bold pt-5 pb-4" >{{langData.login.label.singular}}</h3>
                    <form [formGroup]="loginForm" method="POST" >
                        <div class="form-group">
                            <label >{{langData.login.fields.username.singular}}</label>
                            <span class="p-input-icon-left">
                                <i class="fa-regular fa-user pe-none"></i>
                                <input type="text" [(ngModel)]="login.username" pInputText autocomplete="off" formControlName="username" name="username" id="input_LoginEmailAddrField">
                            </span>
                            <div *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].invalid">
                                <span *ngIf="loginForm.controls['username'].errors?.required" class="p-error text-10" id="label_LoginEmailAddrReqField" >{{langData.general.validationFE.requiredField}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label >{{langData.login.fields.password.singular}}</label>
                            <span class="p-input-icon-left p-input-icon-right">
                                <i class="fa-regular fa-lock-keyhole pe-none"></i>
                                <input [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="login.password" id="input_LoginPwdField"formControlName="password" name="password" pInputText autocomplete="off"><i class="pi"
                                    [ngClass]="{ 'pi-eye-slash': !fieldTextType, 'pi-eye': fieldTextType}"(click)="showPassword()" id="btn_LoginPwdShow"></i>
                            </span>
                            <div *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].invalid">
                                <span *ngIf="loginForm.controls['password'].errors?.required" class="p-error text-10" id="label_LoginPwdReqField" >{{langData.general.validationFE.requiredField}}</span>
                            </div>
                        </div>
                    <app-messages></app-messages>
                    <div class="remember">
                        <div class="p-field-checkbox">
                            <p-checkbox [(ngModel)]="checkRemember" formControlName="remember" id="remember" name="remember" [binary]="true"></p-checkbox>
                            <label class="rmb-label" id="label_LoginRememberMe" >{{langData.login.fields.rememberMe.singular }}</label>
                        </div>
                    </div>

                    <div class="my-4">
                        <button type="submit" class="custella-btn-long" id="btn_LoginSignInSubmit" (click)="signIn()">
                            <span class="text-12" >{{langData.login.buttons.login.singular}}</span>
                        </button>
                    </div>

                    <div class="forgot-me">
                        <label class="click-forgot" routerLink="/forgot-password" >{{langData.login.fields.forgotPassword.singular}}&nbsp;</label>
                    </div>
                </form>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="vh-100 overflow-hidden">
    <form [formGroup]="loginForm" method="POST" >

        <div class="d-flex vh-100">
            <div class="left-form">
                <div class="container">
                    <div class="custella-login d-block position-relative p-5">
                        <img src="assets\images\custella_logo.png">
                        <div class="text-20 font-medium" id="title_LoginLetsStart" >{{'lets_start'}}</div>
                        <div class="custella-login-form d-block position-relative">
                            <div class="mb-3">
                                <span class="mb-2 text-12 font-medium" id="label_LoginEmailAddr" >{{'email_address'}}</span>
                                <input type="text" [(ngModel)]="login.username" class="w-100"
                                    placeholder="Email address" pInputText autocomplete="off" formControlName="username" name="username" id="input_LoginEmailAddrField">

                                <div class="alert col-12 px-0 py-0 text-right custella-content-row-input" *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].invalid">
                                    <small *ngIf="loginForm.controls['username'].errors?.required" class="custella-content-row-input-sub p-error" id="label_LoginEmailAddrReqField" >{{'field_required'}}</small>
                                </div>
                            </div>
                            <div class="mb-3">
                                <span class="mb-2 text-12 font-medium" id="label_LoginPwd" >{{'password'}}</span>
                                <span class="p-input-icon-right w-100">
                                    <input [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="login.password" id="input_LoginPwdField"
                                        formControlName="password" name="password" class="custella-input w-100"
                                        placeholder="Password" pInputText autocomplete="off">
                                    <i class="pi "
                                        [ngClass]="{ 'pi-eye-slash': !fieldTextType, 'pi-eye': fieldTextType}"
                                        (click)="showPassword()" id="btn_LoginPwdShow"
                                        ></i>
                                </span>
                                <div class="alert col-12 px-0 py-0 text-right custella-content-row-input"
                                    *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].invalid">
                                    <small *ngIf="loginForm.controls['password'].errors?.required"
                                        class="custella-content-row-input-sub p-error" id="label_LoginPwdReqField" >{{'field_required'}}</small>
                                </div>
                            </div>
                        </div>
                        <app-messages></app-messages>
                        <div class="d-flex align-items-baseline justify-content-between mt-1 mb-4">
                            <div class="p-field-checkbox">
                                <p-checkbox [(ngModel)]="checkRemember" formControlName="remember" id="remember"
                                    name="remember" [binary]="true"></p-checkbox>
                                <span class="d-blcok mb-3 mt-1 ms-2 text-10" id="label_LoginRememberMe" >{{'remember_me'}}</span>
                            </div>
                            <span class="text-10 color-primary justify-content-end cursor-pointer" id="label_LoginForgotPwd" routerLink="/forgot-password" >{{'forgot_password'}}</span>
                        </div>

                        <div class="mt-3">
                            <button type="submit" class="custella-btn-long" id="btn_LoginSignInSubmit" (click)="signIn()">
                                <label><i class="pi pi-user pe-2"></i>Sign In</label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-75" [ngStyle]="{background:'var(--surface-300)'}">
                <div class="right-form">
                    <div class="custella-quote">
                        <div class="custella-quote-text">
                            “<span class="custella-quote-text-bold">Refine</span> the way your <span
                                class="custella-quote-text-bold">Time</span> is spent, to <span
                                class="custella-quote-text-bold">Achieve</span> more towards a greater <span
                                class="custella-quote-text-bold">Purpose”</span>
                            <br>
                            <span class="custella-quote-text-small"> – Custella Team</span>
                        </div>
                        <div class="custella-quote-img">
                            <img src="assets\images\login_view.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div> -->