<div class="custella-modal">
    <form [formGroup]="requestAssistanceForm">
        <div class="custella-modal-header align-items-center">
            <div class="custella-modal-header-text" id="title_TaskReqAssist"> {{ title }} </div>
            <i class="pi pi-times" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  id="btn_TaskReqAssistClose" (click)="modalRef.hide()"></i>
        </div>
  
        <div class="">
            <!-- Container -->
            <div class="custella-form-container w-100 rounded-2 m-0 pt-3 height-fit-content">
                <!-- Container Content -->
  
                <!-- Task name and Priority level -->
                <div class="custella-form-container-content m-0">
                    <div class="custella-content-row mb-3 pe-0">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskAssistReason" >{{ translate?.task.fields.assistanceReason.singular }}</span>
                            <textarea pInputTextarea autocomplete="off" name="description" id="input_TaskAssistReasonField" [(ngModel)]="request.reason" formControlName="reason" name="reason" [maxlength]="320000"></textarea>
                            <div class="p-error text-10" *ngIf=" requestAssistanceForm.controls['reason'].touched && requestAssistanceForm.controls['reason'].invalid ">
                                <span *ngIf="requestAssistanceForm.controls['reason'].errors?.required" id="label_TaskAssistReasonReqField" >{{ translate?.general.validationFE.requiredField }}</span>
                            </div>
                        </div>
  
                    </div>
  
                    
                </div>
  

                <div class="custella-form-container-footer mb-0">
                    <div class="d-flex align-items-center justify-content-end">
                            <p-button class="ms-2 h-32" id="btn_TaskAssistSubmit" type="button" (click)="onSubmit()" [label]="translate?.general.buttons.save.singular"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
  </div>
  