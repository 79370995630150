<div class="d-flex vh-100 position-fixed  justify-content-between overflow-x-hidden">
    <div class="left-setup vh-100 overflow-scroll z-200">
        <app-setup-frame></app-setup-frame>
    </div>
    <div class="flex-fill px-0 right-setup overflow-y-auto" id="right-setup">
        <div class="custella-setup-stickey-breadcrumb" *ngIf="setupItems.length !=0">
            <p-breadcrumb [model]="setupItems"></p-breadcrumb>
        </div>
        <!-- <div class="h-95 position-fixed flex-fill px-0" id="right-setup"> -->           
            <router-outlet></router-outlet>
        <!-- </div> -->
    </div>
</div>