import { of as observableOf, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PriceRateCardBookService {
  // EventEmitter to notify when the price book needs to be refreshed
  public refreshPriceBook$: EventEmitter<any>;
  // Endpoints for interacting with the price book API
  ENDPOINT_LIST = '/price-books';
  ENDPOINT_PRODUCT_PRICING_LIST = '/price-book-products/product';
  ENDPOINT_RATE_CARD_LIST = '/rate-cards';

  // EventEmitters for value change notifications
  public valueChanged$: EventEmitter<any>;
  refreshSelectedSectionSettings$: EventEmitter<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthenticationService
  ) {
    // Initialize EventEmitters
    this.refreshPriceBook$ = new EventEmitter();
    this.valueChanged$ = new EventEmitter();
    this.refreshSelectedSectionSettings$ = new EventEmitter();
  }

  /**
   * Fetches the list of price books.
   * Allows optional search parameters and supports pagination.
   * @param searchValue Filter value for the search.
   * @param parameters Object containing pagination and sorting details.
   */
  public getPriceBookList(searchValue: any, parameters: any) {
    let params = new HttpParams();

    // Add search filter to parameters
    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }

    // Add pagination and sorting parameters if provided
    if (parameters) {
      params = params
        .set('startFrom', parameters.startRow)
        .set('perPage', '25') // Fixed page size
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    }

    // Perform the GET request to fetch price books
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST, { observe: 'response', params: params })
      .pipe(
        map((resp: any) => {
          return resp; // Pass the response to the caller
        }),
        catchError((error) => {
          return observableOf(error); // Handle errors and return observable
        })
      );
  }

  /**
   * Creates a new price book.
   * @param body Request body containing details of the price book to be created.
   */
  public createPriceBookData(body: any) {
    return this.http.post(environment.base_url + this.ENDPOINT_LIST, body, { observe: 'response' }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error); // Propagate error to the caller
      })
    );
  }

  /**
   * Updates an existing price book.
   * @param body Request body containing updated details of the price book.
   */
  public updatePriceBookData(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_LIST, body, { observe: 'response' }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Fetches details of a price book by its ID.
   * @param id ID of the price book to be retrieved.
   */
  public getPriceBookById(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_LIST + '/' + id, { observe: 'response' }).pipe(
      map((resp: any) => {
        return resp; // Pass the response to the caller
      }),
      catchError((error) => {
        return observableOf(error); // Handle errors and return observable
      })
    );
  }

  /**
   * Deletes a price book by its ID.
   * Emits an event after successful deletion.
   * @param id ID of the price book to be deleted.
   */
  public deletePriceBookById(id: any) {
    return this.http.delete(environment.base_url + this.ENDPOINT_LIST + '/' + id, { observe: 'response' }).pipe(
      map((resp: any) => {
        this.refreshPriceBook$.emit('Deleted Price Book'); // Notify listeners about deletion
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Fetches the list of product pricing for a specific price book.
   * Supports optional search parameters and pagination.
   * @param searchValue Filter value for the search.
   * @param parameters Object containing pagination and sorting details.
   * @param priceBookId ID of the price book to fetch product pricing for.
   */
  public getProductPricingList(searchValue: any, parameters: any, priceBookId: any) {
    let params = new HttpParams();
    params = params.set('viewAll', false); // Fetch only specific data
    params = params.set('priceBookId', priceBookId); // Bind to price book ID

    // Add search filter to parameters
    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }

    // Add pagination and sorting parameters if provided
    if (parameters) {
      params = params
        .set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    }

    // Perform the GET request to fetch product pricing
    return this.http
      .get(environment.base_url + this.ENDPOINT_PRODUCT_PRICING_LIST, { observe: 'response', params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error); // Handle errors and return observable
        })
      );
  }
  /**
   * Creates a new price book.
   * @param body Request body containing details of the price book to be created.
   */
  public createProductPriceData(body: any) {
    return this.http.post(environment.base_url +'/price-book-products', body, { observe: 'response' }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error); // Propagate error to the caller
      })
    );
  }

  /**
   * Updates an existing price book.
   * @param body Request body containing updated details of the price book.
   */
  public updateProductPriceData(body: any) {
    return this.http.put(environment.base_url + '/price-book-products', body, { observe: 'response' }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Fetches details of a price book by its ID.
   * @param id ID of the price book to be retrieved.
   */
  public getProductPriceById(id: any) {
    return this.http.get(environment.base_url +  '/price-book-products/' + id, { observe: 'response' }).pipe(
      map((resp: any) => {
        return resp; // Pass the response to the caller
      }),
      catchError((error) => {
        return observableOf(error); // Handle errors and return observable
      })
    );
  }

  /**
   * Deletes a price book by its ID.
   * Emits an event after successful deletion.
   * @param id ID of the price book to be deleted.
   */
  public deleteProductPriceById(id: any) {
    return this.http.delete(environment.base_url  + '/price-book-products/' + id, { observe: 'response' }).pipe(
      map((resp: any) => {
        this.refreshPriceBook$.emit('Deleted Product Pricing'); // Notify listeners about deletion
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
 /**
   * Fetches the list of price books.
   * Allows optional search parameters and supports pagination.
   * @param searchValue Filter value for the search.
   * @param parameters Object containing pagination and sorting details.
   */
 public getActivePriceBookList(url: any,searchValue: any, parameters: any,customerId: any,installationTime: any) {
  let params = new HttpParams();

  // Add search filter to parameters
  if (searchValue !== '' && searchValue != null) {
    params = params.set('search', searchValue);
  }
  // Add search filter to parameters
  if (customerId !== '' && customerId != null) {
    params = params.set('customerId', customerId);
  }
  // Add search filter to parameters
  if (installationTime !== '' && installationTime != null) {
    params = params.set('installationTime', installationTime);
  }

  params = params.set('statuses', 'ACTIVE');
  // Add pagination and sorting parameters if provided
  if (parameters) {
    params = params
      .set('startFrom', parameters.startRow)
      .set('perPage', '25') // Fixed page size
      .set('columnName', parameters.sortModel[0].colId)
      .set('sortOrder', parameters.sortModel[0].sort);
  }

  // Perform the GET request to fetch price books
  return this.http
    .get(environment.base_url + url, { observe: 'response', params: params })
    .pipe(
      map((resp: any) => {
        return resp; // Pass the response to the caller
      }),
      catchError((error) => {
        return observableOf(error); // Handle errors and return observable
      })
    );
}
/*==================================Get rate Card List==================================*/

/**
   * Fetches the list of product pricing for a specific price book.
   * Supports optional search parameters and pagination.
   * @param searchValue Filter value for the search.
   * @param parameters Object containing pagination and sorting details.
   * @param priceBookId ID of the price book to fetch product pricing for.
   */
public getRateCardList(searchValue: any, parameters: any, contractId: any) {
  let params = new HttpParams();
  params = params.set('contractId', contractId); // Bind to price book ID

  // Add search filter to parameters
  if (searchValue !== '' && searchValue != null) {
    params = params.set('search', searchValue);
  }

  // Add pagination and sorting parameters if provided
  if (parameters) {
    params = params
      .set('startFrom', parameters.startRow)
      .set('perPage', '25')
      .set('columnName', parameters.sortModel[0].colId)
      .set('sortOrder', parameters.sortModel[0].sort);
  }

  // Perform the GET request to fetch product pricing
  return this.http
    .get(environment.base_url + this.ENDPOINT_RATE_CARD_LIST, { observe: 'response', params: params })
    .pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error); // Handle errors and return observable
      })
    );
}
/**
 * Creates a new price book.
 * @param body Request body containing details of the price book to be created.
 */
public createRateCardData(body: any) {
  return this.http.post(environment.base_url + this.ENDPOINT_RATE_CARD_LIST, body, { observe: 'response' }).pipe(
    map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      return throwError(error); // Propagate error to the caller
    })
  );
}

/**
 * Updates an existing price book.
 * @param body Request body containing updated details of the price book.
 */
public updateRateCardData(body: any) {
  return this.http.put(environment.base_url +  this.ENDPOINT_RATE_CARD_LIST, body, { observe: 'response' }).pipe(
    map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
}

/**
 * Fetches details of a price book by its ID.
 * @param id ID of the price book to be retrieved.
 */
public getRateCardById(id: any) {
  return this.http.get(environment.base_url +   this.ENDPOINT_RATE_CARD_LIST+'/' + id, { observe: 'response' }).pipe(
    map((resp: any) => {
      return resp; // Pass the response to the caller
    }),
    catchError((error) => {
      return observableOf(error); // Handle errors and return observable
    })
  );
}

/**
 * Deletes a price book by its ID.
 * Emits an event after successful deletion.
 * @param id ID of the price book to be deleted.
 */
public deleteRateCardById(id: any) {
  return this.http.delete(environment.base_url  +  this.ENDPOINT_RATE_CARD_LIST+ '/' + id, { observe: 'response' }).pipe(
    map((resp: any) => {
      this.refreshPriceBook$.emit('Deleted Rate Card'); // Notify listeners about deletion
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
}
 /**
   * Fetches the list of price books.
   * Allows optional search parameters and supports pagination.
   * @param searchValue Filter value for the search.
   * @param parameters Object containing pagination and sorting details.
   */
 public getPriceBookListForProduct(searchValue: any, parameters: any, productId: any) {
  let params = new HttpParams();

  // Add search filter to parameters
  if (searchValue !== '' && searchValue != null) {
    params = params.set('search', searchValue);
  }

  params = params.set('productId', productId);
  // Add pagination and sorting parameters if provided
  if (parameters) {
    params = params
      .set('startFrom', parameters.startRow)
      .set('perPage', '25') // Fixed page size
      .set('columnName', parameters.sortModel[0].colId)
      .set('sortOrder', parameters.sortModel[0].sort);
  }

  // Perform the GET request to fetch price books
  return this.http
    .get(environment.base_url + '/price-books/price-book-product', { observe: 'response', params: params })
    .pipe(
      map((resp: any) => {
        return resp; // Pass the response to the caller
      }),
      catchError((error) => {
        return observableOf(error); // Handle errors and return observable
      })
    );
}
}
