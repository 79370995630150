import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { HeaderTitleService } from 'src/app/_services/header-title.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { MenuService } from 'src/app/_services/menu.service';
import { PERSISTANT_ITEM } from 'src/app/_models/global.data.model';
import { UsersService } from 'src/app/_services/admin/users.service';
import { ProfilesService } from 'src/app/_services/admin/profiles.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
    selector: 'app-left-menu',
    templateUrl: 'left-menu.component.html',
    styles: ['a.active { border-left: 6px solid #f7a941; background-color: #233038;}'],
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class LeftMenuComponent extends SubscriptionUtil implements OnInit, AfterViewInit, OnDestroy {

    subMenu: any;
    toggelMenu: boolean = false
    highLighted = [
        {}
    ]
    collapseNav: boolean = false;
    refreshCollapse: Subscription;
    refreshCollapseDot!: Subscription;
    addonFeature: any;
    // reports: boolean;
    tasks!: boolean;
    teams!: boolean;
    customers!: boolean;
    products!: boolean;
    jobs!: boolean;
    sales!: boolean;
    assets!: boolean;
    inventory!: boolean;
    tickets!: boolean;
    setup!: boolean;
    planningHub!: boolean;
    deliveryOrders!: boolean;
    shifts!: boolean;
    drivers!: boolean;
    packages!: boolean;
    vehicles!: boolean;
    fleetSetup!: boolean;
    helpdesk!: boolean;
    scheduledMaintenace!: boolean;
    assetContracts!: boolean;
    site!: boolean;
    project!: boolean;
    users!: boolean;

    visits!: boolean;
    pmv!:boolean
    overtime!:boolean
    attendance!:boolean
    productivity!:boolean
    machine_specialist!:boolean


    menuType: any;
    refreshMenuType!: Subscription;
    salePer: boolean = false;
    servicePer: boolean = false;
    fleetPer: boolean = false;

    refreshSide!: Subscription;
    acadamyData: any;
  checklistsRead: boolean = false;
  checklistsFull!: boolean;
  refreshProfile!: Subscription;
  menuInactiveDesktop: boolean = false;
  translate: any;
  loading: boolean = true

    constructor(
        private router: Router,
        private util: UtilServiceService,
        private auth: AuthenticationService,
        private headerTitleService: HeaderTitleService,
        private perm: PermissionService,
        public breadcrumbService: BreadcrumbService,
        private menuService: MenuService,
        private cdr: ChangeDetectorRef,
        private usersService: UsersService,
        private profileService: ProfilesService,
        private languageTranslateService: LanguageTranslateService,
        private spinner: NgxSpinnerService) {
        super()
        //console.log("cons1", this.translate)
        this.getUrl()
        this.refreshCollapse = this.util.refreshCollapse$.subscribe((res) => {
            this.loadMenuSetting(res)
        });

          this.refreshCollapseDot = this.util.refreshCollapse1$.subscribe((res: any) => {
          //console.log(res, "=====",res.status)
              if(res.status == false){
                this.menuInactiveDesktop=false
              }else{
                this.menuInactiveDesktop = res.event == "mouseOut" ? true : false
              }
           // console.log(res, "===", this.menuInactiveDesktop)
        });

          this.refreshSide = this.headerTitleService.refreshSide$.subscribe(res => {
              setTimeout(() => {
                  this.addonFeature = this.auth.getAddonFeatureLists();
                  //console.log(this.addonFeature, "=====", localStorage.getItem('addonFeatureList'))
              }, 1000);
          })

          //console.log("cons2", this.translate)

    }

    ngOnInit() {
      //console.log("cons3", this.translate)
      //this.translate = {}
  /************************** Start Load Language Translated Dynamically (Preeti) ************************************/
    this.push(this.languageTranslateService.menuSrc$.subscribe(data => {
        if(Object.keys(data).length != 0){ this.translate = data; this.loading = false}
      }))
  /************************** Start Load Language Translated Dynamically (Preeti) ************************************/
      this.refreshProfile = this.profileService.refreshList$.subscribe(res => {
        this.getAllAccessPermission();
      });
       this.getAllAccessPermission()
       console.log("cons4", this.translate)
     //  debugger
        // this.reports = this.perm.capable('reports', 'read');
        
    }
    getAllAccessPermission(){
      this.getUrl()
      this.loadMenuSetting(this.util.getNavCollapseStatus())
      var menuPerm: any = localStorage.getItem('menuPermissions')
      var menuPermissions = JSON.parse(menuPerm);
      this.menuType = sessionStorage.getItem('menuType') ? sessionStorage.getItem('menuType') : menuPermissions.defaultTenantCategory;
      this.refreshMenuType = this.headerTitleService.refreshMenuType$.subscribe((res: any) => {
          this.menuType = res;
      })
      var profileInfo = JSON.parse(this.auth.getProfileInfo());
      this.salePer = this.menuService.findMenuPermissionsSALES(profileInfo.tenantCategories);
      this.servicePer = this.menuService.findMenuPermissionsSERVICE(profileInfo.tenantCategories);
      this.fleetPer = this.menuService.findMenuPermissionsFLEET(profileInfo.tenantCategories);
      this.tasks = this.perm.capable('tasks', 'read');
      this.teams = this.perm.capable('teams', 'read');
      this.customers = this.perm.capable('customers', 'read');
      this.products = this.perm.capable('products', 'read');
      this.jobs = this.perm.capable('job_order', 'read');
      this.sales = this.perm.capable('sales_order', 'read');
      this.assets = this.perm.capable('assets', 'read');
      this.inventory = this.perm.capable('inventory', 'read');
      this.tickets = this.perm.capable('tickets', 'read');
      this.setup = this.perm.capable('setup', 'read');
      this.planningHub = this.perm.capable('planning_hubb', 'read');
      this.deliveryOrders = this.perm.capable('delivery_orders', 'read');
      this.shifts = this.perm.capable('shifts', 'read');
      this.drivers = this.perm.capable('drivers', 'read');
      this.packages = this.perm.capable('packages', 'read');
      this.vehicles = this.perm.capable('vehicles', 'read');
      this.fleetSetup = this.perm.capable('fleet_setup', 'read');
      this.helpdesk = this.perm.capable('helpdesk user', 'read');
      this.scheduledMaintenace = this.perm.capable("scheduled_maintenance", "read");
      this.assetContracts = this.perm.capable("assets_contracts", "read");
      this.site = this.perm.capable("site", "read");
      this.project = this.perm.capable("project", "read");
      this.users = this.perm.capable("manage_users", "read");
      this.checklistsRead = this.perm.capable("checklists", "read");

       let create = this.perm.capable("checklists", "create");
       let update = this.perm.capable("checklists", "update");
       let remove = this.perm.capable("checklists", "remove");

       if(this.perm.capable("checklists", "read") && this.perm.capable("checklists", "create") && this.perm.capable("checklists", "update") && this.perm.capable("checklists", "remove") )
       {
            this.checklistsFull = true;

       }
      /************************** MS Menu *********************************/
      this.visits = this.perm.capable('visit', 'read');
      this.pmv = this.perm.capable('pmv', 'read');
      this.overtime = this.perm.capable('overtime', 'read');
      this.attendance = this.perm.capable('attendance', 'read');
      this.productivity = this.perm.capable('productivity', 'read');
      this.machine_specialist = this.perm.capable('machine_specialist', 'read');
      
      console.log(profileInfo, this.helpdesk);
    }

    loadMenuSetting(res: any) {

        if (res || res == 'true') {
            this.collapseNav = true

        } else {
            this.collapseNav = false

        }
    }
    clearBreadcrumb() {
        this.breadcrumbService.setItems([])
    }
    getUrl() {
        
      this.push(this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.clearBreadcrumb()
             //   console.log(event.url, "-----init", event.url.indexOf('/dashboard/inventory'))
                if (event.url.indexOf('/dashboard/inventory') >= 0) {
                    this.itemClick('inventory')
                } else if (event.url.indexOf('/dashboard/assets') >= 0) {
                    this.itemClick('assets')
                } else if (event.url.indexOf('/dashboard/products') >= 0) {
                    this.itemClick('products')

                } else {
                    this.subMenu = null
                    this.toggelMenu = false

                }
            }
        }));
    }
    itemClick(menuType: any) {
        // console.log(event,"---")
        this.subMenu = menuType
        this.toggelMenu = true
    }
    collapsOut(menuType: any) {
        console.log(this.toggelMenu)
        this.subMenu = menuType
        this.toggelMenu = true

    }
    collapsIn(menuType: any) {
        console.log(this.toggelMenu)
        this.subMenu = menuType
        this.toggelMenu = false


    }
    openUrl(url: any) {
        console.log(url)
        if (url == 'inventory') {
            this.router.navigate(['dashboard/inventory/list']);
        } else if (url == 'assets') {
            this.router.navigate(['dashboard/assets/all']);
        } else if (url == 'products') {
            this.router.navigate(['dashboard/products/all']);
        }

    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
      }
      goToSetup(){
        if(this.perm.capable('manage_org_detail', 'read')){
          this.router.navigate(['/dashboard/setup/generalSettings']);
        } 
        //access
        else if(this.perm.capable('manage_users', 'read')){
          this.router.navigate(['/dashboard/setup/allUser/view']);
        } 
        
        //tickets
        else if(this.perm.capable('manage_modules', 'read') && this.addonFeature.addonSLA == 'SLA'){
          this.router.navigate(['/dashboard/setup/ticket/details']);
        } 
         //inventory
         else if(this.perm.capable('manage_modules', 'read') && this.addonFeature.addonINVENTORY == 'INVENTORY'){
          this.router.navigate(['/dashboard/setup/inventory/location']);
        } 
        //users
        else if(this.perm.capable('manage_users', 'read')){
          this.router.navigate(['/dashboard/setup/users']);
        } 
        //import
        else if(this.perm.capable('manage_import_export', 'read')){
          this.router.navigate(['/dashboard/setup/import']);
        } 
        //geofencing
        else if(this.perm.capable('manage_modules', 'read')){
          this.router.navigate(['/dashboard/setup/geofencing']);
        } 
        //Checklist
        else if(this.perm.capable('manage_workflow', 'read') && this.addonFeature.addonTTL =='TTL_QC'){
          this.router.navigate(['/dashboard/setup/checklist/taskList']);
        } 
        //licenses
        else if(!this.perm.capable('manage_org_detail', 'read') && this.perm.capable('manage_licenses', 'read')){
          this.router.navigate(['/dashboard/setup/licenses']);
        } 
        //devices
        else if(this.perm.capable('manage_workflow', 'read') && this.addonFeature == 'CHUBB'){
          this.router.navigate(['/dashboard/setup/chubb/devices']);
        } 
        //notification
        else if(this.perm.capable('manage_modules', 'read') && this.addonFeature.addonMAGNUM == 'MAGNUM'){
          this.router.navigate(['/dashboard/setup/notifications']);
        } 
        //skills
        else {
          this.router.navigate(['/dashboard/setup/skills/view']);
        }
      }
      resetVariable(){
        this.util.removePersistantItem();

      }
      restSomeData(){
        this.spinner.hide()
        this.resetVariable()
       
        this.util.resetTabView();
        this.util.clearPersistantItem(PERSISTANT_ITEM.JOB_ORDER);
       
      }
      resetItems(){
        this.util.clearPersistantItem(PERSISTANT_ITEM.SALES_ORDER);
      }

      
       public openItemToNewTab(): void {
        this.getZendeskTokenByCustellaToken()
      }

      getZendeskTokenByCustellaToken(){
      this.push(this.profileService.getZendeskTokenByCustellaToken().subscribe((res:any) => {
          if(res.status == '200'){
            this.openZendesk(res.body.id_token)
          }
          console.log(res)
          
        }))
       }
  openZendesk(data: any) {
      let url = 'https://fieldex.zendesk.com/access/jwt?jwt=' + data+"&return_to=https://customer-fieldex.zendesk.com";
      window.open(url, "_blank", "noreferrer");

  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.refreshCollapse.unsubscribe()
    this.refreshCollapseDot.unsubscribe();
    this.refreshSide.unsubscribe();
    this.refreshProfile.unsubscribe();
    this.refreshMenuType.unsubscribe();
    this.translate = null;
    console.log("allclosed")
  }
}
