import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { ServerRequest } from "src/app/_models/global.data.model";
import { CustomersService } from "src/app/_services/customers.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import * as moment from "moment";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { Global } from "src/app/_utilities/global";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
var dateFormateChange: any;
@Component({
  selector: "app-customer-table",
  templateUrl: "./customer-table.component.html",
  styleUrls: ["./customer-table.component.scss"],
})
export class CustomerTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Input() searchValue = "";
  @Input() lookupFilter: any = "";
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any=[];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  customers: any;
  noCustomers: boolean = false;
  noCustomersIcon: boolean = false;
  totalRecord: any;

  loadTable: number =0;
  sortBy: any;
  sortOrder: any;
 @Input() translate: any;
  constructor(
    private auth: AuthenticationService,
    private customerService: CustomersService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private router: Router,
    private dynamicFormService: DynamicFormService,
    private dynamicTableColumnsService:DynamicTableColumnsService,
    
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {
   
    this.getTableColumn()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length > 2
    ) {
      this.onGridReady(this.gridParams);
    }

    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length === 0
    ) {
      this.onGridReady(this.gridParams);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private preInit() {
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
    // this.columnDefs = [
    //   {
    //     headerName: "Customer No.",
    //     headerValueGetter: (params: any) => {
    //       const addonFeature = this.auth.getAddonFeatureLists();
    //       return addonFeature.addonMS == 'MACHINE_SPECIALIST' ? "SOU No." : "Customer No.";
    //     },
    //     field: "customerNumber",
    //     width: 100,
    //     headerTooltip: "#",
    //     cellClass: function (params: any) {
    //       return ["text-1-5 font-weight-bold table_default_color"];
    //     },
    //   },
    //   {
    //     headerName: "Name",
    //     field: "name",
    //     width: 220,
    //     headerTooltip: "#",
    //     headerValueGetter: (params: any) => {
    //       const addonFeature = this.auth.addonFeature();
    //       return addonFeature === "TOUCHANDGO" ? "Account Name" : "Name";
    //     },
    //   },
    //   {
    //     headerName: "Billing Address",
    //     field: "billingAddress",
    //     width: 220,
    //     headerTooltip: "#",
    //     sortable: true,
    //     cellRenderer: function (params: any) {
    //       return params.data && params.data.billingAddress ? params.data.billingAddress : "-";
    //     },
    //   },
    //   {
    //     headerName: "Customer Reference Id",
    //     field: "customerSecondExternalId",
    //     width: 120,
    //     headerTooltip: "#",
    //     headerValueGetter: (params: any) => {
    //       const addonFeature = this.auth.getAddonFeatureLists();
    //       return addonFeature.addonMS == 'MACHINE_SPECIALIST' ? "SOU Reference ID" : "Customer Reference ID";
    //     },
    //   },
    //   {
    //     headerName: "phone",
    //     field: "phone",
    //     width: 130,
    //     headerTooltip: "#",
    //     headerValueGetter: (params: any) => {
    //       const addonFeature = this.auth.addonFeature();
    //       return addonFeature === "TOUCHANDGO" ? "Case Contact Phone" : "Phone";
    //     },
    //   },
    //   { headerName: "Email", field: "email", width: 200, headerTooltip: "#" },
    //   {
    //     headerName: "Category",
    //     field: "categoryType.name",
    //     width: 150,
    //     headerTooltip: "#",
    //   },
    // ];
    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };
    // this.overlayNoRowsTemplate =
    //   "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";
  }


  /******************************************** Pre Init ******************************************/
  private getTableColumn() {
    this.overlayNoRowsTemplate = "<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
    this.push(
      this.dynamicFormService.getTableColumn("customer")
        .subscribe((res: any) => {
          this.sortBy = res.sortBy
          this.sortOrder = res.sortOrder
         
            var columnDefs: any = this.gridOptions.columnDefs;
            res.tableColumnSetups.forEach((element: any) => {
              this.cellRenderer(element);
    
              //console.log(element)
              columnDefs.push(element);
              this.gridOptions.api!.setColumnDefs(columnDefs);
            });
            
            this.loadTable = 1;
            this.gridOptions.api!.setColumnDefs(columnDefs);
            this.onGridReady(this.gridParams);
        })
    );


}
 /************************************************************************************/
 getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
 /******************************************** Render Cell ******************************************/
 private cellRenderer(element: any) {
  console.log(element,"===",element.dataType)
    
  var dateFormat: any = localStorage.getItem("date_format");
  element.headerTooltip = "#";
  if(element.translatePath){
    element.headerName = this.getNestedValue(this.translate, element.translatePath);
   } 

  if (element.field === "customerNumber") {
    element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadCusNo'),
      element.cellRenderer = function (params: any) {
        if(params.data){
          return  Global.setCellRenderer(params,params.data.rowId, params.data.customerNumber)
        }
      };
  }else if (element.dataType == 'date'  || element.dataType == "text" || element.dataType == "datetime" || element.dataType == "boolean") {
    element = Global.setColumnByDataType(element,this.auth.getUserTimezone(),dateFormat)
   }
  

  return element;
}
  onGridReady(params: any) {
    if (params) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          this.getServerSideData(params);
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any=serverRequest.startRow
    this.push(
      this.customerService
        .getCustomerList(this.searchValue, serverRequest)
        .subscribe(
          (data: any) => {
            console.log("table",data.body);
            this.customers = data.body.data;
            this.customers.forEach((element: any) => {
              count++
              element.rowId='Tbl_RecCusNo'+count
              element.buttonEdit='Btn_CusRowEdit'+count
              element.buttonDelete='Btn_CusRowDel'+count
            });
            this.totalRecord = data.body.total;
            this.setGridData(
              agGridGetRowsParams,
              this.customers,
              data.body.total,
              data.body.status
            );
          },
          (err) => {
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
  }

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "name", sort: "asc" };
      agGridRequest.sortModel.push(this.parameters);
    }
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    if (status === "NO_DATA") {
      this.noCustomers = false;
      this.noCustomersIcon = true;
    } else {
      this.noCustomers = true;
      this.noCustomersIcon = false;

      if (this.customers.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        agGridGetRowsParams.successCallback(this.customers, totalCount);
      }
    }
  }

  selectCustomer(e: any) {
    console.log(e.data);
    this.push(
      this.customerService
        .getCustomerById(e.data.id)
        .subscribe(
          (data: any) => {
            this.customerService.setCustomer(data);
          },
          (err) => {
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
    
  }

  /******************************************** Right Click Context Menu ******************************************/
  /******************************************** right click cell context menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";
   

    return this.dynamicTableColumnsService.rightClick(params);
  };

  

  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }
}
