import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  GetContextMenuItemsParams,
  GridOptions,
  IServerSideGetRowsParams,
  MenuItemDef,
} from "ag-grid-community";
import "ag-grid-enterprise";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import {
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { SettingService } from "src/app/_services/admin/setting.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { ProductService } from "src/app/_services/product.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { NgxSpinnerService } from "ngx-spinner";
import { Global } from "src/app/_utilities/global";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

var dateFormateChange: any;
var router: Router;
var translatePjct: any;
@Component({
  selector: "app-product-table",
  templateUrl: "./product-table.component.html",
  styleUrls: ["./product-table.component.scss"],
})
export class ProductTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy, OnChanges
{
  @Output() noDataEvent = new EventEmitter();

  @Input() displayAction: any = false;
  @Input() searchValue: any = null;
  @Input() translate: any = null;

  @Input() filterData: any;
  @Input() lookupFilter: any = "";

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: Partial<GridOptions>;
  overlayNoRowsTemplate!: string;


  assets: any;
  actionData: any;
  totalRecord: any;
  currentRecordNumber: any;
  refresher!: Subscription;
  noProducts: boolean = false;
  noProductsDataIcon: boolean = false;
  dateFormat: any;

  currency: any;

  deleteSubHasPushed = false;
  loadTable = 0;
  sortBy: any;
  sortOrder: any;
  addonFeature: any
  constructor(
    private productService: ProductService,
    private auth: AuthenticationService,
    private dynamicFormService: DynamicFormService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private perm: PermissionService,
    private util: UtilServiceService,
    private settingService: SettingService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private languageTranslateService: LanguageTranslateService
  ) {
    super();
    this.preInit();
  }

  ngOnInit() {
    router = this.router;
    // if (this.displayAction) {
    //   this.columnDefs.push(this.actionData);
    // }
    this.addonFeature = this.auth.getAddonFeatureLists();
    this.getLanguageData();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length > 2
    ) {
      this.onGridReady(this.gridParams);
    }

    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue === ""
    ) {
      this.onGridReady(this.gridParams);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.settingService.getOrgSettings(1).subscribe((res: any) => {
      this.currency = res.currency;
    });

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

  
    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };
    
    this.actionData = {
      headerName: this.translate?.general.buttons.action.singular,
      filter: false,
      sortable: false,
      width: 150,
      headerTooltip: "#",
      headerClass: "marging-auto hide-action-border",
      cellStyle: { "text-align": "center" },
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.edit.bind(this),
        permissions: {
          update: this.perm.capable("products", "update"),
          remove: this.perm.capable("products", "remove"),
        },
        hidden: {
          update: !this.perm.capable("products", "update"),
          remove: !this.perm.capable("products", "remove"),
        },
      },
    };
  }
 /********************************** PreInit *****************************/
 private preInitTableColumn() {
  
  this.dateFormat = localStorage.getItem("date_format");
  dateFormateChange = this.auth.getUserTimezone();

  this.dynamicFormService.getTableColumn("product").subscribe((res: any) => {
    this.sortBy = res.sortBy
    this.sortOrder = res.sortOrder
   
    var columnDefs: any = this.gridOptions.columnDefs;
    res.tableColumnSetups.forEach((element: any) => {
      this.cellRenderer(element);

      columnDefs.push(element);
      this.gridOptions.api!.setColumnDefs(columnDefs);
    });
    this.overlayNoRowsTemplate ="<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";

    if (this.displayAction) {
        this.columnDefs.push(this.actionData);
      
    }
    this.gridOptions.api!.setColumnDefs(columnDefs);

    this.loadTable = 1;

    this.onGridReady(this.gridParams);
  });
}
  /********************************** Render Cell *****************************/
  private cellRenderer(element: any) {
    console.log(element, "asdasdasdad")
    
    var dateFormat: any = localStorage.getItem("date_format");

    if(element.translatePath){
      element.headerName = this.languageTranslateService.getNestedValue(this.translate, element.translatePath);
     } 
    element.headerTooltip = "#";

    if (element.field === "productNumber") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadProdNo'),
      element.cellRenderer = function (params: any) {
        if(params.data){
          return  Global.setCellRenderer(params,params.data.rowId, params.data.productNumber)
        }
      };
    }

    else if (element.field === "warranty") {
      element.cellRenderer = function (params: any) {
        if(params.data){
          return params.data.warranty ? params.data.warranty + " month(s)" : "0 month(s)";
        }
      };
    }

     else if (element.field === "active") {
    
      element.cellRenderer = function (params: any) {
       if (params.data) {
        if (params.data.active) {
          return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value">'+translatePjct.general.status.active.singular+ '</span></span>';
        } else {
          return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">'+translatePjct.general.status.inactive.singular+'</span></span>';
        }
       }
      };
    }
 
     
  
    else  if (element.field == "price") {
       element.valueFormatter = this.currencyFormatter.bind(this)
      
      }else  if (element.dataType == 'date'  || element.dataType == "text" || element.dataType == 'datetime' || element.dataType == 'boolean') {
        element = Global.setColumnByDataType(element,this.auth.getUserTimezone(),dateFormat)
       }
  }
  /******************************************** Format Currency ******************************************/
  private currencyFormatter(params: any) {
    var usdFormate = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    });
    return `${this.currency}${usdFormate.format(params.value)}`;
  }

  /********************************** Click Cell Event / Edit - Delete *****************************/
  private edit(e: any) {
    if (e.action === "edit") {
      this.util.setPersistantItem(PERSISTANT_ITEM.PRODUCT, e.rowData);

      this.router.navigate(["/dashboard/products/update"], {
        queryParams: { id: e.rowData.id },
      });
    } else if (e.action === "delete") {
      e.rowData.deleteHeader = this.translate.product.label.singular
      e.rowData.deleteType = "Product";
      e.rowData.message =
        this.translate.general.messages.confirmDelete+" "+ 
        e.rowData.productNumber +
        " - " +
        e.rowData.name +
        "?";

      if (!this.deleteSubHasPushed) {
        this.push(
          this.deleteRecordService.getItems(e.rowData).subscribe((data) => {
            if (data.status === 200) {
              this.onRefreshList();
            }
          })
        );

        this.deleteSubHasPushed = true;
      } else {
        this.deleteRecordService.getItems(e.rowData);
      }
    }
  }

  /********************************** Refresh Data **************************/
  public onRefreshList() {
    this.onGridReady(this.gridParams);
  }

  /********************************** Search Data **************************/
  public quickSearch() {
    this.onGridReady(this.gridParams);
  }

  /********************************** On Grid Ready **************************/
  public onGridReady(params: any) {
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable === 1 && this.translate) { this.getServerSideData(params);}
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /********************************** Call Customer API to Display data **************************/
  private getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any=serverRequest.startRow;
    var priceBookId = null;
    var url = null
    // //console.log('Making a server request: ', serverRequest);
    if(this.addonFeature.addonPRICEBOOK == 'PRICE_BOOK'){ 
     var currentJobOrder = this.util.getPersistantItem(PERSISTANT_ITEM.JOB_ORDER);
     console.log("currentJobOrder",currentJobOrder)
     
     if(currentJobOrder) {
      url = '/products/price-book-product'
      priceBookId = currentJobOrder.jobOrder.priceBook ? currentJobOrder.jobOrder.priceBook.id : null
    }
    }
    this.push(
      this.productService.getAllProductsV2(this.searchValue, serverRequest,url,priceBookId)
        .subscribe(
          (data: any) => {
            this.assets = data.body.data;
            this.totalRecord = data.body.total;
            this.assets.forEach((element: any) => {
              count++
              element.rowId='Tbl_RecProdNo'+count
              element.buttonEdit='Btn_ProdEdit'+count
              element.buttonDelete='Btn_ProdDel'+count
            });
            this.setGridData(
              agGridGetRowsParams,
              this.assets,
              data.body.total,
              data.body.status
            );
            //this.spinner.hide()
          },
          (err) => {
            //console.log(err,"===>>>")
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    if (status === "NO_DATA") {
      this.noProducts = false;
      this.noProductsDataIcon = true;
    } else {
      this.noProducts = true;
      this.noProductsDataIcon = false;
      if (this.assets.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        agGridGetRowsParams.successCallback(this.assets, totalCount);
        ///CS-4758 this.gridApi.sizeColumnsToFit();
      }

      if (this.noProducts) {
        this.noDataEvent.emit(this.noProducts);
      }
    }
  }
  /********************************** Request Parameter for api **************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters =  { colId: this.sortBy, sort: this.sortOrder }
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }
  /********************************** View Singal Record **************************/
  public viewProduct(e: any) {
    this.util.resetTabView();

    if (!this.filterData) {
      if (e.colDef.field === "productNumber" && e.colDef.cellClass !== null) {
        this.router.navigate(["dashboard/products/view/" + e.data.id], {
          queryParams: { id: e.data.id, serialNo: null },
        });
      }else{
        this.dynamicTableColumnsService.redirectURL(e)
      }
    } else {
      //////debugger
      this.productService.setItems(e.data);
    }
  }

  /********************************** Get Context Menu **************************/
  public getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    this.util.resetTabView();

    var tabData: any | null = params;

    if (tabData.column.colId == "productNumber") {
      var result: (string | MenuItemDef)[] = [
        {
          // custom item
          name: "Open link in new tab",
          action: () => {
            var url: any = router.serializeUrl(
              router.createUrlTree(["/dashboard/products/view" + tabData.id], {
                queryParams: { id: tabData.id },
              })
            );
            url = url.replace("%23", "#");
            window.open(url, "_blank");
          },
          cssClasses: ["redFont", "bold"],
        },
      ];
      return result;
    } else {
      return [];
    }
  }


  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        translatePjct = data;
       // this.preInit();
       this.preInitTableColumn()
      }
    }))
    console.log("translate",this.translate)
   // this.mainLabel = this.translate?.accessories.label.plural
  }


  push(obs: any) {
    super.push(obs);
  }
}
