import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { PriceRateCardBookService } from 'src/app/_services/price-rate-card-book.service';
import * as moment from 'moment';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';

@Component({
  selector: 'app-custella-price-book',
  templateUrl: './custella-price-book.component.html',
  styleUrls: ['./custella-price-book.component.scss']
})
export class CustellaPriceBookComponent extends SubscriptionUtil
implements OnInit, OnDestroy
{
@Output() itemSelector = new EventEmitter();
@Output() closeView = new EventEmitter();

@Input() data: any;
@Input() view: any;
@Input() url: any;

gridApi: any;
gridColumnApi: any;
columnDefs: any;
defaultColDef: any;
frameworkComponents: any;
gridParams : any;
startFrom: any;
product: any;
pageSize: any = 25;
parameters: any;
gridOptions: GridOptions;
overlayNoRowsTemplate: string;
productsFamilies: any;
productsType: any;
currentRecordNumber: any;
totalRecord: any;

 searchValue: any=null
  checklistData: any;
  translate: any;

constructor(private priceRateCardBookService :PriceRateCardBookService,
  private errorUtil: ErrorUtil,
  private languageTranslateService: LanguageTranslateService,
private util: UtilServiceService,) {
  super();
 
  this.gridOptions = {
    cacheBlockSize: 25,
    rowModelType: "infinite",
    sortingOrder: ["desc", "asc"],
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    tooltipShowDelay: 100,
  };
 
  this.defaultColDef = {
    enableRowGroup: true,//ag-Grid-Enterprise
    enablePivot: true,//ag-Grid-Enterprise
    enableValue: true,//ag-Grid-Enterprise
    sortable: true,
    resizable: true,
    filter: false,
    tooltipComponent: TableHeaderToolTipComponent
  };
  //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";

  
}

ngOnInit(): void {
  console.log(this.url,"====",this.view,"----",this.data)
  this.getLanguageData()
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}
public viewCell($event: any) {
  const field = $event.colDef.field;
console.log(field)
  if (field === "priceBookNumber") {
    this.selectItem($event.data);
  }
}
selectItem(val: any) {
  this.itemSelector.emit(val);
}

public closeTable() {
  this.closeView.emit(false);
}
 
push(obs:any) {
  super.push(obs);
}



/************************************************ Search Data ****************************************/
onSearchCheck() {
  this.onGridReady(this.gridParams);
  
}
/***************************************** Initilize Grid Table ****************************************/
onGridReady(params: any) {
  //console.log(params)
  this.gridParams = params;

  this.gridApi = params.api;
  this.gridApi.sizeColumnsToFit();
  var dataSource = {
    getRows: (params: IServerSideGetRowsParams) => {
      this.getServerSideData(params);
    },
  };

  this.gridApi.sizeColumnsToFit();
  this.gridApi.setDatasource(dataSource);
}

/********************************** Call Customer API to Display data *********************************/
getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
  // this.spinner.show()
  this.gridApi.hideOverlay();
  const serverRequest = this.getRequestParams(agGridGetRowsParams);
  console.log('Making a server request: ', serverRequest);
  var customerId = null
  var installationDate = null
  if(this.view == 'jo'){
    customerId = this.data.customer.id ? this.data.customer.id : null
    installationDate = this.data.installationDate ? this.data.installationDate : null

     if (this.data.installationDate) {
      
               
      installationDate = this.util
                          .timeFormatter(
                            moment(this.data.installationDate, "h:mm A").format("h:mm A"),
                            moment(this.data.installationDate, "YYYY-MM-DD").format("YYYY-MM-DD")
                          )
                          .toISOString()
                
              }

  }

  this.push(this.priceRateCardBookService.getActivePriceBookList(this.url,this.searchValue, serverRequest,customerId,installationDate) .subscribe(
        (data: any) => {
          console.log(data.body);
          this.checklistData = data.body.data;
          this.totalRecord = data.body.total;
          this.setGridData(
            agGridGetRowsParams,
            this.checklistData,
            data.body.total,
            data.body.status
          );
          //this.spinner.hide()
        },
        (err) => {
          //console.log(err,"===>>>")
          this.errorUtil.setErrorMessage(err);
        }
      )
  );
}
/**************************************** Set Data for Grid Table ****************************************/
private setGridData( agGridGetRowsParams: IServerSideGetRowsParams,resultItems: any[],totalCount: number, status: string ) {
  console.log(resultItems, "===");
  
 
    if (this.checklistData.length === 0) {
      this.gridApi.showNoRowsOverlay();
       } else {
       agGridGetRowsParams.successCallback(this.checklistData, totalCount);
    }
  
 
}
/********************************** Request Parameter for api ****************************************/

getRequestParams(agGridRequest: any): ServerRequest {
  if (agGridRequest.sortModel.length <= 0) {
    this.parameters = { colId: "id", sort: "asc" };
    agGridRequest.sortModel.push(this.parameters);
  }

  //console.log(agGridRequest)
  this.currentRecordNumber = agGridRequest.startRow;
  return {
    startRow: agGridRequest.startRow,
    pageSize: 25,
    filterModel: null,
    sortModel: agGridRequest.sortModel,
  };
}

getLanguageData() {
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.initializeColumns();
      }
    }))
  console.log("translate",this.translate)
  //this.mainLabel = this.translate?.customer.label.plural
}


initializeColumns() {
      
  this.columnDefs = [];
  this.overlayNoRowsTemplate = "<div class='not-found'><span>" + this.translate?.general.messages.noRecordsFound + "</span> </div>";

  const newData = [
    { headerName: this.translate?.priceBook.label.singular, field: 'priceBookNumber', width: 150, headerTooltip: "#",cellClass: function (params: any) { return ['table_default_color'];}},
    { headerName: this.translate?.priceBook.fields.priceBookName.singular, field: 'name', width: 170,headerTooltip: "#" },
   ];
  this.columnDefs = newData;
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.columnDefs);
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
    }
}


}